import { axiosConfigInstance, BASE_URL } from "./config"

// 로그인 하는 페이지로 이동
// http://192.168.11.11/api/client/social/oauth/{socialName} socialName = 'kakao','naver'
export const LOGIN_LINK = `${BASE_URL}/api/client/social/oauth/`;

// 로그인 정보 가져오기
export const apiGetLogin = async(token:string) =>{
    try {
        const res = await axiosConfigInstance('/api/client/social/oauth').post('',{
            method: 'verifySocialSign',
            params: {
                token
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error
        }
    }
}