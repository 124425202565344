import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AirportBtnItem from '../item/AirportBtnItem';
import BasicText from '../../basic/BasicText';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import ModalDatePage from '../../modal/page/ModalDatePage';
import ModalTimePage from '../../modal/page/ModalTimePage';
import { momentFormat } from '../../../utils/format/time';
import { onlyNumber } from '../../../utils/format/string';
import { apiGetPickupConfig, apiSetOrderAirport } from '../../../api/api_airport';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { numberWithComma } from '../../../utils/format/number';

export default function AirportInput2Part({ info, valueChange, setStep }: any) {
    const userInfo = useUserInfo();
    const [carList, setCarList] = useState([]);
    const [paymentList, setPaymentList] = useState([]);

    const [dateOpen, setDateOpen] = useState({
        name: '출발',
        open: false,
    });
    const [timeOpen, setTimeOpen] = useState({
        name: '출발',
        open: false,
    });

    useEffect(() => {
        getConfig();
    }, [])

    const getConfig = async () => {
        const res = await apiGetPickupConfig();
        if (res.result) {
            setCarList(res.data.product_pickup_config_option_car);
            setPaymentList(res.data.product_pickup_config_option_payment);
        }
    }

    // 비행기 출발 시간
    // const startTimeClickAction = () =>{
    //     if(info?.pickup_time_depart_timestamp !== ''){
    //         setTimeOpen({
    //             name: '비행기 출발',
    //             open: true,
    //         })
    //     }else{
    //         alert('출발날짜와 시간을 먼저 입력해주세요.');
    //     }
    // }

    // 복귀날짜
    const endDateClickAction = () => {
        if (info?.pickup_time_depart_timestamp !== '') {
            setDateOpen({ name: '복귀', open: true })
        } else {
            alert('출발날짜와 시간을 먼저 입력해주세요.');
        }
    }

    // 복귀 비행기
    // const endTimeClickAction = () =>{
    //     if(info?.pickup_time_arrive_timestamp){
    //         setTimeOpen({
    //             name: '비행기 도착',
    //             open: true,
    //         })
    //     }else{
    //         alert('복귀날짜와 시간을 먼저 입력해주세요.');
    //     }
    // }

    const nextAction = async () => {
        if (info?.pickup_time_depart_timestamp === '') {
            let txt = info?.pickup_type === '2' ? '입국' : '출발';
            alert(`${txt}날짜와 시간을 입력해주세요.`);
            return;
        }
        // if(info?.pickup_time_depart_ap === ''){
        //     alert('비행기 출발시간을 입력해주세요.');
        //     return;
        // }
        // if(info?.pickup_time_arrive_ap === ''){
        //     alert('비행기 도착시간을 입력해주세요.');
        //     return;
        // }
        if (info?.pickup_time_depart_flight === '') {
            alert('출발 항공편을 입력해주세요.');
            return;
        }
        if(info?.pickup_type === '1'){
            if (info?.pickup_time_arrive_timestamp === '') {
                alert('복귀날짜와 시간을 입력해주세요.');
                return;
            }
            if (info?.pickup_time_arrive_flight === '') {
                alert('도착 항공편을 입력해주세요.');
                return;
            }
        }
        if (info?.pickup_pickup_people === '') {
            alert('인원을 입력해주세요.');
            return;
        }
        if (info?.pickup_car?.car_name === undefined) {
            alert('차를 입력해주세요.');
            return;
        }
        if(info?.pickup_payment === ''){
            alert('결제 방법을 선택해주세요.');
            return;
        }
        if (userInfo.user_id !== 0) {
            const res = await apiSetOrderAirport(userInfo.token, userInfo.user_id, 
                {...info, 
                    pickup_time_arrive_timestamp: info?.pickup_time_arrive_timestamp === '' ? 0 : info?.pickup_time_arrive_timestamp,
                }
            );
            if (res.result) {
                setStep(3);
            }
        } else {
            setStep(3);
        }
    }

    // 수화물 수량 변경
    const bagsChange = (index: any, val: any) => {
        const newList = info?.pickup_bags.map((data: any, idx: any) => idx === index ? { ...data, bags_count: Number(val) } : data);
        valueChange('pickup_bags', newList);
    }

    // 서비스 수량 변경
    const serviceChange = (index: any, val: any) => {
        const newList = info?.pickup_service.map((data: any, idx: any) => idx === index ? { ...data, service_count: Number(val) } : data);
        valueChange('pickup_service', newList);
    }

    // 차 선택
    const carChange = (val: any) => {
        const data = carList.filter((item: any) => item.car_id === val);
        if (data.length === 0) return;
        valueChange('pickup_car', data[0]);
    }
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={29} mFontSize={21}>일정을 입력해 주세요.</BasicText>
            <InputWrap>
                {/* 왕복 */}
                {info?.pickup_type === '1' &&
                    <>
                        <InputItemWrap cr onClick={() => setDateOpen({ name: '출발', open: true })}>
                            <BasicText color={info?.pickup_time_depart_timestamp === '' ? '#CCCCCC' : '#000'}>{info?.pickup_time_depart_timestamp === '' ? '출발날짜와 자택 출발시간을 입력해주세요.' : `${momentFormat('YYYY-MM-DD HH:mm', info?.pickup_time_depart_timestamp)}`} </BasicText>
                        </InputItemWrap>
                        {/* <InputItemWrap cr onClick={startTimeClickAction}>
                            <BasicText color={info?.pickup_time_depart_ap === '' ? '#CCCCCC' : '#000'}>{info?.pickup_time_depart_ap === '' ? '비행기 출발시간을 입력해주세요.' : info?.pickup_time_depart_ap}</BasicText>
                        </InputItemWrap> */}
                        <InputItemWrap>
                            <input value={info?.pickup_time_depart_flight ?? ''} onChange={(e: any) => valueChange('pickup_time_depart_flight', e.target.value)} placeholder={`출국 항공편을 입력해주세요`} />
                        </InputItemWrap>
                        <InputItemWrap mt='12px' cr onClick={endDateClickAction}>
                            <BasicText color={info?.pickup_time_arrive_timestamp === '' ? '#CCCCCC' : '#000'}>{info?.pickup_time_arrive_timestamp === '' ? '복귀날짜와 비행기 도착 예정시간을 입력해주세요.' : `${momentFormat('YYYY-MM-DD HH:mm', info?.pickup_time_arrive_timestamp)}`} </BasicText>
                        </InputItemWrap>
                        {/* <InputItemWrap cr onClick={endTimeClickAction}>
                            <BasicText color={info?.pickup_time_arrive_ap === '' ? '#CCCCCC' : '#000'}>{info?.pickup_time_arrive_ap === '' ? '비행기 도착시간을 입력해주세요.' : info?.pickup_time_arrive_ap}</BasicText>
                        </InputItemWrap> */}
                        <InputItemWrap>
                            <input value={info?.pickup_time_arrive_flight ?? ''} onChange={(e: any) => valueChange('pickup_time_arrive_flight', e.target.value)} placeholder={`항공편을 입력해주세요`}></input>
                        </InputItemWrap>
                    </>
                }
                {/* 자택>공항 */}
                {info?.pickup_type === '2' &&
                    <>
                        <InputItemWrap cr onClick={() => setDateOpen({ name: '출발', open: true })}>
                            <BasicText color={info?.pickup_time_depart_timestamp === '' ? '#CCCCCC' : '#000'}>{info?.pickup_time_depart_timestamp === '' ? '출발날짜와 자택 출발시간을 입력해주세요.' : `${momentFormat('YYYY-MM-DD HH:mm', info?.pickup_time_depart_timestamp)}`} </BasicText>
                        </InputItemWrap>
                        <InputItemWrap>
                            <input value={info?.pickup_time_depart_flight ?? ''} onChange={(e: any) => valueChange('pickup_time_depart_flight', e.target.value)} placeholder={`출국 항공편을 입력해주세요`} />
                        </InputItemWrap>
                    </>
                }
                {/* 공항>자택 */}
                {info?.pickup_type === '3' &&
                    <>
                        <InputItemWrap cr onClick={() => setDateOpen({ name: '출발', open: true })}>
                            <BasicText color={info?.pickup_time_depart_timestamp === '' ? '#CCCCCC' : '#000'}>{info?.pickup_time_depart_timestamp === '' ? '입국 날짜와 비행기 도착 예정 시간을 입력해주세요.' : `${momentFormat('YYYY-MM-DD HH:mm', info?.pickup_time_depart_timestamp)}`} </BasicText>
                        </InputItemWrap> 
                        <InputItemWrap>
                            <input value={info?.pickup_time_depart_flight ?? ''} onChange={(e: any) => valueChange('pickup_time_depart_flight', e.target.value)} placeholder={`출국 항공편을 입력해주세요`} />
                        </InputItemWrap>
                    </>
                }
                <DecoWrap>
                    <img src='/img/asset/up.png'></img>
                    <img src='/img/asset/down.png'></img>
                </DecoWrap>
            </InputWrap>
            <MoreWrap>
                <MoreInputWrap>
                    <BasicText color={info?.pickup_people === '' ? '#ccc' : '#000'}>인원</BasicText>
                    <input value={info?.pickup_people} onChange={(e: any) => valueChange('pickup_people', onlyNumber(e))} maxLength={1000} placeholder={'0'} />
                </MoreInputWrap>
                {/* 수화물 */}
                {info?.pickup_bags.length === 0 ?
                    <></> :
                    info?.pickup_bags.map((data: any, index: any) => {
                        return (
                            <MoreInputWrap key={`bags-${index}`}>
                                <BasicText color={data?.bags_count == 0 ? '#ccc' : '#000'}>{data?.bags_name} {index === 2 ? '' : '수화물'}</BasicText>
                                <input value={data?.bags_count == 0 ? '' : data?.bags_count} onChange={(e: any) => bagsChange(index, onlyNumber(e))} maxLength={100} placeholder={'0'}></input>
                            </MoreInputWrap>
                        )
                    })
                }
                {/* 편의시설 (카시트) */}
                {info?.pickup_service.length === 0 ?
                    <></> :
                    info?.pickup_service.map((data: any, index: any) => {
                        return (
                            <MoreInputWrap key={`service-${index}`}>
                                <BasicText color={data?.service_count == 0 ? '#ccc' : '#000'}>{data?.service_name}</BasicText>
                                <input value={data?.service_count === 0 ? '' : data?.service_count} onChange={(e: any) => serviceChange(index, onlyNumber(e))} maxLength={150} placeholder={'0'}></input>
                            </MoreInputWrap>
                        )
                    })
                }
                <InputItemWrap cr padding='0'>
                    {carList.length === 0 ?
                        <></> :
                        <SelectItem
                            value={info?.pickup_car?.car_id === undefined ? '' : info?.pickup_car?.car_id}
                            setValue={carChange} borderNo none=''
                            sx={{ '.MuiSelect-select': { color: info?.pickup_car?.car_id === undefined ? '#ccc' : '#000' }, 'svg': { color: info?.pickup_car?.car_id === undefined ? '#ccc' : '#000' } }}
                        >
                            <MenuItem value='' disabled>차량 선택</MenuItem>
                            {carList.map((data: any, index: any) => {
                                return (
                                    <MenuItem key={`car-${index}`} value={data.car_id}>{data?.car_name} (+{numberWithComma(data?.car_price)}원)</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </InputItemWrap>
                <InputItemWrap cr padding='0'>
                    {paymentList.length === 0 ?
                        <></> :
                        <SelectItem
                            value={info?.pickup_payment ?? ''}
                            borderNo none=''
                            sx={{ '.MuiSelect-select': { color: info?.pickup_payment === '' ? '#ccc' : '#000' }, 'svg': { color: info?.pickup_payment === '' ? '#ccc' : '#000' } }}
                            setValue={(val: any) => valueChange('pickup_payment', val)}
                        >
                            <MenuItem value='' disabled>결제방법</MenuItem>
                            {paymentList.map((data: any, index: any) => {
                                return (
                                    <MenuItem key={`pay-${index}`} value={data?.payment_name}>{data?.payment_name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.pickup_message} onChange={(e: any) => valueChange('pickup_message', e.target.value)} maxLength={350} placeholder={`추가 요청사항(탑승자가 다를 경우도 성함 및 연락처 입력해주세요)`}></input>
                </InputItemWrap>
            </MoreWrap>
            <AirportBtnItem prevAction={() => setStep(1)} nextAction={nextAction} />
            <ModalDatePage title={dateOpen.name} open={dateOpen.open} setOpen={setDateOpen} valueChange={valueChange} info={info} setTimeOpen={setTimeOpen} />
            <ModalTimePage title={timeOpen.name} open={timeOpen.open} setOpen={setTimeOpen} valueChange={valueChange} info={info} />
        </Wrap>
    )
}

const Wrap = styled.div``;

const InputWrap = styled(Row)`
    margin-top: 40px;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    position: relative;
    padding-left: 35px;
`;

const DecoWrap = styled(RowBetween)`
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 18px;
    &>img{
        width: 18px;
        position: relative;
    }
    &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 1px;
        height: calc(100% - 36px);
        border: 1px dashed #D4D4D4;
    }
`;
const InputItemWrap = styled(Row) <{ cr?: boolean, h?: string, mt?: string, padding?: string }>`
    background-color: #F5F5F5;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    width: 100%;
    height: ${({ h }) => h ?? '52px'};
    padding: ${({ padding }) => padding ?? '10px 18px'};
    cursor: ${({ cr }) => cr ? 'pointer' : 'default'};
    margin-top: ${({ mt }) => mt ?? '0px'};
    &>input{
        width: 100%;
        height: 100%;
        ::placeholder {
            color: #ccc;
        }
    }
    &>textarea{
        width: 100%;
        font-size: 16px;
        ::placeholder {
            color: #ccc;
        }
    }
`;

const MoreInputWrap = styled(InputItemWrap)`
    justify-content: space-between;
    & input{
        width: auto;
        text-align: right;
    }
`;

const MoreWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    align-items: start;
    margin-top: 30px;
    padding-bottom: 30px;
`;