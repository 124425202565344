import React from 'react'
import styled from 'styled-components'
import ServicePart01 from '../part/ServicePart01';
import ServicePart02 from '../part/ServicePart02';
import ServicePart03 from '../part/ServicePart03';
import ServiceTitleItem from '../item/ServiceTitleItem';
import FaqPart from '../part/FaqPart';
import { Center } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import ServiceAirportCarPart from '../part/ServiceAirportCarPart';
import ServiceBtnPart from '../part/ServiceBtnPart';

const PART02 = [
    {title: '저렴한 비용', img: '/img/asset/service/service1.png', content1: '인천공항 주차장 대비 <strong>60% 저렴!</strong>', content2: '실내주차를 일 5천원(6일차 기준)으로 이용해보세요!'},
    {title: '안전한 주차', img: '/img/asset/service/service2.png', content1: '100% 실내 주차, 24시간 80여개의 CCTV로', content2: '안전한 주차 서비스를 제공합니다!'},
    {title: '편리한 픽업', img: '/img/asset/service/service3.png', content1: '언제든 공항에서 에어드라이브 주차장까지', content2: '무료로 픽업차량을 빠르고 편하게 이용해보세요!'},
]

export default function ServiceAirportPage() {
    const navigate = useNavigate();

    return (
        <Wrap>
            <ServicePart01 text1='집 앞부터 공항까지 합리적인 여행의 파트너' text2='집에서부터 편하게' text3='시작하는 여행,' span1='에어드라이브' img='/img/asset/service_airport.png' />
            <ServicePart02 mt='140px' title='Why AirDrive?' subTitle={['여행의 시작을 에어드라이브와 함께 편안하게!','여행 중에는 귀하의 차량을 안전하게!']} content={PART02} />
            <ServiceTitleItem mt='140px' title='서비스 안내' title1='<span>요금은 낮게,혜택은 가득한</span> 합리적인' title2='주차 대행 서비스, 에어드라이브를 이용해보세요.'/>
            <ServicePart03 />
            <ServiceTitleItem mt='140px' title='차량 안내' title1='<strong>고객님의 여행의 편안함을 위한</strong>' title2='<strong>에어드라이브의 다양한 차량들을 확인하세요!</strong>'/>
            <ServiceAirportCarPart />
            <FaqPart type='공항' />
            <BtnWrap>
                <BtnItem onClick={()=>navigate('/airport')}>공항이동 예약하기</BtnItem>
            </BtnWrap>
            <ServiceBtnPart reservationAction={()=>navigate('/airport')}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnWrap = styled(Center)`
    margin: 80px 0 120px;
`;

const BtnItem = styled(Center)`
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    width: 260px;
    cursor: pointer;
`;