import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import { TbLoader } from 'react-icons/tb';
import { apiGetLogin } from '../../../api/api_login';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../../store/common/user';
import { useNavigate } from 'react-router';
import { useGlobalAirport } from '../../../utils/custom-hooks/common/useAirport';

export default function VerifySignPage() {
    const params = new URLSearchParams(window.location.search);
    const tokenValue = params.get('token') ?? '';
    const airport = useGlobalAirport();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        if(tokenValue === '') return
        getInfo();
    },[tokenValue,airport.pickup_state]);

    const getInfo = async() =>{
        const res = await apiGetLogin(tokenValue);
        if(res.result){
            dispatch(setUserInfo({...res.data}));
            if(res.data.is_already_sign){
                if(airport.pickup_state){
                    navigate('/airport');
                    return;
                }
                navigate('/');
                return
            }
            navigate('/joinDone');
        }    
    }
    
    return (
        <Wrap>
            <TbLoader size={38} color='#000'/>
        </Wrap>
    )
}

const ani = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Wrap = styled(Center)`
    height: 380px;
    &>svg{
        animation: ${ani} 1.8s ease-in-out infinite;
    }
`;