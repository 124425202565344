import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import DaumPostcode from "react-daum-postcode";

export default function ModalAddrPage({ open, setOpen, valueChange }: any) {
    const handleClose = () => {
        setOpen(false);
    }

    const handleComplete = (data:any) => {
        valueChange('pickup_depart_location',data);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor:'#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={28} mFontSize={22} align='center' color='#1c1c1c'>주소를 입력해주세요.</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                    <DaumPostcode
                        autoClose={true}
                        style={{ backgroundColor: 'transparent', background: 'transparent' }}
                        onComplete={handleComplete}
                    />
                </ContentWrap>
            </DialogContent>
        </Dialog>
    )
}

const ContentWrap = styled(Row)`
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    &>div{
        width: calc(50% - 5px);
    }
`;
const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;