import React, { useState } from 'react'
import styled from 'styled-components'
import { BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { ORDER_STATUS_LIST, SIGN_TYPE } from '../../../../data/airport';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { addTheme } from '../../../../style/addTheme';
import AdminModalAddSendPart from './AdminModalAddSendPart';

export default function AdminAirportDetailUserPart({info,valueChange,type}:any) {
    const [open,setOpen] = useState(false);
    const alarmView = () =>{

    }
    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={140}>
                    <BasicText>소셜</BasicText>
                    <InputWrap>
                        <BasicText>{SIGN_TYPE[info?.user_sign_type]}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={140}>
                    <BasicText>이름</BasicText>
                    <InputWrap>
                        <BasicText>{info?.user_name}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={140}>
                    <BasicText>전화번호</BasicText>
                    <InputWrap>
                        <BasicText>{info?.user_phone_number === ' ' ? '-' : info?.user_phone_number}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={140}>
                    <BasicText>이메일</BasicText>
                    <InputWrap>
                        <BasicText>{info?.user_email === ' ' ? '-' : info?.user_email}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={140}>
                    <BasicText>예약 상태</BasicText>
                    <InputWrap padding='0'>
                        <SelectItem value={info?.order_pickup_status ?? info?.order_parking_status ?? 1} setValue={(val: any) => valueChange(type === 'parking' ? 'order_parking_status' : 'order_pickup_status', val)} none='no'>
                            {ORDER_STATUS_LIST.map((data: any, index: any) => {
                                return (
                                    <MenuItem key={`state-${index}`} value={data.value}>{data.name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={140}>
                    <BasicText>알림톡 상태</BasicText>
                    <AlarmWrap>
                        <InputWrap>
                            <BasicText>{'전송 성공'}</BasicText>
                        </InputWrap>
                        <BtnItem bg='#1D4579' height={'42px'} onClick={alarmView}>보기</BtnItem>
                        <BtnItem bg='#226E4B' height={'42px'} onClick={()=>setOpen(true)}>추가 전송</BtnItem>
                    </AlarmWrap>
                </ItemWrap>
                <ItemWrap pWidth={140} align='start'>
                    <BasicText>메모</BasicText>
                    <InputWrap>
                        <textarea rows={8} value={info?.order_pickup_memo ?? info?.order_parking_memo ?? ''} onChange={(e:any)=> valueChange(type === 'parking' ? 'order_parking_memo' : 'order_pickup_memo',e.target.value)} placeholder='메모'/>
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
            <AdminModalAddSendPart info={info} open={open} setOpen={setOpen} type={type} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const AlarmWrap = styled(Row)`
    gap: 10px;
    &>div{
        width: auto;
    }
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
    }
`;