import React, { useEffect } from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

const TITLE = [
    {name: '내 정보', url:'/mypage/info'},
    {name: '예약 정보', url:'/mypage/reservation'},
    {name: '내 후기', url:'/mypage/review'},
]

export default function MyPage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const {pathname} = useLocation();

    useEffect(()=>{
        if(userInfo.user_id === 0){
            navigate('/');
        }
    },[userInfo.user_id])
    
    const titleClick = (url:string) =>{
        navigate(url);
    }
    
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={40} mFontSize={32} align='center'>마이페이지</BasicText>
            <TitleWrap>
                {TITLE.map((data:any,index:any)=>{
                    return(
                        <TitleItem key={`my-${index}`} $active={pathname === data.url} onClick={()=>titleClick(data.url)}>
                            {data.name}
                            <TitleBorderItem $active={pathname === data.url} />
                        </TitleItem>
                    )
                })}
            </TitleWrap>
            <ContentWrap>
                <MaxWidthWrap maxWidth='720px'>
                    <Outlet />
                </MaxWidthWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding-top: 80px;
    min-height: 600px;
    @media ${addTheme.media.s}{
        min-height: 500px;
    }
`;
const TitleWrap = styled(Center)`
    margin-top: 36px;
`;
const TitleItem = styled.div<{$active:boolean}>`
    font-size: 20px;
    width: 100%;
    text-align: center;
    max-width: 110px;
    cursor: pointer;
    font-weight: ${({$active}) => $active ? 700 : 400};
    position: relative;
    padding: 0 0 10px;
    transition: 0.2s ease-in-out;
    @media ${addTheme.media.s}{
        font-size: 18px;
    }
`;

const TitleBorderItem = styled.div<{$active:boolean}>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: ${({$active}) => $active ? '40px' : '0'};
    margin: 0 auto;
    height: 3px;
    background-color: #000;
    border-radius: 52px;
    transition: 0.28s ease-in-out;
`;

const ContentWrap = styled.div`
    margin-top: 50px;
`;