import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BtnItem, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { KAKAO_LINK } from '../../../api/config';

export default function FooterPart() {
    const companyInfo = useGlobalCompanyInfo();
    const navigate = useNavigate();

    // 카카오
    const kakaoClick = () => {
        window.open(KAKAO_LINK);
    }

    const goTerms = (link: string) => {
        navigate(link);
    }

    return (
        <Wrap>
            <ContentTopWrap>
                <MaxWidthWrap padding='0'>
                    <ContentTopInnerWrap>
                        <div>
                            <BasicText color='#191919' fontSize={19} fontWeight={700}>1883-4421</BasicText>
                            <BasicText color='#7E7E7E' fontSize={15} marginTop={0.5}>운영시간 연중무휴 9:00~0:00</BasicText>
                            <BtnItem bg={addTheme.kakaoColor} color='#3C1E1E' margin='10px 0 0' onClick={kakaoClick}>카카오톡으로 상담받기</BtnItem>
                        </div>
                        <TermsWrap>
                            <BasicText onClick={() => goTerms('/service')} color='#7E7E7E' cr='pointer' mFontSize={15} fontWeight={700}>서비스 이용약관</BasicText>
                            <BasicText onClick={() => goTerms('/personal')} color='#7E7E7E' cr='pointer' mFontSize={15}>개인정보 처리방침</BasicText>
                        </TermsWrap>
                    </ContentTopInnerWrap>
                </MaxWidthWrap>
            </ContentTopWrap>
            <MaxWidthWrap>
                <ContentWrap>
                    <BasicText color='#222222' fontWeight={400} fontSize={18}>에어드라이브 사업자 정보</BasicText>
                    <TextWrap>
                        <BasicText fontSize={14} color='#5c5c5c'>(주)에어드라이브</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>사업자번호: 146-19-01383</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>대표자: 오근석</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>통신판매업신고번호: 2021-인천중구-0408</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>주소 : 인천광역시 중구 영종대로 192 305F</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>개인정보보호책임자 : 오택준(email@gmail.com)</BasicText>
                        {/* <BasicText fontSize={14} color='#5c5c5c'>상호: {companyInfo['c_config_business_name']}</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>회사명: {companyInfo['config_company_name']}</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>사업자번호: {companyInfo['config_business_number']}</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>대표자: {companyInfo['config_company_repre_name']}</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>대표전화: {companyInfo['config_company_call_number']}</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>개인정보책임자: {companyInfo['config_private_info_name']}</BasicText>
                        <BasicText fontSize={14} color='#5c5c5c'>사업장 주소: {companyInfo['config_company_address']}</BasicText> */}
                    </TextWrap>
                </ContentWrap>
            </MaxWidthWrap>
            <MoTermsWrap>
                <MaxWidthWrap>
                    <MoTermsInnerWrap>
                        <BasicText onClick={() => goTerms('/service')} color='#7E7E7E' cr='pointer' mFontSize={15} fontWeight={700}>서비스 이용약관</BasicText>
                        <BasicText onClick={() => goTerms('/personal')} color='#7E7E7E' cr='pointer' mFontSize={15}>개인정보 처리방침</BasicText>
                    </MoTermsInnerWrap>
                </MaxWidthWrap>
            </MoTermsWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #fff;
    padding: 30px 0 110px;
`;

const ContentTopWrap = styled.div`
    border-bottom: 1px solid #c2c2c2;
    margin: 0 15px;
`;

const ContentTopInnerWrap = styled(RowBetween)`
    padding-bottom: 35px;
    align-items: start;
    flex-wrap: wrap;
`;
const TermsWrap = styled(Row)`
    width: auto;
    gap: 20px;
    @media ${addTheme.media.m}{
        display: none;
    }
`;
const MoTermsWrap = styled.div`
    border-top: 1px solid #c2c2c2;
    margin: 0 15px;
    display: none;
    @media ${addTheme.media.m}{
        display: block;
    }
`;
const MoTermsInnerWrap = styled(Row)`
    padding: 15px 0;
    gap: 15px;
`;
const ContentWrap = styled.div`
    padding: 30px 0;
`;
const TextWrap = styled(Row)`
    margin-top: 15px;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    align-items: start;
`;