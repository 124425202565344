import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MENU_LIST } from '../../../data/menu';
import { useLocation, useNavigate } from 'react-router';
import { LuSearch, LuUser } from "react-icons/lu";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import MoMenuPart from '../part/MoMenuPart';
import SearchPart from '../part/SearchPart';
import { Fade, Menu, MenuItem } from '@mui/material';
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useDispatch } from 'react-redux';
import { setUserInfoLogOutAction } from '../../../store/common/user';

export default function HeaderPart() {
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const dispatch = useDispatch();
    const [menuState,setMenuState] = useState(0);
    const [open,setOpen] = useState(false);
    const [anchorEl,setAnchorEl] = useState<any>(null);
    const userOpen = Boolean(anchorEl);

    useEffect(()=>{
        if(location.pathname === '/'){
            setMenuState(0);
        }else{
            MENU_LIST.map((data:any,index:any)=>{
                if(location.pathname.includes(data.path)){
                    setMenuState(index);
                }
            })
        }
    },[location.pathname]);

    const menuClick = (router:string) =>{
        navigate(router);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const userMenuClick = (name:string) =>{
        handleClose();
        navigate(name);
    }

    const logout = () =>{
        dispatch(setUserInfoLogOutAction());
    }
    
    const goMyPage = () =>{
        navigate('/mypage/info');
    }

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <MenuWrap>
                        <LogoWrap onClick={()=>menuClick('/')}>
                            <img src='/img/asset/logo.svg'></img>
                        </LogoWrap>
                    </MenuWrap>
                    <IconWrap>
                        {userInfo.user_id === 0 ?
                            <>
                                <BasicText onClick={()=>menuClick('/join')} color='#111' cr='pointer'>회원가입</BasicText>
                                <JoinBtnItem onClick={()=>menuClick('/login')}>로그인</JoinBtnItem> 
                            </> :
                            <>
                                <BasicText onClick={goMyPage} color='#111' cr='pointer'>{userInfo.user_name} 님</BasicText>
                                <JoinBtnItem onClick={logout}>로그아웃</JoinBtnItem> 
                            </>
                        }
                        {/* <SearchWrap>
                            <LuSearch onClick={()=>setOpen(true)} size={18}/>
                        </SearchWrap> */}
                        {/* <UserWrap onClick={handleClick}>
                            <LuUser size={22}/>
                            {userOpen ? 
                                <IoIosArrowUp size={18} /> :
                                <IoIosArrowDown size={18}/>
                            }
                        </UserWrap>
                        <Menu anchorEl={anchorEl} open={userOpen} onClose={handleClose} TransitionComponent={Fade}>
                            <MenuItem sx={{fontSize:'15px'}} onClick={()=>userMenuClick('/')}>마이페이지</MenuItem>
                        </Menu> */}
                        {/* <MoMenuWrap>
                            <MoMenuPart menuState={menuState} />
                        </MoMenuWrap> */}
                    </IconWrap>
                </InnerWrap>
            </MaxWidthWrap>
            <SearchPart open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div`
    border-bottom: 1px solid #ddd;
`;
const InnerWrap = styled(RowBetween)`
    padding: 15px 0;
`;
const MenuWrap = styled(Row)`
    width: auto;
    gap: 25px;
`;

const LogoWrap = styled.div`
    & img{
        height: 30px;
    }
    @media ${addTheme.media.s}{
        & img{
            height: 24px;
        }
    }
`;

const IconWrap = styled(Row)`
    width: auto;
    gap: 35px;
    @media ${addTheme.media.m}{
        &>p{
            display: none;
        }
    }
`;

const JoinBtnItem = styled(Center)`
    background-color: #121212;
    padding: 8px 18px;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
`;

const SearchWrap = styled.div`
    cursor: pointer;
`;
const UserWrap = styled(Row)`
    width: auto;
    cursor: pointer;
`;

const MoMenuWrap = styled.div`
    display: none;
    @media ${addTheme.media.m}{
        display: block;
    }
`;