import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

export default function ModalDatePage({ title, open, setOpen, valueChange, info, setTimeOpen, type }: any) {
    const [date, setDate] = useState<any>(new Date());
    const [minDate,setMinDate] = useState<any>(new Date());

    useEffect(()=>{
        if(title === '복귀'){
            if(type === 'parking'){
                setDate(new Date(info?.parking_time_depart_timestamp))
                setMinDate(new Date(info?.parking_time_depart_timestamp));
            }else{
                setDate(new Date(info?.pickup_time_depart_timestamp))
                setMinDate(new Date(info?.pickup_time_depart_timestamp));
            }
        }else{
            setDate(new Date())
            setMinDate(new Date());
        }
    },[title,info?.pickup_time_depart_timestamp,type,info?.parking_time_depart_timestamp])

    const handleClose = () => {
        setOpen({
            name: '',
            open: false,
        });
        setDate(new Date());
    }
    const nextAction = () =>{
        if(title === '출발'){
            if(type === 'parking'){
                valueChange('parking_time_depart_timestamp',date.getTime());
            }else{
                valueChange('pickup_time_depart_timestamp',date.getTime());
            }
        }
        if(title === '복귀'){
            if(type === 'parking'){
                valueChange('parking_time_arrive_timestamp',date.getTime());
            }else{
                valueChange('pickup_time_arrive_timestamp',date.getTime());
            }
        }
        handleClose();
        setTimeOpen({
            name: title,
            open: true,
        });
    }
    // 요일 한글로 설정
    const formatShortWeekday = (locale:any, date:any) => {
        const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
        return weekdays[date.getDay()];
    };

    // 월 숫자로 표시
    const formatMonth = (locale:any, date:any) => {
        return date.getMonth() + 1; // 0부터 시작하므로 1을 더함
    };

    const tileClassName = ({ date, view }:any) => {
        if (view === 'month') {
            const day = date.getDay();
            if (day === 0 || day === 6) { // 0: 일요일, 6: 토요일
                return 'highlight'; // CSS 클래스 이름
            }
        }
        return null;
    };
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor:'#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={28} mFontSize={22} align='center' color='#1c1c1c'>날짜를 선택해주세요.</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                    <Calendar
                        onChange={setDate}
                        value={date}
                        locale="ko"
                        formatShortWeekday={formatShortWeekday}
                        formatMonth={formatMonth}
                        formatDay={(locale, date) => moment(date).format("DD")}
                        calendarType="hebrew"
                        tileClassName={tileClassName}
                        view='month'
                        showNeighboringCentury={false}
                        showNeighboringDecade={false}
                        prev2Label={null}
                        next2Label={null}
                        minDate={minDate}
                    />
                </ContentWrap>
            </DialogContent>
            <DialogActions sx={{justifyContent:'space-between',padding: '50px 24px 20px'}}>
                <PrevBtnItem onClick={handleClose}>취소</PrevBtnItem>
                <NextBtnItem onClick={nextAction}>다음</NextBtnItem>
            </DialogActions>
        </Dialog>
    )
}

const ContentWrap = styled(Center)`
    &>div{
        border: none;
    }
    & .react-calendar__month-view__weekdays__weekday abbr{
        text-decoration: none;
    }
    & .react-calendar__month-view__days__day--weekend{
        color: #000;
    }
    & .highlight{
        color: red;
    }
    & .react-calendar__tile:disabled{
        color: #ababab !important;
    }
    & .react-calendar__month-view__days__day--neighboringMonth{
        opacity: 0.6;
    }
    & .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
        background-color: #000;
        color: #fff;
    }
    & .react-calendar__tile--active{
        background: #000 !important;
        color: #fff !important;
    }
    & .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus{
        background: #e6e6e6;
        color: #000;
    }
    & .react-calendar__tile--now{
        background: #bcbcbc;
    }
`;
const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;
const PrevBtnItem = styled(Btn)`
    width: 38%;
    background-color: #F5F5F5;
    color: #2A2A2A;
`;
const NextBtnItem = styled(Btn)`
    width: 60%;
    background-color: #181818;
    color: #fff;
`;