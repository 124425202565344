import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { addTheme } from '../../../../style/addTheme';
import { ALARM_LIST } from '../../data/table';
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import NoListItem from '../../common/item/NoListItem';
import AdminAlarmTalkListItem from '../item/AdminAlarmTalkListItem';
import { apiAdmGetAlarmList, apiAdmGetAlarmUrl } from '../../../../api/api_adm_alarm';

export default function AdminAlarmTalkPage() {
    const {page} = useParams();
    const resPage = page ?? 1;
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[adminUser.admin_id,resPage]);
    
    const setPage = (p:any) =>{
        navigate(`/admin/alarmTalk/${p}`);
    }

    const getList = async() =>{
        const res = await apiAdmGetAlarmList(adminUser.admin_token,adminUser.admin_id,resPage,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const goUrl = async() =>{
        const res = await apiAdmGetAlarmUrl(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            const width = 680; 
            const height = 700; 
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2;
            const options = `width=${width},height=${height},left=${left},top=${top}`;
            window.open(res.data.url, '_blank', options);
        }
    }

    const goUpdate = () => {
        navigate('/admin/alarmTalkUpdate');
    }

    return (
        <Wrap>
            <TopWrap>
                <CountWrap>
                    <AdminPageTotalPart title='전체 알림톡' part='talk' unit='건' />
                    <AdminPageTotalPart title='잔여 알림톡' part='talk' unit='건' />
                </CountWrap>
                <TopBtnWrap>
                    <BtnItem bg={addTheme.detailBtnBg} onClick={goUrl}>채널, 템플릿 관리</BtnItem>
                    <BtnItem bg={addTheme.infoChangeBtnBg}>알림톡 충전</BtnItem>
                </TopBtnWrap>
            </TopWrap>
            <AdminWrap>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>알림톡 리스트</BasicText>
                <AdminTablePart title={ALARM_LIST} minWidth={1100} page={resPage} setPage={setPage} total={total} mt='30px'>
                    {list.length === 0 ?
                        <NoListItem length={ALARM_LIST.length} /> :
                        list.map((data:any,index:any)=>{
                            return(
                                <AdminAlarmTalkListItem key={`alarm-${index}`} data={data} />
                            )
                        })
                    }
                </AdminTablePart>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TopWrap = styled(RowBetween)`
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    flex-wrap: wrap;
`;
const CountWrap = styled(Row)`
    flex-wrap: wrap;
    width: auto;
    &>div{
        width: auto;
    }
`;

const TopBtnWrap = styled(Row)`
    width: auto;
    padding: 15px 20px;
    gap: 10px;
    flex-wrap: wrap;
`;