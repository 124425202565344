import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import HomePart02Item from '../item/HomePart02Item';
import { addTheme } from '../../../style/addTheme';

const LIST = [
    {title:'공항이동 서비스', img: '/img/asset/home-icon1.png', desc: '서비스 소개', type: 'airport'},
    {title:'주차대행 서비스', img: '/img/asset/home-icon2.png', desc: '서비스 소개', type: 'parking'},
    {title:'문의 및 상담', img: '/img/asset/home-icon3.png', desc: '1833-4421', type: 'inquiry'},
]

export default function HomePart02() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    {LIST.map((data:any,index:any)=>{
                        return(
                            <HomePart02Item key={`part02-${index}`} data={data} />
                        )
                    })}
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 30px;
`;
const InnerWrap = styled(Row)`
    gap: 16px;
    flex-wrap: wrap;
    &>div{
        width: calc(33.33% - 10.66px);
    }
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
`;