import React from 'react'
import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useGlobalParking } from '../../../utils/custom-hooks/common/useParking';
import { setGlobalParking } from '../../../store/common/globalParking';
import { LOGIN_LINK } from '../../../api/api_login';
import { KAKAO_LINK, PHONE } from '../../../api/config';

export default function ParkingResultPart({ setStep }: any) {
    const userInfo = useUserInfo();
    const mediaQuery = useMediaQuery(addTheme.media.s);

    const dispatch = useDispatch();
    const parking = useGlobalParking

    const noMemberAction = (link: string) => {
        dispatch(setGlobalParking({
            ...parking,
            pickup_state: true,
        }))
        window.location.href = LOGIN_LINK + link
    }

    const kakaoClick = () => {
        window.open(KAKAO_LINK);
    }

    const phoneClick = () => {
        window.location.href = 'tel:' + PHONE;
    }
    return (
        <Wrap>
            {userInfo.user_id === 0 ?
                <BasicText fontWeight={700} fontSize={29} mFontSize={21}>로그인하면 바로 견적서를 확인할 수 있어요.</BasicText> :
                <>
                    <BasicText fontWeight={700} fontSize={29} mFontSize={21}>견적 및 예약접수가 완료되었습니다.</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16} marginTop={1.5}>순차적으로 예약확인중이므로</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16}>남겨주신 연락처 카톡, 문자로 연락드릴예정이오니</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16}>빠른시간내로 접수진행 연락드리도록 하겠습니다.</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16} marginTop={1.5}>에어드라이브는 예약제 운영중입니다.</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16} spColor='#FF2B2B'><span>탑승 24시간 전 예약</span>은 차량 배차가능여부에 따라</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16}>예약접수가 가능하오니</BasicText>
                    <BasicText fontWeight={700} fontSize={20} mFontSize={16} spColor='#FF2B2B'><span>1833-4421</span> 꼭 연락부탁드립겠습니다.</BasicText>
                </>
            }
            <ContentWrap>
                {userInfo.user_id === 0 ?
                    <>
                        <BtnWrap onClick={() => noMemberAction('kakao')} bg={addTheme.kakaoColor}>
                            <img src='/img/asset/kakao.svg' alt='kakao'></img>
                            카카오로 계속하기
                            <div></div>
                        </BtnWrap>
                        <BtnWrap onClick={() => noMemberAction('naver')} bg={addTheme.naverColor} color='#fff'>
                            <img src='/img/asset/naver.svg' alt='naver'></img>
                            네이버로 계속하기
                            <div></div>
                        </BtnWrap>
                    </> :
                    <>
                        <BtnWrap bg={addTheme.kakaoColor} onClick={kakaoClick}>
                            <img src='/img/asset/kakao.svg' alt='kakao'></img>
                            카톡 상담하기
                            <div></div>
                        </BtnWrap>
                        <BtnWrap bg={'#050505'} color='#F5F5F5' onClick={phoneClick}>
                            <img src='/img/asset/phone.png' alt='phone' style={{ height: '19px' }}></img>
                            전화로 예약 확인하기
                            <div></div>
                        </BtnWrap>
                        {mediaQuery &&
                            <BtnWrap bg='#fff' border onClick={() => setStep(4)}>
                                <div></div>
                                내 견적서 확인하기
                                <div></div>
                            </BtnWrap>
                        }
                    </>
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const ContentWrap = styled(Row)`
    margin-top: 40px;
    gap: 12px;
    align-items: start;
    flex-direction: column;
`;

const BtnWrap = styled(RowBetween) <{ bg: string, color?: string, border?: boolean }>`
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: ${({ bg }) => bg ?? '#fff'};
    color: ${({ color }) => color ?? '#000'};
    font-size: 15px;
    padding: 0 18px;
    border: ${({ border }) => border ? '1px solid #000' : '1px solid transparent'};
    cursor: pointer;
    text-align: center;
    &>div{
        width: 20px;
    }
`;