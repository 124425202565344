import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { globalAirportReset } from '../../../store/common/globalAirport';
import { globalParkingReset } from '../../../store/common/globalParking';

const LINK: any = {
    1: 'airport',
    2: 'parking',
}

export default function ModalOrderTypePage({ open, setOpen }: any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [select, setSelect] = useState(0);

    const handleClose = () => {
        setOpen(false);
        setSelect(0);
    }
    const nextAction = () => {
        dispatch(globalAirportReset());
        dispatch(globalParkingReset());
        navigate(`/${LINK[select]}`);
        handleClose();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor:'#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={28} mFontSize={22} align='center' color='#1c1c1c'>서비스 유형을 선택해주세요.</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                    <TypeItemWrap $active={select === 1} onClick={() => setSelect(1)}>
                        <BasicText fontWeight={700} fontSize={20} mFontSize={18}>공항 이동</BasicText>
                        <BasicText mFontSize={15}>공항 갈 때, 올 때</BasicText>
                    </TypeItemWrap>
                    <TypeItemWrap $active={select === 2} onClick={() => setSelect(2)}>
                        <BasicText fontWeight={700} fontSize={20} mFontSize={18}>주차 대행</BasicText>
                        <BasicText mFontSize={15}>주차가 필요할 때</BasicText>
                    </TypeItemWrap>
                </ContentWrap>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '80px 24px 20px' }}>
                <PrevBtnItem onClick={handleClose}>취소</PrevBtnItem>
                <NextBtnItem onClick={nextAction}>다음</NextBtnItem>
            </DialogActions>
        </Dialog>
    )
}

const ContentWrap = styled(Row)`
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    &>div{
        width: calc(50% - 5px);
    }
`;

const TypeItemWrap = styled.div<{ $active: boolean }>`
    background-color: ${({ $active }) => $active ? '#181818' : '#F8F8F8'};
    border-radius: 10px;
    padding: 20px 20px 28px 20px;
    cursor: pointer;
    &>p{
        color: ${({ $active }) => $active ? '#fff' : '#1C1C1C'};
    }
`;
const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;
const PrevBtnItem = styled(Btn)`
    width: 38%;
    background-color: #F5F5F5;
    color: #2A2A2A;
`;
const NextBtnItem = styled(Btn)`
    width: 60%;
    background-color: #181818;
    color: #fff;
`;