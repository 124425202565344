import React from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { Center, RowBetween } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';

export default function HomePart03Item({data,btnActive}:any) {
    const navigate = useNavigate();
    const goReservation = (link:any) =>{
        navigate(link)
    }
    return (
        <Wrap>
            <InnerWrap>
                <TextWrap>
                    <BasicText fontWeight={700} fontSize={24} mFontSize={20}>{data?.title1}</BasicText>
                    <BasicText fontWeight={700} fontSize={24} mFontSize={20}>{data?.title2}</BasicText>
                    <BasicText fontSize={15} marginTop={1}>{data?.desc1}</BasicText>
                </TextWrap>
                <BtnWrap>
                    {btnActive === 1 &&
                        <BtnItem onClick={()=>goReservation('/airport')}>공항이동 예약하기</BtnItem>
                    }
                    {btnActive === 2 &&
                        <BtnItem onClick={()=>goReservation('/parking')}>주차대행 예약하기</BtnItem>
                    }
                </BtnWrap>
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    position: relative;
    ::after{
        content: '';
        padding-bottom: 110%;
        display: block;
    }
`;

const InnerWrap = styled(RowBetween)`
    position: absolute;
    align-items: start;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    border-radius: 8px;
    padding: 35px 25px;
    flex-direction: column;
    @media ${addTheme.media.m}{
        padding: 25px 15px;
    }
`;

const TextWrap = styled.div`
    
`;

const BtnWrap = styled.div`
    width: 100%;
`;
const BtnItem = styled(Center)`
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    height: 42px;
    cursor: pointer;
`;