import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { BtnItem, Center } from '../../../../style/basic/commonStyle'
import { TABLE_MEMBER } from '../../data/table'
import { addTheme } from '../../../../style/addTheme'
import { momentFormat } from '../../../../utils/format/time'
import { SIGN_TYPE } from '../../../../data/airport'

export default function AdminMemberListItem({data,goDetail}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[0].align}>{data.user_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[1].align}>{SIGN_TYPE[data.user_sign_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[2].align}>{data.user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[3].align}>{data.user_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[4].align}>{data.user_email}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[5].align}>{momentFormat('YYYY.MM.DD HH:mm',data.user_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[6].align}>{momentFormat('YYYY.MM.DD HH:mm',data.user_last_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_MEMBER[7].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem onClick={()=>goDetail(data.user_id)} bg={addTheme.detailBtnBg} round={35} padding='7px 16px'>상세</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
