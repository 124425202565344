export const MENU_LIST = [
    {id: 1, name:'모바일', path: '/detail', router: ''},
    {id: 3, name:'구매후기', path: '/review', router: ''},
    {id: 4, name:'자주 묻는 질문', path: '/faq', router: ''},
    {id: 5, name:'1:1 문의', path: '/inquiry', router: ''},
]

export const ADM_MENU_LIST = [
    {id: '1', name: '회원 관리',path: 'member', router: '/admin/member/1', subList: []},
    {id: '2', name: '예약 관리',path: '', router: '/admin/airport/1', subList:[
        {id: '1', name: '공항 이동', path: 'airport', router: '/admin/airport/1',},
        {id: '2', name: '주차 대행', path: 'parking', router: '/admin/parking/1'},
    ]},
    {id: '3', name: '상품 관리',path: 'product', router: '/admin/product', subList: []},
    {id: '4', name: '후기 관리',path: 'review', router: '/admin/review/1', subList: []},
    {id: '5', name: '배너 관리',path: 'banner', router: '/admin/banner/1', subList: []},
    {id: '6', name: 'FAQ',path: 'faq', router: '/admin/faq', subList: []},
    {id: '7', name: '알림톡 관리',path: 'alarmTalk', router: '/admin/alarmTalk/1', subList: []},
]