import React from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';

export default function ServiceSlideOutItem({data}:any) {
    return (
        <Wrap>
            <TextWrap>
                <BasicText fontWeight={700} fontSize={24} mFontSize={20}>{data?.title1}</BasicText>
                <BasicText fontWeight={700} fontSize={24} mFontSize={20}>{data?.title2}</BasicText>
                <BasicText fontSize={15} marginTop={1}>{data?.desc1}</BasicText>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    position: relative;
    ::after{
        content: '';
        padding-bottom: 110%;
        display: block;
    }
`;

const TextWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    border-radius: 8px;
    padding: 35px 25px;
    @media ${addTheme.media.m}{
        padding: 25px 15px;
    }
`;