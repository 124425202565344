import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

export default function ModalTimePage({ title, open, setOpen, info, valueChange, type }: any) {
    const [time, setTime] = useState<any>(moment().hour(12).minute(0));
    const [cutTime,setCutTime] = useState<any>({
        hour: null,
        min: null,
    });
    
    useEffect(()=>{
        // 공항 픽업에서 사용
        if(title === '비행기 출발' || title === '비행기 도착'){
            let newDate = new Date();
            if(title === '비행기 출발'){
                newDate = new Date(info?.pickup_time_depart_timestamp);
            }else if(title === '비행기 도착'){
                newDate = new Date(info?.pickup_time_arrive_timestamp);
            }
            const h = newDate.getHours();
            const m = newDate.getMinutes()+5
            setTime(moment().hour(h).minute(m));
            setCutTime({
                hour: h,
                min: m,
            })
        }else{
            setTime(moment().hour(12).minute(0));
            setCutTime({
                hour: null,
                min: null,
            })
        }
    },[title,info?.pickup_time_depart_timestamp,info?.pickup_time_arrive_timestamp])

    const handleClose = () => {
        setOpen({name:'',open:false});
        setTime(moment().hour(12).minute(0));
    }
    const nextAction = () => {
        const res = formatTime(time);
        if(res === ''){
            return
        }
        
        if(title === '출발'){
            const newDate = type === 'parking' ? new Date(info.parking_time_depart_timestamp) : new Date(info.pickup_time_depart_timestamp);
            newDate.setHours(time.hour());
            newDate.setMinutes(time.minute());
            if(type === 'parking'){
                valueChange('parking_time_depart_timestamp', newDate.getTime());
            }else{
                valueChange('pickup_time_depart_timestamp', newDate.getTime());
            }
        }
        if(title === '복귀'){
            const newDate = type === 'parking' ? new Date(info.parking_time_arrive_timestamp) : new Date(info.pickup_time_arrive_timestamp);
            newDate.setHours(time.hour());
            newDate.setMinutes(time.minute());
            if(type === 'parking'){
                valueChange('parking_time_arrive_timestamp', newDate.getTime());
            }else{
                valueChange('pickup_time_arrive_timestamp', newDate.getTime());
            }
        }
        if(title === '비행기 출발' || title === '비행기 도착'){
            if(time.hour() === cutTime.hour){
                if(time.minute() < cutTime.min){
                    alert('비행기 출발시간이 출발시간보다 빠릅니다.');
                    return
                }
            }else{
                if(time.hour() < cutTime.hour){
                    alert('비행기 출발시간이 출발시간보다 빠릅니다.');
                    return
                }
            }
            if(title === '비행기 출발'){
                valueChange('pickup_time_depart_ap', res);
            }else if(title === '비행기 도착'){
                valueChange('pickup_time_arrive_ap', res);
            }
        }
        handleClose();
    }

    const handleTimeChange = (value: any) => {
        setTime(value);
    };
    const formatTime = (time: any) => {
        if (!time) return '';
        const hour = time.hour();
        const minute = time.minute();
        const ampm = hour < 12 ? '오전' : '오후';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        // return `${ampm} ${formattedHour}:${minute < 10 ? '0' + minute : minute}`;
        return `${hour}:${minute < 10 ? '0' + minute : minute}`;
    };

    // 비활성화할 시간 설정
    const disabledHours = () => {
        const hours = [];
        if(cutTime.hour !== null){
            for (let i = 0; i < cutTime.hour; i++) {
                hours.push(i);
            }
        }
        return hours;
    };

    const disabledMinutes:any = (hour:any) => {
        const min:any = [];
        if (hour === cutTime.hour) {
            for (let i = 0; i < cutTime.min; i++) { // 17시 이전의 모든 시간 비활성화
                min.push(i);
            }
            // return Array.from({ length: 60 }, (_, i) => i < cutTime.min ? i : null).filter(Boolean);
        }
        return min;
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor:'#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={28} mFontSize={22} align='center' color='#1c1c1c'>시간을 선택해주세요.</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                     <TimePicker
                        showSecond={false} // 초 단위 숨기기
                        value={time}
                        onChange={handleTimeChange}
                        format="A hh:mm"
                        use12Hours
                        placeholder="시간 선택"
                        open={true}
                        minuteStep={5}
                        inputReadOnly
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                    />
                </ContentWrap>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '50px 24px 20px' }}>
                <PrevBtnItem onClick={handleClose}>취소</PrevBtnItem>
                <NextBtnItem onClick={nextAction}>다음</NextBtnItem>
            </DialogActions>
        </Dialog>
    )
}

const ContentWrap = styled(RowBetween)`
    max-width: 290px;
    margin: 15px auto 0;
    align-items: start;
    height: 180px;
    .rc-time-picker{
        width: 100%;
        opacity: 0;
    }
    .rc-time-picker-input{
        border: none;
    }
`;

const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;
const PrevBtnItem = styled(Btn)`
    width: 38%;
    background-color: #F5F5F5;
    color: #2A2A2A;
`;
const NextBtnItem = styled(Btn)`
    width: 60%;
    background-color: #181818;
    color: #fff;
`;