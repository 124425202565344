import { axiosConfigInstance } from "./config"

// 관리자 리뷰 리스트
export const apiGetAdminReviewList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/review',token).post('',{
            method: 'getAdminReviewList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 리뷰 디테일
export const apiGetAdminReviewDetail = async(token:string,admin_id:any,review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/review',token).post('',{
            method: 'getAdminReviewDetailInfo',
            params:{
                admin_id,review_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 리뷰 삭제
export const apiSetAdminReviewDel = async(token:string,admin_id:any,review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/review',token).post('',{
            method: 'adminReviewDelete',
            params:{
                admin_id,review_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 리뷰 등록
export const apiSetAdminReviewCreate = async(token:string,admin_id:any,order_type:any,review_title:any,review_rate:any,review_content:any,review_user_name:any,review_image:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/review',token).post('',{
            method: 'adminReviewAdd',
            params:{
                admin_id,order_type,review_title,review_rate,review_content,review_user_name,review_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 리뷰 수정
export const apiSetAdminReviewUpdate = async(token:string,admin_id:any,order_type:any,review_title:any,review_rate:any,review_content:any,review_user_name:any,review_image:any,review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/review',token).post('',{
            method: 'adminReviewEdit',
            params:{
                admin_id,order_type,review_title,review_rate,review_content,review_user_name,review_image,review_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}