import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { themeColor } from './common/theme';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import session from "redux-persist/lib/storage/session";
import {user} from './common/user';
import { globalCompanyInfo } from './common/globalCompanyInfo';
import { adminUser } from './common/adminUser';
import { globalAirport } from './common/globalAirport';
import { globalParking } from './common/globalParking';
import { globalAirplane } from './common/globalAirplane';

const persistConfig = {
    key: "airdrive",
    // localStorage에 저장합니다.
    storage: session,
    whitelist: ['user', 'adminUser', 'globalAirport', 'globalParking' , 'globalAirplane'],
    blacklist: []
};

const rootReducer = combineReducers({
    themeColor,
    user,
    globalCompanyInfo,
    adminUser,
    globalAirport,
    globalParking,
    globalAirplane,
});

export type RootState = ReturnType<typeof rootReducer>;

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default function reducer() {
    return persistReducer(persistConfig, rootReducer);
}
