import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import ServicePart02Item from '../item/ServicePart02Item';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

export default function ServicePart02({mt,title,subTitle,content}:any) {
    const mediaQuery = useMediaQuery(addTheme.media.m);
    return (
        <Wrap mt={mt}>
            <MaxWidthWrap padding={mediaQuery ? '0' : addTheme.padding}>
                <InnerWrap>
                    <TitleWrap>
                        <BasicText fontSize={50} mFontSize={26} fontWeight={700} align='center'>{title}</BasicText>
                        {!(subTitle === undefined || subTitle.length === 0) &&
                            subTitle.map((item:any,index:any)=>{
                                return(
                                    <BasicText key={`sub-${index}`} fontSize={24} mFontSize={18} align='center' marginTop={index === 0 ? 1 : 0}>{item}</BasicText>
                                )
                            })
                        }
                    </TitleWrap>
                    <ContentWrap mediaQuery={mediaQuery}>
                        {!(content === undefined || content.length === 0) &&
                            mediaQuery ?
                                <Swiper
                                    slidesPerView={1.4}
                                    spaceBetween={20}
                                >
                                    {content.map((item:any,index:any)=>{
                                        return(
                                            <SwiperSlide key={`content-${index}`}>
                                                <ServicePart02Item item={item} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            : content.map((item:any,index:any)=>{
                                return(
                                    <ServicePart02Item key={`content-${index}`} item={item} />
                                )
                            })

                        }
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{mt?:string}>`
    margin-top: ${({mt}) => mt ?? '0'};
`;
const InnerWrap = styled.div``;
const TitleWrap = styled.div`
    @media ${addTheme.media.m}{
        padding: 0 15px;
    }
`;
const ContentWrap = styled(Row)<{mediaQuery?:boolean}>`
    margin-top: 60px;
    gap: 25px;
    flex-wrap: wrap;
    padding: ${({mediaQuery}) => mediaQuery ? '0 0 0 5%' : '0 15px'};
    &>div{
        width: calc(33.33% - 16.66px);
    }
    @media ${addTheme.media.m}{
        &>div{
            width: auto;
        }
    }
`;