import React from 'react'
import { InputWrap, ItemWrap } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'

export default function AdminProductConfigItem({title,titleBold,placeholder,value,onChange,right}:any) {
    return (
        <ItemWrap pWidth={140} align='start' style={{ flexDirection: 'column' }}>
            <BasicText color='#232323' fontWeight={titleBold ? '700' : '400'} fontSize={titleBold ? 18 : 17}>{title}</BasicText>
            <InputWrap margin='10px 0 0'>
                <input style={{ textAlign: right ? 'right' : 'left' }} value={value} onChange={onChange} placeholder={placeholder}></input>
            </InputWrap>
        </ItemWrap>
    )
}
