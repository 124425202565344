import exp from "constants";
import { axiosConfigInstance } from "./config";

// 카카오 알림톡 관리 url 가져오기
export const apiAdmGetAlarmUrl = async (token: string, admin_id: any) => {
    try {
        const res = await axiosConfigInstance('/api/admin/atalk', token).post('', {
            method: 'getAlarmTalkManageUrl',
            params: {
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 알림톡 템플릿 리스트
export const apiAdmGetAlarmTemplateList = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/atalk', token).post('', {
            method: 'getAlarmTalkTemplate',
            params: {
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 알림톡 전송
export const apiAdmSetAlarmSend = async(token:string,admin_id:any,template_name:string,reciever_number:string,message:string,target_user_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/atalk', token).post('', {
            method: 'sendAlarmTalk',
            params: {
                admin_id,template_name,reciever_number,message,target_user_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 알림톡 리스트
export const apiAdmGetAlarmList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/atalk', token).post('',{
            method: 'getAlarmTalkList',
            params: {
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}