import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { apiGetFaqCate, apiGetFaqList } from '../../../api/api_faq';

const ContentItemWrap = ({data,faqOpen,setFaqOpen}:any) =>{

    const clickEvent = () => {
        if (faqOpen.id === data.faq_id) {
            setFaqOpen((obj: any) => ({ ...obj, open: !faqOpen.open }));
        } else {
            setFaqOpen(() => ({ id: data.faq_id, open: true }));
        }
    }

    return(
        <FaqItemWrap onClick={clickEvent}>
            <FaqTitleWrap>
                <BasicText fontSize={17} mFontSize={16}>Q. {data?.faq_question}</BasicText>
            </FaqTitleWrap>
            <FaqContentWrap $active={faqOpen.open && data.faq_id === faqOpen.id}>
                <BasicText wh='pre-wrap'>{data?.faq_answer}</BasicText>
            </FaqContentWrap>
        </FaqItemWrap>
    )
}

export default function FaqPart({type}:any) {
    const [list,setList] = useState<any>([]);
    const [selectType,setSetSelectType] = useState({faq_category_id: 0, faq_category_title: '', faq_category_order: 0});
    const [faqOpen,setFaqOpen] = useState({
        id: 0,
        open: false,
    })

    useEffect(()=>{
        getCate();
    },[]);

    useEffect(()=>{
        if(selectType.faq_category_id === 0) return;
        getList();
    },[selectType.faq_category_id])

    const getCate = async() =>{
        const res = await apiGetFaqCate(1,50);
        if(res.result){
            const chk = res.data.filter((item:any)=>item.faq_category_title.includes(type));
            if(chk.length === 0){
                setSetSelectType(res.data[0]);
            }else{
                setSetSelectType(chk[0]);
            }
        }
    }

    const getList = async() =>{
        const res = await apiGetFaqList(selectType.faq_category_id,1,60);
        if(res.result){
            setList(res.data);
        }
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BasicText align='center' fontSize={32} mFontSize={22} fontWeight={700}>자주묻는질문</BasicText>
                    <ContentWrap>
                        {list.length !== 0 &&
                            list.map((data:any,index:any)=>{
                                return(
                                    <ContentItemWrap key={`faq-${index}`} data={data} faqOpen={faqOpen} setFaqOpen={setFaqOpen} />
                                )
                            })
                        }
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 140px;
`;
const InnerWrap = styled.div``;
const ContentWrap = styled.div`
    margin-top: 70px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
`;

const FaqItemWrap = styled.div`
    cursor: pointer;
    :nth-last-of-type(1){
        &>div{
            border: none;
        }
    }
`;
const FaqItem = styled.div`
    padding: 15px 20px;
`;
const FaqTitleWrap = styled(FaqItem)`
    border-bottom: 1px solid #a9a9a9;
`;
const FaqContentWrap = styled(FaqItem)<{$active?:boolean}>`
    display: ${({$active}) => $active ? 'block' : 'none'};
    border-bottom: 1px solid #a9a9a9;
`;