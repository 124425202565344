import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function TermsServicePage() {
    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>서비스 이용약관</BasicText>
                </TitleWrap>
                <ContentWrap>
                    <BasicText fontSize={24} color={'#1e4479'}>에어드라이브 서비스 이용약관</BasicText>
{`
제1조 (목적)
본 약관은 주식회사 에어드라이브(이하 "회사")가 제공하는 공항 이동 및 주차 대행 예약 서비스(이하 "서비스")의 이용과 관련하여 회사와 이용자 간의 권리, 의무 및 책임 사항을 규정하는 것을 목적으로 합니다.

제2조 (용어의 정의)
1. "서비스"란 이용자가 회사가 운영하는 "에어드라이브" 홈페이지를 통해 공항 이동 및 주차 대행 예약을 할 수 있도록 제공하는 모든 관련 서비스를 의미합니다.
2. "이용자"란 본 약관에 따라 회사가 제공하는 서비스를 이용하는 자를 의미합니다.
3. "회원"이란 카카오 또는 네이버 간편 로그인을 통해 가입하고, 본 약관에 동의한 자를 의미합니다.
4. "예약"이란 이용자가 원하는 날짜 및 시간에 서비스 이용을 신청하는 행위를 의미합니다.

제3조 (약관의 효력 및 변경)
1. 본 약관은 "에어드라이브" 홈페이지를 통해 공지함으로써 효력이 발생합니다.
2. 회사는 관련 법령을 위배하지 않는 범위 내에서 본 약관을 개정할 수 있으며, 개정된 약관은 공지된 날로부터 효력이 발생합니다.
3. 이용자가 개정된 약관에 동의하지 않는 경우, 서비스 이용을 중단할 수 있으며, 계속 이용할 경우 변경된 약관에 동의한 것으로 간주합니다.

제4조 (서비스의 제공 및 변경)
1. 회사는 다음과 같은 서비스를 제공합니다.
   - 공항 이동 예약 서비스
   - 주차 대행 예약 서비스
   - 기타 관련 부가 서비스
2. 회사는 운영상 또는 기술상의 필요에 따라 서비스 내용을 변경할 수 있으며, 변경된 사항은 홈페이지를 통해 공지합니다.

제5조 (회원가입 및 관리)
1. 회원 가입은 카카오 또는 네이버 간편 로그인을 통해 가능합니다.
2. 이용자는 본인의 이름, 전화번호, 이메일 등의 정보를 제공해야 하며, 회사는 해당 정보를 보호합니다.
3. 회원 정보가 허위 또는 타인의 정보를 도용한 경우, 회사는 회원의 서비스 이용을 제한할 수 있습니다.

제6조 (예약 및 결제)
1. 이용자는 원하는 날짜를 선택하여 예약할 수 있습니다.
2. 예약이 완료되면 확인 메시지가 발송되며, 예약 내용은 홈페이지에서 확인 가능합니다.
3. 예약 변경 및 취소는 회사가 정한 정책에 따라 진행됩니다.

제7조 (서비스 이용 제한 및 중단)
1. 이용자가 본 약관을 위반하거나 서비스 운영에 방해되는 행위를 하는 경우, 회사는 서비스 이용을 제한하거나 중단할 수 있습니다.
2. 회사는 천재지변, 시스템 장애 등 불가항력적인 사유가 발생한 경우 서비스 제공을 중단할 수 있습니다.

제8조 (이용자의 권리와 의무)
1. 이용자는 본 약관 및 관계 법령을 준수해야 합니다.
2. 이용자는 예약한 서비스 이용 시 정해진 절차를 준수해야 하며, 허위 정보 입력으로 인한 불이익에 대한 책임을 집니다.

제9조 (회사의 책임과 면책)
1. 회사는 이용자에게 안정적인 서비스를 제공하기 위해 최선을 다합니다.
2. 회사는 이용자의 귀책 사유로 인해 발생한 손해에 대해 책임을 지지 않습니다.
3. 회사는 천재지변, 법령 변경, 시스템 장애 등 불가항력적인 사유로 인한 서비스 중단에 대해 책임을 지지 않습니다.

제10조 (개인정보 보호)
1. 회사는 이용자의 개인정보를 보호하며, 관련 법령을 준수합니다.
2. 개인정보의 수집, 이용, 제공, 보호 등에 대한 내용은 별도의 "개인정보처리방침"에 따릅니다.

제11조 (분쟁 해결)
1. 본 약관에 명시되지 않은 사항은 관계 법령 및 상관례에 따릅니다.
2. 서비스 이용과 관련하여 회사와 이용자 간의 분쟁이 발생할 경우, 상호 원만한 해결을 위해 협의하며, 협의가 어려울 경우 관할 법원에서 해결합니다.

부칙: 본 약관은 2025년 3월 12일부터 시행됩니다.

`}
                </ContentWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}

const Wrap = styled.div`
    margin-bottom: 50px;
`;
const TitleWrap = styled.div`
    margin-top: 150px;
`;
const ContentWrap = styled.div`
    white-space: pre-wrap;
    margin-top: 30px;
    line-height: 1.3;
`;