import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components';
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid, MenuItem } from '@mui/material';
import { apiGetAdminReviewDetail, apiSetAdminReviewUpdate } from '../../../../api/api_adm_review';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import SelectItem from '../../../common/item/SelectItem';
import { REVIEW_TYPE_LIST } from '../../../../data/airport';
import { addTheme } from '../../../../style/addTheme';
import { momentFormat } from '../../../../utils/format/time';

export default function AdminReviewDetailPage() {
    const { id } = useParams();
    const fileRef = useRef<any>(null);
    const adminUser = useAdminUser();
    const [newImg,setNewImg] = useState<any>(null)
    const [info, setInfo] = useState({
        "review_title": "",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "",
        "review_timestamp": "",
        "order_type": "1",
        "user_name": ""
    });

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        getInfo();
    }, [adminUser.admin_id])

    const getInfo = async () => {
        const res = await apiGetAdminReviewDetail(adminUser.admin_token, adminUser.admin_id, id);
        
        if (res.result) {
            setInfo(res.data);
        }
    }

    const valueChange = (name: string, val: any) => {
        setInfo((obj: any) => ({
            ...obj,
            [name]: val,
        }))
    }

    const fileTrigger = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    }

    const fileSelect = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                setNewImg(result);
            };

            reader.readAsDataURL(file);
        }
    }

    const update = async() =>{
        const res = await apiSetAdminReviewUpdate(adminUser.admin_token,adminUser.admin_id,info.order_type,info.review_title,info.review_rate,info.review_content,info.user_name,newImg ?? '',id);
        if(res.result){
            alert('변경 되었습니다.');
            getInfo();
        }else{
            alert(res.error);
        }
    }
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>후기 상세</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <ContentWrap gap={15}>
                                <ItemWrap pWidth={140}>
                                    <BasicText>이름</BasicText>
                                    <InputWrap>
                                        <input value={info?.user_name} onChange={(e: any) => valueChange('user_name', e.target.value)} placeholder='이름을 입력해주세요.'></input>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>상품</BasicText>
                                    <InputWrap padding='0'>
                                        <SelectItem value={info?.order_type} setValue={(val: any) => valueChange('order_type', val)} none='no'>
                                            {REVIEW_TYPE_LIST.map((data: any, index: any) => {
                                                return (
                                                    <MenuItem key={`type-${index}`} value={data.value}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </SelectItem>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>등록일</BasicText>
                                    <InputWrap>
                                        <BasicText>{momentFormat('YYYY-MM-DD HH:mm',info?.review_timestamp)}</BasicText>
                                        {/* <input value={info?.review_timestamp} onChange={(e: any) => valueChange('review_timestamp', e.target.value)} placeholder='이름을 입력해주세요.'></input> */}
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140} align='start'>
                                    <BasicText marginTop={1}>후기</BasicText>
                                    <div style={{ width: '100%' }}>
                                        <InputWrap>
                                            <textarea rows={6} value={info.review_content} onChange={(e: any) => valueChange('review_content', e.target.value)}></textarea>
                                        </InputWrap>
                                    </div>
                                </ItemWrap>
                                <ItemWrap pWidth={140} align='start'>
                                    <BasicText marginTop={1}>후기 이미지</BasicText>
                                    <ImgWrap onClick={fileTrigger}>
                                        <img src={newImg === null ? info.review_image : newImg}></img>
                                        <input ref={fileRef} type='file' accept='image/*' onChange={fileSelect} />
                                    </ImgWrap>
                                </ItemWrap>
                            </ContentWrap>
                        </Grid>
                    </Grid>
                    <BtnWrap>
                        <BtnItem bg={addTheme.infoChangeBtnBg} onClick={update}>후기 변경</BtnItem>
                    </BtnWrap>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 35px;
`;
const ImgWrap = styled.div`
    margin-top: 20px;
    min-height: 80px;
    width: 100%;
    cursor: pointer;
    & img{
        max-width: 100%;
    }
    & input{
        display: none;
    }
`;

const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 30px;
`;