import React from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../../style/addTheme';
import { BtnItem, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminAirportItem from '../item/AdminAirportItem';
import { AIRPORT_TYPE } from '../../../../data/airport';
import { momentFormat } from '../../../../utils/format/time';
import { numberWithComma } from '../../../../utils/format/number';

const AddrItem = ({ img, title }: any) => {
    return (
        <AddrItemWrap>
            <img src={img}></img>
            <BasicText color='#000' fontWeight={700} fontSize={18} mFontSize={17}>{title}</BasicText>
        </AddrItemWrap>
    )
}

const Airport = ({ title, time, airport }: any) => {
    const date = momentFormat('YYYY-MM-DD',time);
    const airTime = momentFormat('HH:mm', time);
    const resTime = `${date} ${airTime}`;
    return (
        <AirportItemWrap>
            <AdminAirportItem title={title} content={resTime}/>
            <AdminAirportItem title={'항공편'} content={airport}/>
        </AirportItemWrap>
    )
}

export default function AdminAirportDetailContentPart({info}:any) {
    const bagsRes = info?.order_pickup_bags.map((item:any)=>item.bags_count === 0 ? '':`${item.bags_name} ${item.bags_count}개`).filter((item:any)=>item).join(',');
    const serviceRes = info?.order_pickup_service.map((item:any)=>item.service_count === 0 ? '':`${item.service_name} ${item.service_count}개`).filter((item:any)=>item).join(',');

    return (
        <Wrap>
            <TitleWrap>
                <BtnItem bg='#000' round={30} padding='9px 16px' fontSize='15px'>{AIRPORT_TYPE[info?.order_pickup_type]}</BtnItem>
                <BasicText fontSize={23} mFontSize={20}>공항 견적 신청서</BasicText>
            </TitleWrap>
            <AddrWrap>
                <AddrItem img='/img/asset/start.svg' title={info?.order_pickup_depart_location} />
                <AddrItem img='/img/asset/end.svg' title={info?.order_pickup_arrive_location} />
            </AddrWrap>
            <AirportWrap>
                {info?.order_pickup_type === '1' &&
                    <>
                        <Airport title='출발' time={info?.order_pickup_time_depart_timestamp} airport={info?.order_pickup_time_depart_flight} />
                        <Airport title='복귀' time={info?.order_pickup_time_arrive_timestamp} airport={info?.order_pickup_time_arrive_flight} />
                    </>
                }
                {info?.order_pickup_type === '3' &&
                    <>
                        <Airport title='입국' time={info?.order_pickup_time_depart_timestamp} airport={info?.order_pickup_time_depart_flight} />
                    </>
                }
                {info?.order_pickup_type === '2' &&
                    <>
                        <Airport title='출발' time={info?.order_pickup_time_depart_timestamp} airport={info?.order_pickup_time_depart_flight} />
                    </>
                }
            </AirportWrap>
            <ContentWrap>
                <AdminAirportItem title='인원' content={info?.order_pickup_people === '' ? '' : `${info?.order_pickup_people}명`} placeholder={'0명'}/>
                <AdminAirportItem title='수화물' content={bagsRes} placeholder='0개'/>
                <AdminAirportItem title='편의서비스' content={serviceRes} placeholder='0개'/>
                <AdminAirportItem title='경유지' content={info?.order_pickup_stopover_location } placeholder='없음' />
                <AdminAirportItem title='차량선택' content={info?.order_pickup_car?.car_name === undefined ? '' : info?.order_pickup_car?.car_name} />
                <AdminAirportItem title='결제 방법' content={info?.order_pickup_payment} />
                <AdminAirportItem title='추가 메모' content={info?.order_pickup_message} color={info?.order_pickup_message === '' ? '#AAAAAA' : '#000'}/>
            </ContentWrap>
            <ResWrap>
                <div>
                    <BasicText fontSize={28} mFontSize={22} fontWeight={700}>예상 비용</BasicText>
                    <BasicText fontSize={16} mFontSize={15}>실제 상황에 따라 견적 비용이 상이할 수 있습니다.</BasicText>
                </div>
                <BasicText fontSize={28} mFontSize={22} fontWeight={700}>{numberWithComma(info?.order_pickup_price)}원</BasicText>
            </ResWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    @media ${addTheme.media.m}{
        padding: 15px;
    }
`;
const TitleWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 8px;
`;
const AddrWrap = styled(TitleWrap)`
    margin-top: 20px;
    gap: 16px;
`;
const AddrItemWrap = styled(Row)`
    gap: 8px;
`;

const AirportWrap = styled(TitleWrap)`
    margin-top: 20px;
    gap: 20px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 25px;
`;

const AirportItemWrap = styled(TitleWrap)``;

const ContentWrap = styled(TitleWrap)`
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e2e2e2;
    gap: 12px;
`;

const ResWrap = styled(RowBetween)`
    padding: 25px 0;
    flex-wrap: wrap;
    gap: 15px;
`;