import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useMediaQuery } from '@mui/material';
export default function ServicePart03() {
    const mediaQuery = useMediaQuery(addTheme.media.m);

    return (
        <Wrap>
            <MaxWidthWrap padding={mediaQuery ? '0' : addTheme.padding}>
                <ContentWrap mediaQuery={mediaQuery}>
                    {mediaQuery ?
                        <Swiper
                            slidesPerView={1.4}
                            spaceBetween={20}
                        >
                            <SwiperSlide>
                                <ImgWrap>
                                    <img alt='' src='/img/asset/pay1.png'></img>
                                </ImgWrap>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ImgWrap>
                                    <img alt='' src='/img/asset/pay2.png'></img>
                                </ImgWrap>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ImgWrap>
                                    <img alt='' src='/img/asset/pay3.png'></img>
                                </ImgWrap>
                            </SwiperSlide>
                        </Swiper> :
                        <>
                            <ImgWrap>
                                <img alt='' src='/img/asset/pay1.png'></img>
                            </ImgWrap>
                            <ImgWrap>
                                <img alt='' src='/img/asset/pay2.png'></img>
                            </ImgWrap>
                            <ImgWrap>
                                <img alt='' src='/img/asset/pay3.png'></img>
                            </ImgWrap>
                        </>
                    }
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 50px;
`;
const ContentWrap = styled(Row)<{mediaQuery?:boolean}>`
    align-items: start;
    gap: 25px;
    flex-wrap: wrap;
    padding: ${({mediaQuery}) => mediaQuery ? '0 0 0 5%' : '0 15px'};
    &>div{
        width: calc(33.33% - 16.66px);
    } 
    @media ${addTheme.media.m}{
        &>div{
            width: auto;
        }
    }
`;
const ImgWrap = styled(Center)`
    & img{
        width: 85%;
        @media ${addTheme.media.m}{
            width: 100%;
        }
    }
`;