import React from 'react'
import { Row } from '../../../../style/basic/commonStyle';
import styled from 'styled-components';
import BasicText from '../../../basic/BasicText';

export default function AdminAirportItem({title,content,color,placeholder}:any) {
    return (
        <AirportItem>
            <AirportItemTitle color={content === '' ? color ?? '#aaa' : '#000'}>{title}</AirportItemTitle>
            <BasicText fontSize={14} color={content === '' ? color ?? '#aaa' : '#000'}>{content === '' ? placeholder : content}</BasicText>
        </AirportItem>
    )
}

const AirportItem = styled(Row)`
    flex-wrap: wrap;
    gap: 10px;
`;
const AirportItemTitle = styled.div<{color?:string}>`
    width: 80px;
    font-weight: 600;
    font-size: 14px;
    color: ${({color}) => color ?? '#000'};
    flex-shrink: 0;
`;
