import { createGlobalStyle, css } from 'styled-components';
import StyledReset from 'styled-reset';
import { MyReset } from './my-reset';

const layout = css`
  :root {
    color-scheme: light only;
  }
  html, body, #root {
    height: 100%;
    overscroll-behavior-y: none;
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
`;

const reset = css`
  ${StyledReset};
  ${MyReset};
`;

export const notSelect = css`
  /* -webkit-user-select: none;
  -moz-user-select: none; */
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${layout};

  a {
    color: inherit;
    display: block;
  }
  
  body {
  // padding: 10px;
    ${notSelect}
    background-repeat: no-repeat;
    font-family: "pretendard" !important;
    color: #1C1C1C;
  }
  @media (prefers-color-scheme: dark) {
    body{
      color: #1C1C1C;
    }
  }
  input{
    :focus-visible{
      outline: none;
    }
  }
  textarea{
    font-family: "pretendard" !important;
    :focus-visible{
      outline: none;
    }
  }
  .rc-time-picker-panel{
    z-index: 9999;
    max-width: 290px;
    width: 100%;
  }
  .rc-time-picker-panel-inner{
    border: none;
    box-shadow: none;
    width: 100%;
  }
  .rc-time-picker-panel-input-wrap{
    border: none;
    display: none;
    }
    .rc-time-picker-panel-input{
    border: none;
    text-align: center;
  }
  .rc-time-picker-panel-combobox{
    display: flex;
  }
  .rc-time-picker-panel-select{
    width: 33.33%;
    border: none;
  }
  .rc-time-picker-panel-select:nth-of-type(1){
    order: 2;
  }
  .rc-time-picker-panel-select:nth-of-type(2){
    order: 3;
  }
  .rc-time-picker-panel-select:nth-of-type(3){
    order: 1;
  }
  .rc-time-picker-panel-select:nth-of-type(3) li:nth-of-type(1){
    position: relative;
  }
  .rc-time-picker-panel-select:nth-of-type(3) li:nth-of-type(2){
    position: relative;
  }
  .rc-time-picker-panel-select:nth-of-type(3) li:nth-of-type(1)::after{
    content: '오전';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  .rc-time-picker-panel-select:nth-of-type(3) li:nth-of-type(2)::after{
    content: '오후';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  .rc-time-picker-panel-select ul{
    padding: 10px;
  }
  .rc-time-picker-panel-select li{
    padding: 0;
    height: 52px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #ccc;
  }
  .rc-time-picker-panel-select li.rc-time-picker-panel-select-option-selected{
    background-color: #fff;
    color: #1c1c1c;
  }
  li.rc-time-picker-panel-select-option-disabled{
    opacity: 0.8;
  }
  strong{
    font-weight: 700;
  }
  @media (max-width: 500px){
    * {
      cursor: default !important;
    }
  }
`;

