import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { RowBetween } from '../../../../style/basic/commonStyle';
import { useLocation, useNavigate } from 'react-router';
import { ADM_MENU_LIST } from '../../../../data/menu';
import { FaAngleDown } from 'react-icons/fa';
import { addTheme } from '../../../../style/addTheme';

export default function MenuPart() {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuOnIdx,setMenuOnIdx] = useState(0);
    const [lineIdx,setLineIdx] = useState(0);
    const [open,setOpen] = useState({
        open: false,
        index: 0,
    });
    
    useEffect(()=>{
        const pathnameArr = location.pathname.split('/').filter(data=>data);
        
        if(pathnameArr[0] === 'admin'){
            if(pathnameArr.length === 1){
                setLineIdx(0);
                setMenuOnIdx(0);
                return
            }
            ADM_MENU_LIST.map((data:any,index:any)=>{
                if(data.subList.length === 0){
                    if(data.path === pathnameArr[1]){
                        setMenuOnIdx(index);
                        setLineIdx(index+1);
                    }
                }else{
                    data.subList.map((sub:any,idx:any)=>{
                        if(sub.path === pathnameArr[1]){
                            setMenuOnIdx(index);
                            setLineIdx((index+1) + (idx+1));
                            setOpen({
                                open: true,
                                index: (index+1) + (idx+1),
                            });
                        }
                    })
                }
            })
        }
    },[location.pathname,ADM_MENU_LIST]);

    useEffect(()=>{
        if(open.index === 0) return;
        if(open.open){
            setLineIdx(open.index);
        }else{
            setLineIdx(menuOnIdx+1);
        }
    },[open.open])
    
    const GoHistory = React.useCallback((data: any,index:any,active:any,type?:string) => {
        if(type==='out'){
            window.open(data.router);
            return;
        }
        if(data.router === '') return;
        if(data.path === ''){
            if(index !== active){
                navigate(data.router);
            }
            setOpen((obj:any) =>({...obj, open: !obj.open}));
            return;
        }
        navigate(data.router);
    },[]);

    return (
        <Wrap>
            {ADM_MENU_LIST.map((data:any,index:any)=>{
                return(
                    <React.Fragment key={`adm-menu-${index}`}>
                        <MenuWrap onClick={()=>GoHistory(data,index,menuOnIdx)} fontSize={18}>
                            {data.name}
                            {data.subList === undefined || data.subList.length === 0 ? '' : 
                                <IconWrap menuOnIdx={(menuOnIdx === index) && open.open}>
                                    <FaAngleDown color='#fff' />
                                </IconWrap> }
                        </MenuWrap>
                        {data.subList === undefined || data.subList.length === 0 ?
                            <></> :
                            (menuOnIdx) === index && open.open &&
                            <SubWrap>
                                {data.subList.map((item:any,idx:any)=>{
                                    return(
                                        <MenuWrap key={`adm-menu-${index}-${idx}`} fontSize={16} onClick={()=>GoHistory(item,index,menuOnIdx)}>{item.name}</MenuWrap>
                                    )
                                })}
                            </SubWrap>
                        }
                    </React.Fragment>
                )
            })}
            <BgWrap lineIdx={lineIdx} />
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const MenuWrap = styled(RowBetween)<{fontSize?:number}>`
    position: relative;
    z-index: 1;
    padding-left: 30px;
    padding-right: 10px;
    color: #fff;
    cursor: pointer;
    height: 48px;
    font-size: ${props=>props.fontSize === undefined ? 15 : props.fontSize}px;
`;

const BgWrap = styled.div<{lineIdx:number}>`
    position: absolute;
    top: ${({lineIdx}) => (lineIdx-1) * 48}px;
    left: 0;
    width: 4px;
    height: 48px;
    background-color: #fff;
    z-index: 2;
    display: ${({lineIdx}) => lineIdx === 0 ? 'none' : 'block'};
    transition: 0.2s;
`;

const SubWrap = styled.div`
    /* padding-left: 10px; */
    background-color: #344155;
    &>div{
        border-top: 1px solid ${addTheme.adminColor};
    }
    &>div:nth-of-type(1){
        margin-top: none;
    }
`;

const IconWrap = styled.div<{menuOnIdx:boolean}>`
    transition: 0.2s;
    transform: ${({menuOnIdx}) => menuOnIdx ? 'rotate(180deg)' : 'rotate(0deg)'} ;
`;