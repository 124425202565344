import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router';
import { AdminWrap, AdmBtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { onlyNumber } from '../../../../utils/format/string';
import { apiAdmGetBannerDetail, apiAdmSetBanner, apiAdmSetBannerUpdate } from '../../../../api/api_adm_popupAndBenner';

export default function AdminBannerUpdatePage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const [type,setType] = useState('등록');
    const pcRef = useRef<any>(null);
    const [info,setInfo] = useState({
        banner_id: '',
        banner_img_mo: '',
        banner_img_web: '',
        banner_link_url: '',
        banner_order: '',
        banner_part_type: '1',
        banner_reg_timestamp: '',
        banner_title: '',
        moFileName: '',
        pcFileName: '',
    });
    
    useEffect(()=>{
        if(id === undefined){
            setType('등록')
        }else{
            setType('수정')
        }
    },[id]);

    useEffect(()=>{
        if(id === undefined) return
        if(adminUser.admin_token === '') return;
        getDetail();
    },[adminUser.admin_token,id])

    // 디테일 정보 가져오기
    const getDetail = async() =>{
        const res = await apiAdmGetBannerDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data)
        }
    }

    // input 변경
    const valueChange = (name:string,val:any) =>{
        if(name === 'banner_part_type'){
            setInfo((obj:any)=>({...obj,banner_order: ''}))
        }
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    // 피시 선택
    const pcInputClick = () =>{
        if(pcRef.current){
            pcRef.current.click();
        }
    }

    // pc 파일 선택
    const pcFileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            setInfo((obj:any)=>({...obj, pcFileName: file.name,moFileName: file.name}))
            reader.onload = () => {
                const result = reader.result;
                setInfo((obj:any)=>({...obj,banner_img_web:result,banner_img_mo:result}))
            };

            reader.readAsDataURL(file);
        }
    }
    
    const btnAction = async() =>{
        if(info.banner_img_web === ''){
            alert('이미지를 선택해주세요.');
            return;
        }

        if(type === '등록'){
            let newInfo = info
            if(newInfo.banner_order === ''){
                newInfo.banner_order = '999';
            }
            const res = await apiAdmSetBanner(adminUser.admin_token,adminUser.admin_id,newInfo,info.banner_part_type);
            if(res.result){
                alert('배너를 등록했습니다.');
                location.reload();
            }else{
                alert(res.error);
            }
        }
        if(type === '수정'){
            if(window.confirm('배너를 수정하시겠습니까?')){
                const res = await apiAdmSetBannerUpdate(adminUser.admin_token,adminUser.admin_id,info,info.banner_part_type)
                if(res.result){
                    alert('배너를 수정했습니다.');
                }else{
                    alert(res.error);
                }
            }
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>배너 {type === '등록' ? type : '관리'}</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <ItemWrap pWidth={120} align='start'>
                            <BasicText marginTop={1}>이미지</BasicText>
                            <div style={{width:'100%'}}>
                                <FileInputWrap>
                                    <InputWrap>
                                        <BasicText>{(info?.pcFileName === '' || info?.pcFileName === undefined) ? '' : info.pcFileName}</BasicText>
                                        <input ref={pcRef} type='file' accept='image/*' onChange={pcFileChange}/>
                                    </InputWrap>
                                    <ImgShowBtn onClick={pcInputClick}>
                                        이미지 보기
                                    </ImgShowBtn>
                                </FileInputWrap>
                                {info?.banner_img_web === '' ?
                                    <></> :
                                    <PreImgWrap>
                                        <img src={info?.banner_img_web.includes('https://') ? `${info?.banner_img_web}?time=${new Date().getTime()}` : info?.banner_img_web}></img>
                                    </PreImgWrap>
                                    
                                }
                            </div>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>링크 이동</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('banner_link_url',e.target.value)} value={info.banner_link_url} placeholder='배너 클릭시, 이동할 링크를 입력해주세요.'/>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>노출 순서</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('banner_order',onlyNumber(e))} value={info.banner_order} placeholder='노출 순서를 입력해주세요. 미입력시 자동으로 뒷순서로 배치됩니다.'/>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                </InnerWrap>
                <Row style={{marginTop:'80px',gap:'15px',justifyContent:'end'}}>
                    {type === '등록' ?
                        <AdmBtnItem bg={addTheme.detailBtnBg} padding='10px 25px' onClick={btnAction}>배너 등록</AdmBtnItem> :
                        <>
                            <AdmBtnItem bg={addTheme.detailBtnBg} padding='10px 25px' onClick={btnAction}>배너 변경</AdmBtnItem>
                        </>
                    }
                </Row>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;

const FileInputWrap = styled(Row)`
    align-items: stretch;
    gap: 10px;
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
    & input{
        display: none;
    }
`;
const ImgShowBtn = styled(Center)`
    flex-shrink: 0;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-height: 36px;
    padding: 0 18px;
    cursor: pointer;
`;

const PreImgWrap = styled.div`
    width: 100%;
    max-width: 280px;
    margin-top: 10px;
    &>img{
        width: 100%;
    }
`;
