import React from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import RectItem from '../../common/item/RectItem';
import { momentFormat } from '../../../utils/format/time';
import { addTheme } from '../../../style/addTheme';
import { textToStar } from '../../../utils/format/string';

export default function HomeReviewItem({className,data,clickAction}:any) {
    
    return (
        <Wrap className={className} onClick={clickAction}>
            <ImgWrap>
                <RectItem>
                    <ImgItem img={data?.review_image}/>
                </RectItem>
            </ImgWrap>
            <TextWrap>
                <NameWrap>
                    <BasicText>{textToStar(data?.user_name)} 고객님</BasicText>
                    <BasicText>{momentFormat('YYYY.MM.DD',data?.review_timestamp)}</BasicText>
                </NameWrap>
                <DescWrap>{data?.review_content}</DescWrap>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    padding: 20px 15px;
    gap: 20px;
    align-items: start;
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ImgWrap = styled.div`
    width: 30%;
    flex-shrink: 0;
    @media ${addTheme.media.sx}{
        width: 65%;
        margin: 0 auto;
    }
`;
const ImgItem = styled.div<{img?:string}>`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: url(${({img}) => img ?? ''});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
const TextWrap = styled.div`
    width: 100%;
    @media ${addTheme.media.sx}{
        width: 100%;
    }
`;
const NameWrap = styled(RowBetween)``;
const DescWrap = styled.div`
    white-space: pre-wrap;
    margin-top: 5px;
    line-height: 1.3;
`;