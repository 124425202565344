import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import HomeBannerPart from './HomeBannerPart';
import HomeTypePart from './HomeTypePart';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';

export default function HomePart01() {
    const mediaQuery = useMediaQuery(addTheme.media.m);

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BannerWrap>
                        <HomeBannerPart />
                    </BannerWrap>
                    <BtnWrap>
                        <BasicText fontSize={24} mFontSize={20} fontWeight={700} align={mediaQuery ? 'center' : 'left'}>빠른 견적받기</BasicText>
                        <HomeTypePart />
                    </BtnWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(RowBetween)`
    align-items: start;
    gap: 30px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        flex-direction: column;
        gap: 50px;
        &>div{
            width: 100%;
        }
    }
`;
const BannerWrap = styled.div`
    width: calc(65% - 15px);
`;
const BtnWrap = styled.div`
    width: calc(35% - 15px);
`;