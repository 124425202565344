import { axiosConfigInstance } from "./config"

// 관리자 공항 픽업 리스트
// 현재 상태 (0: 전체 / 1: 접수 / 2: 예약 확정 / 3: 완료 / 10: 취소 )
export const apiGetAdminAirportList = async(token:string,admin_id:any,page:any,count:any,order_pickup_status:any,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order/pickup',token).post('',{
            method: 'getAdminOrderPickUpList',
            params:{
                admin_id,page,count,order_pickup_status,search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 공항 픽업 카운트
// order_type (1: 공항 픽업 / 2: 주차 대행)
export const apiGetAdminAirportCount = async(token:string,admin_id:any,order_type:'1'|'2') =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatReserveCount',
            params:{
                admin_id,
                order_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 공항 픽업 디테일
export const apiGetAdminAirportDetail = async(token:string,admin_id:any,order_pickup_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order/pickup',token).post('',{
            method: 'getAdminOrderPickUpDetailInfo',
            params:{
                admin_id,order_pickup_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 공항 픽업 디테일 수정
export const apiSetAdminAirportDetailUpdate = async(token:string,admin_id:any,order_pickup_id:any,order_pickup_status:any,order_pickup_memo:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order/pickup',token).post('',{
            method: 'adminOrderPickUpEdit',
            params: {
                admin_id,order_pickup_id,order_pickup_status,order_pickup_memo
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 주차대행 리스트
// order_parking_status (0: 전체 / 1: 접수 / 2: 예약 확정 / 3: 완료 / 10: 취소)
export const apiGetAdminParkingList = async(token:string,admin_id:any,page:any,count:any,order_parking_status:any,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order/parking',token).post('',{
            method: 'getAdminOrderParkingList',
            params:{
                admin_id,page,count,order_parking_status,search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 주차대행 디테일
export const apiGetAdminParkingDetail = async(token:string,admin_id:any,order_parking_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order/parking',token).post('',{
            method: 'getAdminOrderParkingDetailInfo',
            params:{
                admin_id,order_parking_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}