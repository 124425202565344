import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

export default function ModalParkingTypePage({info, list, open, setOpen, valueChange }: any) {
    const [select,setSelect] = useState<any>({});

    useEffect(()=>{
        if(info?.parking_type?.parking_id === undefined) return;
        setSelect(info?.parking_type);
    },[info?.parking_type])

    const handleClose = () => {
        setOpen(false);
        setSelect({});
    }
    const nextAction = () =>{
        valueChange('parking_type',select);
        handleClose();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor:'#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={28} mFontSize={22} align='center' color='#1c1c1c'>픽업 방법을 선택해주세요.</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        list.map((data: any, index: any) => {
                            const parts = data.parking_name.split('(').map((part:any) => part.replace(')', '').trim());
                            
                            return (
                                <ContentItemWrap $active={data.parking_id === select?.parking_id} key={`air-${index}`} onClick={()=>setSelect(data)}>
                                    {parts.length > 0 && parts.length === 1 ?
                                        <BasicText fontSize={20} mFontSize={18} fontWeight={700}>{data.parking_name}</BasicText> :
                                        <>
                                            <BasicText fontSize={20} mFontSize={18} fontWeight={700}>{parts[0]}</BasicText>
                                            <BasicText fontSize={15} fontWeight={600}>{parts[1]}</BasicText>
                                        </>
                                    }
                                </ContentItemWrap>
                            )
                        })
                    }
                </ContentWrap>
            </DialogContent>
            <DialogActions sx={{justifyContent:'space-between',padding: '50px 24px 20px'}}>
                <PrevBtnItem onClick={handleClose}>취소</PrevBtnItem>
                <NextBtnItem onClick={nextAction}>다음</NextBtnItem>
            </DialogActions>
        </Dialog>
    )
}

const ContentWrap = styled(Row)`
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    align-items: start;
    &>div{
        width: calc(50% - 5px);
    }
`;
const ContentItemWrap = styled.div<{$active:boolean}>`
    background-color: ${({$active}) => $active ? '#000' : '#F8F8F8'};
    border-radius: 10px;
    padding: 20px;
    transition: 0.28s ease-in-out;
    cursor: pointer;
    &>p{
        color: ${({$active}) => $active ? '#fff' : '#1c1c1c'};
    }
    @media ${addTheme.media.s}{
        padding: 15px;
    }
`;
const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;
const PrevBtnItem = styled(Btn)`
    width: 38%;
    background-color: #F5F5F5;
    color: #2A2A2A;
`;
const NextBtnItem = styled(Btn)`
    width: 60%;
    background-color: #181818;
    color: #fff;
`;