import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MyReservationNoListPart from '../part/MyReservationNoListPart';
import MyReservationItem from '../item/MyReservationItem';
import { BtnItem, Center, Row } from '../../../style/basic/commonStyle';
import { apiGetMyOrderList, apiGetMyOrderParkingList } from '../../../api/api_mypage';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { Pagination } from '@mui/material';
import MyReservationParkingItem from '../item/MyReservationParkingItem';

export default function MyReservationPage() {
    const userInfo = useUserInfo();
    const [type,setType] = useState(0);
    const [list,setList] = useState<any>([]);
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);

    const [list2,setList2] = useState<any>([]);
    const [page2,setPage2] = useState(1);
    const [total2,setTotal2] = useState(0);

    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        getList1();
    },[userInfo.user_id,page]);

    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        getList2();
    },[userInfo.user_id,page2]);

    const getList1 = async() =>{
        const res = await apiGetMyOrderList(userInfo.token,userInfo.user_id,page,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const getList2 = async() =>{
        const res = await apiGetMyOrderParkingList(userInfo.token,userInfo.user_id,page2,10);
        if(res.result){
            setList2(res.data);
            setTotal2(res.total);
        }
    }

    // 페이지 이동
    const pageChange = (p:any) =>{
        if(type === 0){
            setPage(p);
        }else{
            setPage2(p);
        }
    }

    return (
        <Wrap>
            {list.length === 0 && list2.length === 0 ?
                <MyReservationNoListPart type={type}/> :
                <>
                    <TitleWrap>
                        <TitleItem $active={type === 0} onClick={()=>setType(0)}>공항 이동</TitleItem>
                        <TitleItem $active={type === 1} onClick={()=>setType(1)}>주차 대행</TitleItem>
                    </TitleWrap>
                    <ContentWrap>
                        {type === 0 ?
                            list.length === 0 ?
                                <MyReservationNoListPart /> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <MyReservationItem key={`reservation-${index}`} type='1' data={data} />
                                    )
                                }) :
                            list2.length === 0 ?
                                <MyReservationNoListPart /> :
                                list2.map((data:any,index:any)=>{
                                    return(
                                        <MyReservationParkingItem  key={`reservation-parking-${index}`} type='2' data={data} />
                                    )
                                }) 
                        }
                    </ContentWrap>
                    <PaginWrap>
                        <Pagination shape="rounded" page={type === 0 ? page : page2} count={type === 0 ? total : total2} onChange={(e:any, page:any) => pageChange(page)} sx={{'.MuiPagination-ul li button':{color:'#7d7d7d'},'.MuiPagination-ul li .Mui-selected':{color:'#111'}}} color='secondary' />
                    </PaginWrap>
                </>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Row)`
    gap: 18px;
`;
const TitleItem = styled.div<{$active:boolean}>`
    border-bottom: ${({$active}) => $active ? '1px solid #000' : '1px solid transparent'};
    padding-bottom: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
`;
const ContentWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    margin-top: 16px;
`;
const PaginWrap = styled(Center)`
    margin-top: 30px;
`;