import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function ServicePart01({text1,text2,text3,span1,img}:any) {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BasicText fontSize={23} mFontSize={17} align='center'>{text1}</BasicText>
                    <BasicText fontSize={54} mFontSize={30} align='center' fontWeight={700}>{text2}</BasicText>
                    <BasicText fontSize={54} mFontSize={30} align='center' fontWeight={700} spColor={addTheme.detailBtnBg}>{text3} <span>{span1}</span></BasicText>
                    <ImgWrap>
                        <img alt='img' src={img}></img>
                    </ImgWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 80px;
`;

const ImgWrap = styled.div`
    margin-top: 20px;
    text-align: center;
    border-radius: 30px;
    overflow: hidden;
    & img{
        width: 100%;
    }
`;