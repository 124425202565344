import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';

export default function HomeApplicationItem({data}:any) {
    const mediaQuery = useMediaQuery(addTheme.media.m);

    return (
        <Wrap>
            <BasicText fontSize={28} mFontSize={22} fontWeight={700} align={mediaQuery ? 'center' : 'left'}>{data?.title}</BasicText>
            <TextWrap>
                {data?.desc.map((txt:any,idx:any)=>{
                    return(
                        <BasicText key={`deco-${idx}`} fontSize={15} align={mediaQuery ? 'center' : 'left'}>{txt}</BasicText>
                    )
                })}
            </TextWrap>
            <ImgWrap>
                <ImgItem>
                    <img src={data?.img}></img>
                </ImgItem>
            </ImgWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 32%;
    padding: 30px 20px 15px;
    background-color: #fff;
    border-radius: 8px;
    @media ${addTheme.media.m}{
        padding: 35px 10px;
    }
`;
const TextWrap = styled.div`
    margin-top: 8px;
`;
const ImgWrap = styled(Row)`
    justify-content: end;
    margin-top: 24px;
    @media ${addTheme.media.m}{
        margin-top: 40px;
    }
`;
const ImgItem = styled.div`
    width: 55%;
    &>img{
        width: 100%;
        max-height: 187px;
        max-width: 203px;
    }
    @media ${addTheme.media.m}{
        width: 100%;
        text-align: center;
        &>img{
            width: 70%;
        }
    }
`;