import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from '../utils/custom-hooks/common/ScrollToTop';
import { apiGetAirplaneList, apiGetCompanyInfo } from '../api/api_main';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalCompanyInfo } from '../store/common/globalCompanyInfo';
import AdminBannerUpdatePage from '../components/admin/banner/page/AdminBannerUpdatePage';
import LayoutPage from '../components/layout/page/LayoutPage';
import HomePage from '../components/home/page/HomePage';
import LoginPage from '../components/login/page/LoginPage';
import JoinPage from '../components/login/page/JoinPage';
import JoinDonePage from '../components/login/page/JoinDonePage';
import MyPage from '../components/mypage/page/MyPage';
import MyInfoPage from '../components/mypage/page/MyInfoPage';
import MyReservationPage from '../components/mypage/page/MyReservationPage';
import MyReviewPage from '../components/mypage/page/MyReviewPage';
import AdminMemberPage from '../components/admin/member/page/AdminMemberPage';
import AdminHomePage from '../components/admin/home/page/AdminHomePage';
import AdminLayoutPage from '../components/admin/layout/page/AdminLayoutPage';
import AdminLoginPage from '../components/admin/login/page/AdminLoginPage';
import AdminAirportPage from '../components/admin/reservation/page/AdminAirportPage';
import AdminParkingPage from '../components/admin/reservation/page/AdminParkingPage';
import AdminProductPage from '../components/admin/product/page/AdminProductPage';
import AdminReviewPage from '../components/admin/review/page/AdminReviewPage';
import AdminTalkPage from '../components/admin/talk/page/AdminAlarmTalkPage';
import AdminMemberDetailPage from '../components/admin/member/page/AdminMemberDetailPage';
import AdminAirportDetailPage from '../components/admin/reservation/page/AdminAirportDetailPage';
import AdminParkingDetailPage from '../components/admin/reservation/page/AdminParkingDetailPage';
import AirportPage from '../components/airport/page/AirportPage';
import ParkingPage from '../components/parking/page/ParkingPage';
import VerifySignPage from '../components/login/page/VerifySignPage';
import ReviewCreatePage from '../components/review/page/ReviewCreatePage';
import AdminReviewDetailPage from '../components/admin/review/page/AdminReviewDetailPage';
import AdminReviewCreatePage from '../components/admin/review/page/AdminReviewCreatePage';
import { setGlobalAirplane } from '../store/common/globalAirplane';
import AdminProductAirportPage from '../components/admin/product/page/AdminProductAirportPage';
import AdminProductParkingPage from '../components/admin/product/page/AdminProductParkingPage';
import AdminBannerPage from '../components/admin/banner/page/AdminBannerPage';
import AdminAlarmTalkPage from '../components/admin/talk/page/AdminAlarmTalkPage';
import AdminAlarmTalkUpdatePage from '../components/admin/talk/page/AdminAlarmTalkUpdatePage';
import TermsServicePage from '../components/terms/page/TermsServicePage';
import TermsPersonalPage from '../components/terms/page/TermsPersonalPage';
import ServiceParkingPage from '../components/service/page/ServiceParkingPage';
import ServiceAirportPage from '../components/service/page/ServiceAirportPage';
import AdminFaqPage from '../components/admin/faq/page/AdminFaqPage';
import AdminFaqUpdatePage from '../components/admin/faq/page/AdminFaqUpdatePage';
import AdminFaqCateUpdatePage from '../components/admin/faq/page/AdminFaqCateUpdatePage';

export default function Router(){
    const dispatch = useDispatch();

    useEffect(()=>{
        getCompanyInfo();
        getAirplane();
    },[]);

    const getCompanyInfo = async() =>{
        // const res = await apiGetCompanyInfo();
        // if(res.data){
        //     dispatch(setGlobalCompanyInfo({...res.data}));
        // }
    }

    const getAirplane = async() =>{
        const res = await apiGetAirplaneList();
        if(res.result){
            dispatch(setGlobalAirplane({list:res.data}));
        }
    }
    
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<LayoutPage />}>
                    <Route path='' element={<HomePage />} />
                    <Route path='login' element={<LoginPage />} />
                    <Route path='join' element={<JoinPage />} />
                    <Route path='joinDone' element={<JoinDonePage />} />
                    <Route path='verifySign' element={<VerifySignPage />} />
                    <Route path='mypage' element={<MyPage />}>
                        <Route path='info' element={<MyInfoPage />} />
                        <Route path='reservation' element={<MyReservationPage />} />
                        <Route path='review' element={<MyReviewPage />} />
                    </Route>
                    <Route path='reviewCreate/:type/:id' element={<ReviewCreatePage />} />
                    <Route path='airport' element={<AirportPage />} />
                    <Route path='parking' element={<ParkingPage />} />
                    <Route path='service' element={<TermsServicePage />} />
                    <Route path='personal' element={<TermsPersonalPage />} />
                    <Route path='serviceParking' element={<ServiceParkingPage />} />
                    <Route path='serviceAirport' element={<ServiceAirportPage />} />
                </Route>
                <Route path='/adminLogin' element={<AdminLoginPage />} />
                <Route path='/admin' element={<AdminLayoutPage />}>
                    <Route path='' element={<AdminHomePage />} />
                    <Route path='member/:page?' element={<AdminMemberPage />} />
                    <Route path='memberDetail/:id' element={<AdminMemberDetailPage />} />
                    <Route path='airport/:page?' element={<AdminAirportPage />} />
                    <Route path='airportDetail/:id' element={<AdminAirportDetailPage />} />
                    <Route path='parking/:page?' element={<AdminParkingPage />} />
                    <Route path='parkingDetail/:id' element={<AdminParkingDetailPage />} />
                    <Route path='product' element={<AdminProductPage />} />
                    <Route path='productAirport' element={<AdminProductAirportPage />} />
                    <Route path='productParking' element={<AdminProductParkingPage />} />
                    <Route path='review/:page?' element={<AdminReviewPage />} />
                    <Route path='reviewCreate' element={<AdminReviewCreatePage />} />
                    <Route path='reviewDetail/:id' element={<AdminReviewDetailPage />} />
                    <Route path='banner/:page' element={<AdminBannerPage />} />
                    <Route path='bannerUpdate/:id?' element={<AdminBannerUpdatePage />} />
                    <Route path='faq' element={<AdminFaqPage />} />
                    <Route path='faqUpdate/:id?' element={<AdminFaqUpdatePage />} />
                    <Route path='faqCateUpdate/:id?' element={<AdminFaqCateUpdatePage />} />
                    <Route path='alarmTalk/:page?' element={<AdminAlarmTalkPage />} />
                    <Route path='alarmTalkUpdate' element={<AdminAlarmTalkUpdatePage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}