import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

export default function ModalOutPage({open, setOpen, outAction }: any) {
    const handleClose = () =>{
        setOpen(false);
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor:'#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 0px' }}>
                <BasicText fontWeight={700} fontSize={24} mFontSize={20} color='#1c1c1c'>견적신청에서 나가시겠습니까?</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                    <BasicText fontSize={20} mFontSize={16} color='#1c1c1c'>지금 나가시면 입력하신 정보는 사라집니다.</BasicText>
                </ContentWrap>
            </DialogContent>
            <DialogActions sx={{justifyContent:'space-between',padding: '20px 24px 20px'}}>
                <PrevBtnItem onClick={handleClose}>계속 진행</PrevBtnItem>
                <NextBtnItem onClick={outAction}>나가기</NextBtnItem>
            </DialogActions>
        </Dialog>
    )
}

const ContentWrap = styled(Row)`
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    &>div{
        width: calc(50% - 5px);
    }
`;
const ContentItemWrap = styled.div<{$active:boolean}>`
    background-color: ${({$active}) => $active ? '#000' : '#F8F8F8'};
    border-radius: 10px;
    padding: 20px;
    transition: 0.28s ease-in-out;
    cursor: pointer;
    &>p{
        color: ${({$active}) => $active ? '#fff' : 'inherit'};
    }
    @media ${addTheme.media.s}{
        padding: 15px;
    }
`;
const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;
const PrevBtnItem = styled(Btn)`
    width: 38%;
    background-color: #F5F5F5;
    color: #2A2A2A;
`;
const NextBtnItem = styled(Btn)`
    width: 60%;
    background-color: #181818;
    color: #fff;
`;