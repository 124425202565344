import React from 'react'
import styled from 'styled-components'
import AdminTablePart from '../../common/part/AdminTablePart';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
import BasicText from '../../../basic/BasicText';
import { momentFormat } from '../../../../utils/format/time';
import { HOME_TABLE_REVIEW } from '../../data/table';
import NoListItem from '../../common/item/NoListItem';

const ListItem = ({data,itemClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={HOME_TABLE_REVIEW[0].align} onClick={itemClick}>
                <BasicText line={1} fontSize={14}>{data.review_content}</BasicText>
            </TableCell>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={HOME_TABLE_REVIEW[1].align} onClick={itemClick}>{data.user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={HOME_TABLE_REVIEW[2].align}>{momentFormat('YYYY-MM-DD hh:mm',data.review_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function AdminHomeReviewPart({list}:any) {
    const navigate = useNavigate();
    const itemClick = (id:any) =>{
        // navigate(`/admin/reviewUpdate/${id}`);
    }
    return (
        <Wrap>
            <AdminTablePart title={HOME_TABLE_REVIEW}>
                {list === undefined || list.length === 0 ?
                    <NoListItem length={HOME_TABLE_REVIEW.length} /> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`review-${index}`} data={data} itemClick={()=>itemClick(data.review_id)} />
                        )
                    })
                }
            </AdminTablePart>
        </Wrap>
    )
}

const Wrap = styled.div``;