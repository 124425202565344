import React, { useState } from 'react'
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import ModalOrderTypePage from '../../modal/page/ModalOrderTypePage';

export default function MyReviewNoListPart() {
    const [open,setOpen] = useState(false);
    return (
        <Wrap>
            <BasicText align='center' fontSize={20} mFontSize={18}>등록된 후기가 없습니다.</BasicText>
            <BasicText align='center' fontSize={20} mFontSize={18}>서비스를 이용해보고 후기를 남겨보세요!</BasicText>
            <BtnItem onClick={()=>setOpen(true)}>견적 확인하기</BtnItem>
            <ModalOrderTypePage open={open} setOpen={setOpen}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnItem = styled(Center)`
    max-width: 320px;
    width: 80%;
    margin: 25px auto 0;
    height: 48px;
    color: #fff;
    background-color: #181818;
    border-radius: 8px;
    cursor: pointer;
`;