import { axiosConfigInstance } from "./config"

// 공항 주문 등록
export const apiSetOrderAirport = async(token:string,user_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/order/pickup',token).post('',{
            method: 'submitOrderPickUp',
            params: {
                ...info,
                user_id: user_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 공항 기본 설정
export const apiGetPickupConfig = async() =>{
    try {
        const res = await axiosConfigInstance('/api/client/order/pickup').post('',{
            method: 'getOrderPickUpConfig',
            params: {
                '':''
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 공항 주차 대행 등록
export const apiSetOrderParking = async(token:string,user_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/order/parking',token).post('',{
            method: 'submitOrderParking',
            params: {
                ...info,
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}