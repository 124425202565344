import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import BasicText from '../../../basic/BasicText'
import { ADM_FAQ_CATE, ADM_FAQ_LIST } from '../../data/table'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme'

export function AdminFaqListItem({data,update,del}:any) {
    const updateClick = () =>{
        if(update === undefined) return;
        update(data.faq_id);
    }

    const delClick = () =>{
        if(del === undefined) return
        del(data.faq_id);
    }
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_LIST[0].align}>{data.faq_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_LIST[1].align}>{data.faq_category_title}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_LIST[2].align}>
                <BasicText line={1}>{data.faq_question}</BasicText>
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_LIST[3].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={updateClick}>수정</AdmBtnItem>
                    <AdmBtnItem bg={addTheme.delBtnBg} round={30} padding='6px 16px' onClick={delClick}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export function AdminFaqCateListItem({data,update,del,count}:any) {
    const updateClick = () =>{
        if(update === undefined) return;
        update(data.faq_category_id);
    }

    const delClick = () =>{
        if(del === undefined) return
        del(data.faq_category_id);
    }
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_CATE[0].align}>{data.faq_category_order}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_CATE[1].align}>{data.faq_category_title}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_CATE[2].align}>{count ?? 0}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_FAQ_CATE[3].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={updateClick}>수정</AdmBtnItem>
                    <AdmBtnItem bg={addTheme.delBtnBg} round={30} padding='6px 16px' onClick={delClick}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}