import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useGlobalAirplane } from '../../../utils/custom-hooks/common/useAirplane';
import { Airplane, setGlobalAirplane } from '../../../store/common/globalAirplane';
import { useDispatch } from 'react-redux';
import { apiGetAirplaneList } from '../../../api/api_main';

export default function ModalAirportPage({ name, open, setOpen, valueChange, type }: any) {
    const airplane = useGlobalAirplane();
    const [select, setSelect] = useState<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        if(airplane.list.length === 0){
            getAirplane();
        }
    }, [airplane.list]);

    const getAirplane = async () => {
        const res = await apiGetAirplaneList();
        if (res.result) {
            dispatch(setGlobalAirplane({ list: res.data }));
        }
    }

    const handleClose = () => {
        if (name === '입국' || name === '출국') {
            setOpen({
                name: '출발',
                open: false,
            })
        } else {
            setOpen(false);
        }
        setSelect(0);
    }
    const nextAction = () => {
        if (name === undefined) {
            valueChange('pickup_arrive_location', select);
        }
        if (name === '입국') {
            valueChange('parking_time_arrive_airplane', select);
        }
        if (name === '출국') {
            valueChange('parking_time_depart_airplane', select);
        }
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '460px', width: '100%', borderRadius: '8px', backgroundColor: '#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '40px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={28} mFontSize={22} align='center' color='#1c1c1c'>{name === undefined ? '공항을 선택해주세요.' : `${name} 터미널을 선택해주세요.`}</BasicText>
            </DialogTitle>
            <DialogContent>
                <ContentWrap>
                    {airplane.list.length !== 0 && airplane.list.map((data: Airplane, index: any) => {
                        if(type === 'parking' && data.airplane_name.includes('김포')) return;
                        return (
                            <ContentItemWrap $active={data.airplane_id === select?.airplane_id} key={`air-${index}`} onClick={() => setSelect(data)}>
                                <BasicText color='#1c1c1c' fontSize={20} mFontSize={18} fontWeight={700}>{data.airplane_name}</BasicText>
                                <BasicText color='#1c1c1c'>{data.airplane_sub_name}</BasicText>
                            </ContentItemWrap>
                        )
                    })}
                </ContentWrap>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '50px 24px 20px' }}>
                <PrevBtnItem onClick={handleClose}>취소</PrevBtnItem>
                <NextBtnItem onClick={nextAction}>다음</NextBtnItem>
            </DialogActions>
        </Dialog>
    )
}

const ContentWrap = styled(Row)`
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    &>div{
        width: calc(50% - 5px);
    }
`;
const ContentItemWrap = styled.div<{ $active: boolean }>`
    background-color: ${({ $active }) => $active ? '#000' : '#F8F8F8'};
    border-radius: 10px;
    padding: 20px;
    transition: 0.28s ease-in-out;
    cursor: pointer;
    &>p{
        color: ${({ $active }) => $active ? '#fff' : '#1c1c1c'};
    }
    @media ${addTheme.media.s}{
        padding: 15px;
    }
`;
const Btn = styled(Center)`
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    cursor: pointer;
`;
const PrevBtnItem = styled(Btn)`
    width: 38%;
    background-color: #F5F5F5;
    color: #2A2A2A;
`;
const NextBtnItem = styled(Btn)`
    width: 60%;
    background-color: #181818;
    color: #fff;
`;