import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BtnItem, ContentWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminProductConfigItem from '../item/AdminProductConfigItem';
import { apiGetAdminPickupConfig, apiSetAdminPickupConfigUpdate } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';
import { addTheme } from '../../../../style/addTheme';
import AdminProductAirportConfigBagsItem from '../item/AdminProductAirportConfigBagsItem';
import AdminProductAirportConfigServiceItem from '../item/AdminProductAirportConfigServiceItem';
import AdminProductAirportConfigCarItem from '../item/AdminProductAirportConfigCarItem';
import AdminProductAirportConfigPayItem from '../item/AdminProductAirportConfigPayItem';

export default function AdminProductAirportConfigPart() {
    const adminUser = useAdminUser();
    const [bagsItems, setBagsItems] = useState('');
    const [serviceItems, setServiceItems] = useState('');
    const [carItems, setCarItems] = useState('');
    const [payItems, setPayItems] = useState('');
    const [info, setInfo] = useState({
        product_pickup_config_basic_price: 0,
        product_pickup_config_stopover_price: 0,
        product_pickup_config_option_bags: [],
        product_pickup_config_option_car: [],
        product_pickup_config_option_payment: [],
        product_pickup_config_option_service: [],
    });

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        getInfo();
    }, [adminUser.admin_id])

    const getInfo = async () => {
        const res = await apiGetAdminPickupConfig(adminUser.admin_token, adminUser.admin_id);
        if (res.result) {
            setInfo(res.data);
            setBagsItems(res.data.product_pickup_config_option_bags.map((item: any) => item.bags_name).join(','));
            setServiceItems(res.data.product_pickup_config_option_service.map((item: any) => item.service_name).join(','));
            setCarItems(res.data.product_pickup_config_option_car.map((item: any) => item.car_name).join(','));
            setPayItems(res.data.product_pickup_config_option_payment.map((item: any) => item.payment_name).join(','))
        }
    }

    const valueChange = (name: string, val: any) => {
        setInfo((obj: any) => ({
            ...obj,
            [name]: val,
        }))
    }

    // 변경
    const update = async () => {
        const { product_pickup_config_basic_price: basic_price, 
            product_pickup_config_stopover_price: stopover_price,
            product_pickup_config_option_bags: option_bags,
        product_pickup_config_option_car: option_car,
        product_pickup_config_option_payment: option_payment,
        product_pickup_config_option_service: option_service,
        } = info;
        const newInfo = {basic_price,stopover_price,option_bags,option_car,option_payment,option_service};
        const res = await apiSetAdminPickupConfigUpdate(adminUser.admin_token, adminUser.admin_id, newInfo);
        if(res.result){
            alert('정보가 변경되었습니다');
        }else{
            alert(res.error);
        }
    }
    return (
        <Wrap>
            <ContentWrap gap={15}>
                <AdminProductConfigItem title='기본가' titleBold placeholder='0' value={numberWithComma(info?.product_pickup_config_basic_price)} onChange={(e: any) => valueChange('product_pickup_config_basic_price', onlyNumber(e))} right />
                <AdminProductConfigItem title='경유지' placeholder='경유지 포함 기본 추가금을 입력해주세요.' value={numberWithComma(info?.product_pickup_config_stopover_price)} onChange={(e: any) => valueChange('product_pickup_config_stopover_price', onlyNumber(e))} />
            </ContentWrap>
            <SettingWrap>
                <BasicText color='#232323' fontSize={18} fontWeight={700}>옵션 관리</BasicText>
                <ContentWrap gap={15} mt={15}>
                    <AdminProductAirportConfigBagsItem info={info} titles={bagsItems} setTitles={setBagsItems} valueChange={valueChange} />
                    <AdminProductAirportConfigServiceItem info={info} titles={serviceItems} setTitles={setServiceItems} valueChange={valueChange} />
                    <AdminProductAirportConfigCarItem info={info} titles={carItems} setTitles={setCarItems} valueChange={valueChange} />
                    <AdminProductAirportConfigPayItem info={info} titles={payItems} setTitles={setPayItems} valueChange={valueChange} />
                </ContentWrap>
            </SettingWrap>
            <BtnWrap>
                <BtnItem onClick={update} bg={addTheme.infoChangeBtnBg}>정보 변경</BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const SettingWrap = styled.div`
    margin-top: 60px;
`;

const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 60px;
`;