import { axiosConfigInstance } from "./config"

// 유저 방문
export const apiVisit = async(user_id:number) =>{
    try {
        const res = await await axiosConfigInstance('/api/user/log').post('',{
            method: 'userVisitLog',
            params: {
                user_id: user_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 회사 정보 가져오기
export const apiGetCompanyInfo = async() =>{
    try {
        const res = await axiosConfigInstance('/api/config/info').post('',{
            method: 'getCompanyConfigInfo',
            params: {

            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 배너 가져오기
export const apiGetBannerList = async(page:any,count:any,part_type:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/banner',).post('',{
            method: 'getBannerList',
            params: {
                page,count,part_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 공항 리스트
export const apiGetAirplaneList = async() =>{
    try {
        const res = await axiosConfigInstance('/api/client/config').post('',{
            method: 'getAirplaneList',
            params: {
                '':''
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주차 대행 설정 리스트
export const apiGetParkingConfig = async() =>{
    try {
        const res = await axiosConfigInstance('/api/client/order/parking').post('',{
            method: 'getOrderParkingConfig',
            params: {
                "": "",
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}