import React from 'react'
import AdminProductConfigItem from './AdminProductConfigItem'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';

export default function AdminProductAirportConfigPayItem({ info, titles, setTitles, valueChange }: any) {

    // 결제 방법 인풋 변경 시 -> 배열도 변경
    const payItemChange = (val: any) => {
        setTitles(val);
        const items = val.split(',');
        const payList = info?.product_pickup_config_option_payment;
        let newPay: any = info?.product_pickup_config_option_payment;
        if (items.length > payList.length) {
            newPay = [...payList, {
                "payment_id": payList.length + 1,
                "payment_name": '',
            }]
        } else if (items.length === payList.length) {
            newPay = payList.map((item: any, idx: any) => ({ ...item, payment_name: items[idx] }));
        } else {
            newPay = payList.filter((item: any) => items.includes(item.payment_name));
        }
        valueChange('product_pickup_config_option_payment', newPay);
    }

    // 결제 방법 제거
    const payItemDel = (index: any) => {
        const newPay = info?.product_pickup_config_option_payment.filter((item: any, idx: any) => idx !== index);
        setTitles(newPay.map((item: any) => item.payment_name).join(','))
        valueChange('product_pickup_config_option_payment', newPay);
    }

    return (
        <>
            <AdminProductConfigItem title='결제 방법' placeholder='옵션값을 입력하세요(쉼표 사용)' value={titles} onChange={(e: any) => payItemChange(e.target.value)} />
            <ArrWrap>
                {info?.product_pickup_config_option_payment.length === 0 ?
                    <></> :
                    info?.product_pickup_config_option_payment.map((data: any, index: any) => {
                        return (
                            <ArrItemWrap key={`bags-${index}`}>
                                <BasicText>{data?.payment_name}</BasicText>
                                <InputWrap>
                                    <img onClick={() => payItemDel(index)} src='/img/asset/del.svg'></img>
                                </InputWrap>
                            </ArrItemWrap>
                        )
                    })
                }
            </ArrWrap>
        </>
    )
}

const ArrWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-top: 6px;
    padding: 0 8px 12px;
`;
const ArrItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 6px;
`;
const InputWrap = styled(Row)`
    width: auto;
    gap: 6px;
    justify-content: end;
    & input{
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        padding: 5px;
        width: 80%;
    }
    & img{
        cursor: pointer;
    }
`;