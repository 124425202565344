interface TYPE_TABLE {
    name: string,
    size: number,
    align: 'center' | 'right' | 'left',
    colSpan?: number
}

// 홈 주문
export const HOME_TABLE_ORDER:TYPE_TABLE[]= [
    {name: '코드', size: 25, align:'center'},
    {name: '유형', size: 25, align:'center'},
    {name: '이름', size: 20, align:'center'},
    {name: '날짜', size: 30, align:'center'},
]

// 홈 리뷰
export const HOME_TABLE_REVIEW:TYPE_TABLE[] = [
    {name:'내용',size:40,align:'left'},
    {name:'작성자',size:20,align:'center'},
    {name:'날짜',size:20,align:'center'},
]

// 회원 관리
export const TABLE_MEMBER:TYPE_TABLE[] = [
    {name:'번호',size:8,align:'center'},
    {name:'소셜',size:8,align:'center'},
    {name:'이름',size:10,align:'center'},
    {name:'전화번호',size:14,align:'center'},
    {name:'이메일',size:14,align:'center'},
    {name:'가입일',size:16,align:'center'},
    {name:'최근 접속일',size:16,align:'center'},
    {name:'관리',size:14,align:'center'},
]

// 회원 관리
export const TABLE_AIRPORT:TYPE_TABLE[] = [
    {name:'번호',size:6,align:'center'},
    {name:'이름',size:8,align:'center'},
    {name:'유형',size:8,align:'center'},
    {name:'연락처',size:12,align:'center'},
    {name:'출발 위치',size:15,align:'center'},
    {name:'도착 위치',size:15,align:'center'},
    {name:'예약일',size:16,align:'center'},
    {name:'상태',size:8,align:'center'},
    {name:'관리',size:12,align:'center'},
]

// 배너 리스트
export const BANNER_LIST:TYPE_TABLE[] = [
    {name:'번호',size:10, align: 'center'},
    {name:'이미지',size:40, align: 'center'},
    {name:'등록일',size:25, align: 'center'},
    {name:'관리',size:25, align: 'center'},
]

// 리뷰 리스트
export const REVIEW_LIST:TYPE_TABLE[] = [
    {name: '번호', size: 12, align: 'center'},
    {name: '이름', size: 14, align: 'center'},
    {name: '상품', size: 14, align: 'center'},
    {name: '내용', size: 22, align: 'center'},
    {name: '등록일', size: 20, align: 'center'},
    {name: '관리', size: 18, align: 'center'},
]

// 상품 리스트
export const PRODUCT_LIST:TYPE_TABLE[] = [
    {name: '번호', size: 10, align:'center'},
    {name: '상품명', size: 14, align:'center'},
    {name: '판매가', size: 18, align:'center'},
    {name: '등록일', size: 16, align:'center'},
    {name: '수정일', size: 16, align:'center'},
    {name: '상태', size: 12, align:'center'},
    {name: '관리', size: 14, align:'center'},
]

// 상품 픽업 지역
export const PRODUCT_CITY_LIST:TYPE_TABLE[] = [
    {name: '출발 위치', size: 20, align: 'center', colSpan:1},
    {name: '인천 공항', size: 40, align: 'center', colSpan:2},
    {name: '김포 공항', size: 40, align: 'center', colSpan:2},
]

// 알림톡 리스트
export const ALARM_LIST:TYPE_TABLE[] = [
    {name: '번호', size: 20, align: 'center',},
    {name: '이름', size: 35, align: 'center',},
    {name: '유형', size: 35, align: 'center',},
    {name: '연락처', size: 40, align: 'center',},
    {name: '출발 위치', size: 45, align: 'center',},
    {name: '도착 위치', size: 45, align: 'center',},
    {name: '예약일', size: 40, align: 'center',},
    {name: '상태', size: 40, align: 'center',},
]

// faq 카테고리
export const ADM_FAQ_CATE: TYPE_TABLE[] = [
    {name:'순서',size:15, align: 'center'},
    {name:'제목',size:35, align: 'center'},
    {name:'개수',size:20, align: 'center'},
    {name:'관리',size:30, align: 'center'},
]

// faq 리스트
export const ADM_FAQ_LIST:TYPE_TABLE[] = [
    {name:'번호',size:10, align: 'center'},
    {name:'카테고리',size:25, align: 'left'},
    {name:'제목',size:40, align: 'left'},
    {name:'관리',size:25, align: 'center'},
]