import { axiosConfigInstance } from "./config"

// 리뷰 가져오기
export const apiGetReviewList = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/review',).post('',{
            method: 'getReviewList',
            params: {
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 등록
// order_type: 신청한 서비스 타입 (1: 픽업 서비스 / 2: 주차 대행 서비스)

export const apiSetReviewCreate = async(token:string,user_id:any,order_id:any,order_type:any,review_title:any,review_rate:any,review_content:any,review_image:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/review',token).post('',{
            method: 'submitReview',
            params: {
                user_id,order_id,order_type,review_title,review_rate,review_content,review_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}