import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, BtnItem, Row } from '../../../../style/basic/commonStyle';
import { useParams } from 'react-router';
import BasicText from '../../../basic/BasicText';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { Grid } from '@mui/material';
import AdminAirportDetailUserPart from '../part/AdminAirportDetailUserPart';
import { addTheme } from '../../../../style/addTheme';
import AdminParkingDetailContentPart from '../part/AdminParkingDetailContentPart';
import { apiGetAdminParkingDetail } from '../../../../api/api_adm_airportAndParking';

export default function AdminParkingDetailPage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const [info,setInfo] = useState();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getInfo();
    },[adminUser.admin_id])

    const getInfo = async() =>{
        const res = await apiGetAdminParkingDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data);
        }
    }
    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>예약 정보</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <AdminAirportDetailUserPart info={info} valueChange={valueChange} type='parking'/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AdminParkingDetailContentPart info={info} />
                        </Grid>
                    </Grid>
                </InnerWrap>
                <BtnWrap>
                    <BtnItem bg={addTheme.infoChangeBtnBg}>정보 변경</BtnItem>
                </BtnWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 35px;
`;
const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 45px;
`;
