import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import HomeReviewItem from '../item/HomeReviewItem';
import { apiGetReviewList } from '../../../api/api_review';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const LIST = [
    {
        "review_id": 47,
        "review_title": "",
        "review_rate": "5",
        "review_content": "리뷰에요1",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737515023183",
        "order_type": "1",
        "user_name": "이지수"
    },
    {
        "review_id": 45,
        "review_title": "",
        "review_rate": "5",
        "review_content": "",
        "review_image": "http://192.168.11.11:1002/review_image/MtgxWZnM9ccqHUKWyHbxZI0v4aLAUSRMubq.png",
        "review_timestamp": "1737448410563",
        "order_type": "1",
        "user_name": "이지수"
    },
    {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    },
    {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    },
    {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    }, {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    }, {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    }, {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    }, {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    }, {
        "review_id": 43,
        "review_title": "리뷰 제목",
        "review_rate": "5",
        "review_content": "리뷰 내용",
        "review_image": "http://192.168.11.11:1002/review_image/mHCmDklg1E8zSov2xypOJFyMAnZ271JKof4.png",
        "review_timestamp": "1737434488254",
        "order_type": "1",
        "user_name": "김준성"
    }
]

export default function HomeReviewPart() {
    const [list, setList] = useState<any>([]);

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        const res = await apiGetReviewList(1, 20);
        if (res.result) {
            setList([...res.data,...res.data,...res.data]);
        }
    }

    const clickAction = () => {

    }
    
    return (
        <Wrap>
            <MaxWidthWrap maxWidth={addTheme.contentMaxWidth}>
                <InnerWrap>
                    <BasicText fontSize={22} mFontSize={18} align='center'>실제 승객 이용 후기</BasicText>
                    <BasicText fontSize={36} mFontSize={26} align='center' fontWeight={700}>에어드라이브 어떠셨나요?</BasicText>
                    <ContentWrap>
                        <ListWrap>
                            {list.length === 0 ?
                                <></> :
                                <Swiper
                                    direction={'vertical'}
                                    modules={[Autoplay]}
                                    loop
                                    slidesPerView={'auto'}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    allowTouchMove={false}
                                >
                                    {list.map((data:any,index:any)=>{
                                        return(
                                            <SwiperSlide key={`review-${index}`}>
                                                <HomeReviewItem className='item' data={data} clickAction={clickAction}/>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            }
                            {/* {list.length === 0 ? 
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <HomeReviewItem key={`review-${index}`} data={data} clickAction={clickAction}/>
                                    )
                                })
                            } */}

                        </ListWrap>
                        <WhiteSpaceWrap />
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 120px;
`;

const InnerWrap = styled.div``;
const ContentWrap = styled.div`
    position: relative;
    margin-top: 40px;
`;

const ListWrap = styled.div`
    height: 680px;
    overflow-y: hidden;
    & .item{
        background-color: #fff;
        border-radius: 8px;
        margin: 10px 0;
    }
    & .swiper {
        height: 100%;
    }
    & .swiper-slide{
        height: auto;
    }
`;

const WhiteSpaceWrap = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(254,254,255,0) 100%);
    z-index: 10;
`;