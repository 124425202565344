import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useNavigate } from 'react-router';
import { PHONE } from '../../../api/config';

export default function HomePart02Item({data}:any) {
    const navigate = useNavigate();
    const clickEvent = () =>{
        if(data.type === 'airport'){
            navigate('/serviceAirport');
            return;
        }
        if(data.type === 'parking'){
            navigate('/serviceParking');
            return;
        }
        if(data.type === 'inquiry'){
            window.location.href = 'tel:' + PHONE;
            return;
        }
    }
    return (
        <Wrap onClick={clickEvent}>
            <IconWrap bg={data.bg}>
                <img alt='icon' src={data.img}></img>
            </IconWrap>
            <TextWrap>
                <BasicText fontSize={20} mFontSize={18} fontWeight={700}>{data.title}</BasicText>
                <BasicText fontSize={16} mFontSize={15} color='#919191'>{data.desc}</BasicText>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    box-shadow: 0px 3px 6px #1D457914;
    border-radius: 10px;
    padding: 12px;
    gap: 15px;
    background-color: #fff;
    cursor: pointer;
`;

const IconWrap = styled(Center)<{bg?:string}>`
    width: 70px;
    height: 70px;
    /* background-color: ${({bg}) => bg ?? '#fff'}; */
    /* border-radius: 10px; */
    /* &>img{
        width: 55%;
    } */
    & img{
        width: 90%;
    }
`;

const TextWrap = styled.div``;