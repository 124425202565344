import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { BANNER_LIST } from '../../data/table'
import { BtnItem, Center } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme'
import { momentFormat } from '../../../../utils/format/time'

export default function AdminBannerItem({data,goDetail,del}:any) {
    const time = new Date().getTime();
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={BANNER_LIST[0].align}>{data.banner_id}</TableCell>
            <TableCell sx={{padding:'10px','& img':{maxWidth:'100%',maxHeight:'140px'}}} align={'left'}>
                <img src={`${data.banner_img_web}?time=${time}`}></img>
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={BANNER_LIST[2].align}>{momentFormat('YYYY-MM-MM HH:mm',data?.banner_reg_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={BANNER_LIST[3].align}>
                <Center style={{gap:'12px'}}>
                    <BtnItem onClick={goDetail} bg={addTheme.detailBtnBg} round={35} padding='7px 16px'>상세</BtnItem>
                    <BtnItem onClick={del} bg={addTheme.delBtnBg} round={35} padding='7px 16px'>삭제</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
