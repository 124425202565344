import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import AirportInput1Part from '../part/AirportInput1Part';
import AirportViewPart from '../part/AirportViewPart';
import { addTheme } from '../../../style/addTheme';
import AirportInput2Part from '../part/AirportInput2Part';
import AirportResultPart from '../part/AirportResultPart';
import { FaAngleLeft } from "react-icons/fa6";
import BasicText from '../../basic/BasicText';
import ModalOutPage from '../../modal/page/ModalOutPage';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';
import { useGlobalAirport } from '../../../utils/custom-hooks/common/useAirport';
import { useDispatch } from 'react-redux';
import { globalAirportReset, setGlobalAirport } from '../../../store/common/globalAirport';
import { apiGetPickupConfig } from '../../../api/api_airport';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function AirportPage() {
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const airport = useGlobalAirport();
    const dispatch = useDispatch();
    const [outOpen, setOutOpen] = useState(false);
    const [step, setStep] = useState(1);
    const userInfo = useUserInfo();
 
    useEffect(() => {
        if (airport.pickup_state) {
            if(userInfo.user_id === 0) return
            setStep(2);
            dispatch(setGlobalAirport({
                ...airport,
                pickup_state: false,
            }))
        }
    }, [airport.pickup_state,userInfo.user_id]);

    useEffect(() => {
        if(airport?.pickup_bags?.length === 0 || airport?.pickup_service?.length === 0){
            getConfig();
        }
    }, [airport?.pickup_bags?.length, airport?.pickup_service?.length])

    const getConfig = async () => {
        const res = await apiGetPickupConfig();
        if (res.result) {
            const newBags = res.data.product_pickup_config_option_bags.map((data: any) => ({ ...data, bags_count: 0 }));
            const newService = res.data.product_pickup_config_option_service.map((data: any) => ({ ...data, service_count: 0 }));
            dispatch(setGlobalAirport({
                ...airport,
                pickup_bags: newBags,
                pickup_service: newService,
            }))
        }
    }

    // 뒤로가기 액션
    const preventGoBack = () => {
        history.pushState(null, "", location.href);
        setOutOpen(true);
    };

    // 뒤로가기 감지
    useEffect(() => {
        if (!mediaQuery) return
        (() => {
            history.pushState(null, "", location.href);
            window.addEventListener("popstate", preventGoBack);
        })();

        return () => {
            window.removeEventListener("popstate", preventGoBack);
        };
    }, [mediaQuery]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    useEffect(() => {
        if (mediaQuery) return
        if (step === 4) {
            setStep(3);
        }
    }, [step, mediaQuery]);

    const valueChange = (name: string, val: any) => {
        dispatch(setGlobalAirport({
            ...airport,
            [name]: val,
        }))
    }

    const outAction = () => {
        dispatch(globalAirportReset());
        navigate('/');
    }

    return (
        <Wrap>
            <HeaderWrap>
                <HeaderItem onClick={() => setOutOpen(true)}>
                    <FaAngleLeft size={20} color='#000' />
                </HeaderItem>
                <BasicText fontWeight={700} fontSize={20}>공항 견적 신청서</BasicText>
                <HeaderItem />
            </HeaderWrap>
            <LeftWrap>
                <MaxWidthWrap maxWidth='650px' margin='0'>
                    <AirportViewPart info={airport} />
                </MaxWidthWrap>
            </LeftWrap>
            <RightWrap>
                <RightBorderTop step={step} />
                <MaxWidthWrap maxWidth='720px' padding='0'>
                    {step === 1 &&
                        <AirportInput1Part info={airport} valueChange={valueChange} setStep={setStep} />
                    }
                    {step === 2 &&
                        <AirportInput2Part info={airport} valueChange={valueChange} setStep={setStep} />
                    }
                    {step === 3 &&
                        <AirportResultPart setStep={setStep} />
                    }
                    {step === 4 &&
                        <AirportViewPart info={airport} type='result' />
                    }
                </MaxWidthWrap>
            </RightWrap>
            <ModalOutPage open={outOpen} setOpen={setOutOpen} outAction={outAction} />
        </Wrap>
    )
}

const Wrap = styled(Row)`
    align-items: stretch;
    &>div{
        width: 50%;
        min-height: 600px;
    }
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
            min-height: 400px;
        }
    }
`;

const HeaderWrap = styled(RowBetween)`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    min-height: auto !important;
    z-index: 50;
    background-color: #fff;
    padding: 0 15px;
    @media ${addTheme.media.s}{
        display: flex;
    }
`;

const HeaderItem = styled(Center)`
    width: 50px;
    cursor: pointer;
`;

const LeftWrap = styled(Center)`
    background-color: #F5F5F5;
    justify-content: end;
    padding-right: 50px;
    padding-bottom: 40px;
    padding-top: 40px;
    align-items: start;
    position: relative;
    &>div{
        position: sticky;
        top: 80px;
    }
    @media ${addTheme.media.m}{
        padding-right: 0px;
        justify-content: center;
    }
    @media ${addTheme.media.s}{
        display: none;
    }
`;
const RightWrap = styled.div`
    background-color: #fff;
    border-top: 5px solid #F5F5F5;
    position: relative;
    padding: 100px 50px;
    @media ${addTheme.media.m}{
        padding: 50px 25px;
    }
    @media ${addTheme.media.s}{
        margin-top: 60px;
    }
`;

const RightBorderTop = styled.div<{ step: number }>`
    position: absolute;
    top: -5px;
    left: 0;
    width: ${({ step }) => step * 33.33}%;
    height: 5px;
    background-color: #181818;
    transition: 0.36s ease-in-out;
`;