import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminHomePart from '../part/AdminHomePart';
import PartTitleItem from '../item/PartTitleItem';
import { useNavigate } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import AdminHomeChartPart from '../part/AdminHomeChartPart';
import { apiGetAdminAirportList, apiGetAdminParkingList } from '../../../../api/api_adm_airportAndParking';
import AdminHomeOrderPart from '../part/AdminHomeOrderPart';
import { apiGetAdminReviewList } from '../../../../api/api_adm_review';
import AdminHomeReviewPart from '../part/AdminHomeReviewPart';

export default function AdminHomePage() {
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [orderList,setOrderList] = useState<any>([]);
    const [reviewList,setReviewList] = useState<any>([]);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        newOrderList();
        getReviewList();
    },[adminUser.admin_id]);

    const goTotal = (router:string) =>{
        navigate(router);
    }

    // 공항 픽업
    const getOrderList1 = async() =>{
        const res = await apiGetAdminAirportList(adminUser.admin_token,adminUser.admin_id,1,5,'0','');
        if(res.result){
            const newList = res.data.map((data:any)=>({...data,time:data.order_pickup_timestamp,type:'1'}));
            return newList;
        }else{
            return []
        }
    }

    // 주차 대행
    const getOrderList2 = async() =>{
        const res = await apiGetAdminParkingList(adminUser.admin_token,adminUser.admin_id,1,5,'0','');
        if(res.result){
            const newList = res.data.map((data:any)=>({...data,time:data.order_parking_timestamp,type:'2'}));
            return newList;
        }else{
            return []
        }
    }
    // 주문 합치기 -> sort
    const newOrderList = async() =>{
        const list1 = await getOrderList1();
        const list2 = await getOrderList2();
        const newArr = [...list1, ...list2];
        const sortedArr = newArr.sort((a, b) => b.time - a.time).slice(0,5);
        setOrderList(sortedArr);
    }

    const getReviewList = async() =>{
        const res = await apiGetAdminReviewList(adminUser.admin_token,adminUser.admin_id,1,5);
        
        if(res.result){
            setReviewList(res.data);
        }
    }

    return (
        <Wrap>
            <Grid container={true} spacing={1}>
                <Grid item md={6} xs={12}>
                    <AdminHomePart title={<PartTitleItem title='예약 현황' border={true} goTotal={()=>goTotal('/admin/airport/1')}/>}>
                        <AdminHomeOrderPart list={orderList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='고객 후기' border={true} goTotal={()=>goTotal('/admin/review/1')}/>} >
                        <AdminHomeReviewPart list={reviewList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={12}>
                    <AdminHomePart title={<PartTitleItem title='현황' type='chart'/> } >
                        <AdminHomeChartPart />
                    </AdminHomePart>
                </Grid>
            </Grid> 
        </Wrap>
    )
}

const Wrap = styled.div``;