import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

const LIST = ['01','02','03','04','05','06'];

export default function ServiceAirportCarPart() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    {LIST.map((data:any,index:any)=>{
                        return(
                            <ImgWrap key={`car-${index}`}>
                                <img alt='car' src={`/img/asset/car${data}.png`}></img>
                            </ImgWrap>
                        )
                    })}
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(Row)`
    align-items: start;
    flex-wrap: wrap;
    margin-top: 46px;
    gap: 30px;
    &>div{
        width: calc(33.33% - 20px);
    }
    @media ${addTheme.media.m}{
        gap: 15px;
        &>div{
            width: calc(50% - 15px);
        }
    }
`;
const ImgWrap = styled.div`
    & img{
        width: 100%;
    }
`;