import React from 'react'
import AdminProductConfigItem from './AdminProductConfigItem'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdminProductParkingConfigTypeItem({ info, titles, setTitles, valueChange }: any) {
    
    // 주차 옵션 인풋 변경 시 -> 배열도 변경
    const payItemChange = (val: any) => {
        setTitles(val);
        const items = val.split(',');
        const list = info?.option_parking;
        let newList: any = info?.option_parking;
        if (items.length > list.length) {
            newList = [...list, {
                "parking_id": list.length + 1,
                "parking_name": '',
            }]
        } else if (items.length === list.length) {
            newList = list.map((item: any, idx: any) => ({ ...item, parking_name: items[idx] }));
        } else {
            newList = list.filter((item: any) => items.includes(item.parking_name));
        }
        valueChange('option_parking', newList);
    }

    // 주차 옵션 제거
    const payItemDel = (index: any) => {
        const newList = info?.option_parking.filter((item: any, idx: any) => idx !== index);
        setTitles(newList.map((item: any) => item.parking_name).join(','))
        valueChange('option_parking', newList);
    }

    // 가격 변경
    const priceChange = (index: any, val: any) => {
        const newService = info?.option_parking.map((item: any, idx: any) => idx === index ? { ...item, parking_price: val } : item);
        valueChange('option_parking', newService);
    }

    return (
        <>
            <AdminProductConfigItem title='주차 옵션' placeholder='옵션값을 입력하세요(쉼표 사용)' value={titles} onChange={(e: any) => payItemChange(e.target.value)} />
            <ArrWrap>
                {info?.option_parking.length === 0 ?
                    <></> :
                    info?.option_parking.map((data: any, index: any) => {
                        return (
                            <ArrItemWrap key={`bags-${index}`}>
                                <BasicText>{data?.parking_name}</BasicText>
                                <InputWrap>
                                    <input value={numberWithComma(data?.parking_price)} onChange={(e: any) => priceChange(index, onlyNumber(e))} />
                                    <img onClick={() => payItemDel(index)} src='/img/asset/del.svg'></img>
                                </InputWrap>
                            </ArrItemWrap>
                        )
                    })
                }
            </ArrWrap>
        </>
    )
}

const ArrWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-top: 6px;
    padding: 0 8px 12px;
`;
const ArrItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 6px;
`;
const InputWrap = styled(Row)`
    width: auto;
    gap: 6px;
    justify-content: end;
    & input{
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        padding: 5px;
        width: 80%;
    }
    & img{
        cursor: pointer;
    }
`;