import { createAction, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

const SET_PARKING = '/SET_PARKING';
const SET_RESET = '/SET_PARKING/RESET';

export const globalParkingCreator = createAction(SET_PARKING);
export const globalParkingReset = createAction(SET_RESET);

export function setGlobalParking(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalParkingCreator>> {
    return async function (dispatch) {
        try {
            dispatch(globalParkingCreator({
                data: data
            }));
        } catch (error) {

        }
    };
}

export interface Parking {
    "parking_type": any,
    "parking_name": string,
    "parking_phone_number": string,
    "parking_car": string,
    "parking_car_number": string,
    "parking_destination": string,
    "parking_airline": string,
    "parking_payment": string,
    "parking_message": string,
    "parking_time_depart_timestamp": string,
    "parking_time_depart_airplane": any,
    "parking_time_depart_flight": string,
    "parking_time_arrive_timestamp": string,
    "parking_time_arrive_airplane": any,
    "parking_time_arrive_flight": string
}

const initialState: Parking = {
    "parking_type": {},
    "parking_name": "",
    "parking_phone_number": "",
    "parking_car": "",
    "parking_car_number": "",
    "parking_destination": "",
    "parking_airline": "",
    "parking_payment": "",
    "parking_message": "",
    "parking_time_depart_timestamp": "",
    "parking_time_depart_airplane": {},
    "parking_time_depart_flight": "",
    "parking_time_arrive_timestamp": "",
    "parking_time_arrive_airplane": {},
    "parking_time_arrive_flight": ""
};

export const globalParking = handleActions<Parking, any>({
    [SET_PARKING]: (state, action: ReturnType<typeof globalParkingCreator>) => ({
        parking_type: action.payload.data.parking_type ?? state.parking_type,
        parking_name: action.payload.data.parking_name ?? state.parking_name,
        parking_phone_number: action.payload.data.parking_phone_number ?? state.parking_phone_number,
        parking_car: action.payload.data.parking_car ?? state.parking_car,
        parking_car_number: action.payload.data.parking_car_number ?? state.parking_car_number,
        parking_destination: action.payload.data.parking_destination ?? state.parking_destination,
        parking_airline: action.payload.data.parking_airline ?? state.parking_airline,
        parking_payment: action.payload.data.parking_payment ?? state.parking_payment,
        parking_message: action.payload.data.parking_message ?? state.parking_message,
        parking_time_depart_timestamp: action.payload.data.parking_time_depart_timestamp ?? state.parking_time_depart_timestamp,
        parking_time_depart_airplane: action.payload.data.parking_time_depart_airplane ?? state.parking_time_depart_airplane,
        parking_time_depart_flight: action.payload.data.parking_time_depart_flight ?? state.parking_time_depart_flight,
        parking_time_arrive_timestamp: action.payload.data.parking_time_arrive_timestamp ?? state.parking_time_arrive_timestamp,
        parking_time_arrive_airplane: action.payload.data.parking_time_arrive_airplane ?? state.parking_time_arrive_airplane,
        parking_time_arrive_flight: action.payload.data.parking_time_arrive_flight ?? state.parking_time_arrive_flight,
    }),
    [SET_RESET]: () => (initialState)
}, initialState);
