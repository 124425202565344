import React, { useRef, useState } from 'react'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import AirportBtnItem from '../../airport/item/AirportBtnItem';
import { useNavigate, useParams } from 'react-router';
import { apiSetReviewCreate } from '../../../api/api_review';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function ReviewCreatePage() {
    const {type,id} = useParams();
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const [content,setContent] = useState('');
    const fileRef = useRef<any>(null);
    const [img,setImg] = useState({
        file: null,
        fileName: '',
        fileImg: '',
    });
    
    // 인풋 파일 활성화
    const fileOpen = () =>{
        fileRef.current.click();
    }

    // 파일 선택
    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            setImg((obj:any)=>({...obj, fileName: file.name}))
            reader.onload = () => {
                const result = reader.result;
                setImg((obj:any)=>({...obj,fileImg:result,fileName: file.name,file:file}))
            };
            reader.readAsDataURL(file);
        }
    }

    const prevAction = () =>{
        navigate(-1);
    }

    const nextAction = async() =>{
        if(userInfo.user_id === 0){
            alert('로그인을 해주세요.')
            return;
        }
        if(content === ''){
            alert('내용을 입력해주세요.');
            return;
        }
        if(img.fileImg === ''){
            alert('이미지를 추가해주세요.');
            return;
        }
        const res = await apiSetReviewCreate(userInfo.token,userInfo.user_id,id,type,'',5,content,img.fileImg);
        if(res.result){
            alert('리뷰가 등록되었습니다.');
            window.location.reload();
        }else{
            alert(res.error);
        }
    }

    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={40} mFontSize={32} align='center'>후기 작성</BasicText>
            <ContentWrap>
                <MaxWidthWrap maxWidth='720px'>
                    <InputItemWrap h='auto'>
                        <textarea rows={6} placeholder='리뷰를 작성해주세요.' maxLength={400} value={content} onChange={(e:any)=>setContent(e.target.value)} />
                    </InputItemWrap>
                    <FileInputWrap cr onClick={fileOpen}>
                        <img alt='puls icon' src='/img/asset/plus.png' ></img>
                        {img.fileName === '' ? '사진을 추가해주세요' : img.fileName}
                        <input type='file' ref={fileRef} accept='image/*' onChange={fileChange}/>
                    </FileInputWrap>
                    {img.file !== null &&
                        <ImgWrap>
                            <img alt='review-img' src={img.fileImg}></img>
                        </ImgWrap>
                    }
                    <BtnWrap>
                        <AirportBtnItem prevAction={prevAction} nextName='등록' nextAction={nextAction}/>
                    </BtnWrap>
              </MaxWidthWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding-top: 80px;
    min-height: 600px;
    @media ${addTheme.media.s}{
        min-height: 500px;
    }
`;

const ContentWrap = styled.div`
    margin-top: 36px;
`;

const InputItemWrap = styled(Row)<{cr?:boolean,h?:string,mt?:string}>`
    background-color: #F5F5F5;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    width: 100%;
    height: ${({h}) => h ?? '52px'};
    padding: 10px 18px;
    cursor: ${({cr}) => cr ? 'pointer' : 'default'};
    margin-top: ${({mt}) => mt ?? '0px'};
    &>input{
        width: 100%;
        height: 100%;
        ::placeholder {
            color: #ccc;
        }
    }
    &>textarea{
        width: 100%;
        font-size: 16px;
        ::placeholder {
            color: #ccc;
        }
    }
`;

const FileInputWrap = styled(InputItemWrap)`
    margin-top: 10px;
    gap: 12px;
    &>input[type='file']{
        display: none;
        width: 0;
        height: 0;
    }
`;

const BtnWrap = styled.div`
    margin-top: 85px;
`;

const ImgWrap = styled.div`
    margin-top: 15px;
    text-align: center;
    &>img{
        max-width: 100%;
    }
`;