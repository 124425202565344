import React, { useState } from 'react'
import styled from 'styled-components'
import { BtnItem, Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import HomeApplicationItem from '../item/HomeApplicationItem';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ModalOrderTypePage from '../../modal/page/ModalOrderTypePage';

const LIST = [
    { title: '견적 확인 및 예약 진행', img: '/img/asset/3d-1.png', desc: ['에어드라이브와 함꼐 편안한 공항 이동!', '인천공항, 김포공항에서 전국 어디든 편안하게!'] },
    { title: '완료메시지 및 배차정보 받기', img: '/img/asset/3d-2.png', desc: ['에어드라이브와 함꼐 편안한 공항 이동!', '인천공항, 김포공항에서 전국 어디든 편안하게!'] },
    { title: '기사님 미팅', img: '/img/asset/3d-3.png', desc: ['에어드라이브와 함꼐 편안한 공항 이동!', '인천공항, 김포공항에서 전국 어디든 편안하게!'] },
]

export default function HomeApplicationPart() {
    const [open, setOpen] = useState(false);
    const mediaQuery = useMediaQuery(addTheme.media.m);
    const [swiper, setSwiper] = useState<SwiperClass>();
    const handlePrev = () => {
        swiper?.slidePrev()
    }
    const handleNext = () => {
      swiper?.slideNext()
    }
    return (
        <Wrap>
            <MaxWidthWrap maxWidth='1200px'>
                <InnerWrap>
                    <BasicText align='center' color='#fff' fontWeight={700} fontSize={36} mFontSize={26}>한 번에 쉽게 예약하고, 편하게 이동하세요!</BasicText>
                    {mediaQuery ?
                        <SlideWrap>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={15}
                                loop
                                onSwiper={(e:any) => {setSwiper(e)}}
                            >
                                {LIST.map((data: any, index: any) => {
                                    return (
                                        <SwiperSlide key={`s-application-${index}`}>
                                            <HomeApplicationItem data={data} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            <ArrowLeftItem onClick={handlePrev}>
                                <FaChevronLeft size={20} color='#fff'/>
                            </ArrowLeftItem>
                            <ArrowRightItem onClick={handleNext}>
                                <FaChevronRight size={20} color='#fff'/>
                            </ArrowRightItem>
                        </SlideWrap> :
                        <ContentWrap>
                            {LIST.map((data: any, index: any) => {
                                return (
                                    <HomeApplicationItem key={`application-${index}`} data={data} />
                                )
                            })}
                        </ContentWrap>
                    }
                    <BtnWrap>
                        <BtnItem onClick={() => setOpen(true)} bg='#000' color='#fff' round={30}>지금 견적 신청하기</BtnItem>
                    </BtnWrap>
                </InnerWrap>
            </MaxWidthWrap>
            <ModalOrderTypePage open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #1D4579;
    padding: 100px 0;
`;

const InnerWrap = styled.div``;
const ContentWrap = styled(RowBetween)`
    margin-top: 40px;
    flex-wrap: wrap;
    align-items: start;
    @media ${addTheme.media.s}{
        gap: 20px;
        &>div{
            width: 100%;
        }
    }
`;

const SlideWrap = styled.div`
    margin-top: 40px;
    padding: 0 25px;
    font-size: 0;
    position: relative;
    & .swiper-slide>div{
        width: 100%;
    }
`;

const ArrowItem = styled(Center)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;
const ArrowLeftItem = styled(ArrowItem)`
    left: -2px;
`;
const ArrowRightItem = styled(ArrowItem)`
    right: -2px;
`;

const BtnWrap = styled(Center)`
    margin-top: 35px;
    &>div{
        width: 286px;
        height: 48px;
    }
`;