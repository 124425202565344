// 공항 픽업 주문 타입
export const AIRPORT_TYPE:any ={
    '1': '공항 왕복',
    '2': '자택 > 공항',
    '3': '공항 > 자택'
}
// 공항 픽업 주문 타입
export const AIRPORT_TYPE2:any ={
    '1': '왕복',
    '2': '자택>공항',
    '3': '공항>자택'
}
// 공항 픽업 주문 타입 리스트
export const AIRPORT_TYPE_LIST:any = [
    {name: '자택 > 공항', value: '2'},
    {name: '공항 > 자택', value: '3'},
    {name: '왕복', value: '1'},
]

// 공항 리스트
export const AIRPORT_LIST:any = [
    { id: 1, title: '인천공항', subTitle: '제1여객터미널', value: '인천공항 제1여객터미널'},
    { id: 2, title: '인천공항', subTitle: '제2여객터미널', value: '인천공항 제2여객터미널'},
    { id: 3, title: '김포공항', subTitle: '국내선', value: '김포공항 국내선'},
    { id: 4, title: '김포공항', subTitle: '국제선', value: '김포공항 국제선'},
]

export const AIRPORT:any = {
    1: '인천공항 제1여객터미널',
    2: '인천공항 제2여객터미널',
    3: '김포공항 국내선',
    4: '김포공항 국제선',
}

// 소셜가입 유형
export const SIGN_TYPE:any = {
    2: '카카오',
    3: '네이버',
}


// 주차 타입
export const PARKING_TYPE:any = {
    1: '실내주차',
    2: '실외주차',
}

// 주차 타입 리스트
export const PARKING_TYPE_LIST:any = [
    { id: 1, title: '실내주차', value: '1'},
    { id: 2, title: '실외주차', value: '2'},
]

// 주문 상태
export const ORDER_STATUS:any = {
    0: {name:'전체', bg: '#1D4579'},
    1: {name:'접수', bg: '#1D4579'},
    2: {name:'예약 확정', bg: '#1D4579'},
    3: {name:'완료', bg: '#AAAAAA'},
    10: {name:'취소', bg: '#AAAAAA'},
}

// 주문 상태 필터 리스트
export const ORDER_STATUS_FILTER_LIST = [
    {name: '전체', value: 0},
    {name: '접수', value: 1},
    {name: '예약 확정', value: 2},
    {name: '완료', value: 3},
    {name: '취소', value: 10},
]

// 디테일 주문 상태 리스트
export const ORDER_STATUS_LIST = [
    {name: '접수', value: 1},
    {name: '예약 확정', value: 2},
    {name: '완료', value: 3},
    {name: '취소', value: 10},
]

// 리뷰 타입
export const REVIEW_TYPE:any = {
    '1': '공항이동',
    '2': '주차대행'
}

// 리뷰 타입 리스트
export const REVIEW_TYPE_LIST:any = [
    {name: '공항이동', value: 1},
    {name: '주차대행', value: 2},
]

// 상품 활성화
export const PRODUCT_ACTIVE:any = {
    0: '비활성',
    1: '활성'
}

