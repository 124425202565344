import { createAction, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginAction = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutAction = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginAction>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginAction({
        data: data
      }));
    } catch (error) {

    }
  };
}

export interface userInfo {
  "user_id": number,
  "user_timestamp": number,
  "user_social_id": string,
  "user_sign_type": string,
  "user_name": string,
  "user_nickname": string,
  "is_already_sign": boolean,
  "social_provider": string,
  "token": string,
}

export const userState: userInfo = {
  "user_id": 0,
  "user_timestamp": 0,
  "user_social_id": "",
  "user_sign_type": "",
  "user_name": "",
  "user_nickname": "",
  "is_already_sign": false,
  "social_provider": "",
  "token": "",
}

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginAction>) => ({
    user_id: action.payload.data.user_id ?? state.user_id,
    user_timestamp: action.payload.data.user_timestamp ?? state.user_timestamp,
    user_social_id: action.payload.data.user_social_id ?? state.user_social_id,
    user_sign_type: action.payload.data.user_sign_type ?? state.user_sign_type,
    user_name: action.payload.data.user_name ?? state.user_name,
    user_nickname: action.payload.data.user_nickname ?? state.user_nickname,
    is_already_sign: action.payload.data.is_already_sign ?? state.is_already_sign,
    social_provider: action.payload.data.social_provider ?? state.social_provider,
    token: action.payload.data.token ?? state.token,
  })
  ,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutAction>) => (userState),
}, userState);
