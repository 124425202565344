import React from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../../style/addTheme';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminAirportItem from '../item/AdminAirportItem';
import { numberWithComma } from '../../../../utils/format/number';

export default function AdminParkingDetailContentPart({ info }: any) {
    const parking_type = info?.order_parking_type === undefined ? {} : JSON.parse(info?.order_parking_type);
    return (
        <Wrap>
            <TitleWrap>
                <BasicText fontSize={23} mFontSize={20}>주차 대행 견적 신청서</BasicText>
            </TitleWrap>
            <AirportWrap>
                <AdminAirportItem title='출발' content={info?.order_parking_time_depart_timestamp} placeholder='출발날짜와 시간을 입력해주세요.' />
                <AdminAirportItem title='항공편' content={info?.order_parking_time_depart_flight} placeholder='출항공편을 입력해주세요.' />
                <AdminAirportItem title='터미널' content={info?.order_parking_time_depart_airplane} placeholder='출국 터미널을 선택해주세요.' />
            </AirportWrap>
            <AirportWrap>
                <AdminAirportItem title='복귀' content={info?.order_parking_time_arrive_timestamp} placeholder='복귀날짜와 시간을 입력해주세요.' />
                <AdminAirportItem title='항공편' content={info?.order_parking_time_arrive_flight} placeholder='항공편을 입력해주세요.' />
                <AdminAirportItem title='터미널' content={info?.order_parking_time_arrive_airplane} placeholder='입국 터미널을 선택해주세요.' />
            </AirportWrap>
            <MoreWrap>
                <AdminAirportItem title='픽업 방법' content={parking_type?.parking_name} placeholder='픽업 방법을 선택해주세요' />
                <AdminAirportItem title='연락처' content={info?.order_parking_name === '' && info?.order_parking_phone_number === '' ? '' : `${info?.order_parking_name} ${info?.order_parking_phone_number}`} placeholder='맡기실 분의 이름과 연락가능한 연락처를 입력해주세요.' />
                <AdminAirportItem title='차량 정보' content={info?.order_parking_car === '' && info?.order_parking_car_number === '' ? '' : `${info?.order_parking_car} ${info?.order_parking_car_number}`} placeholder='차량 기종과 차량 번호를 입력해주세요.' />
                {/* <AdminAirportItem title='여행 정보' content={info?.order_parking_destination === '' && info?.order_parking_airline === '' ? '' : `${info?.order_parking_destination} ${info?.order_parking_airline}`} placeholder='여행지와 항공사를 입력해주세요.' />
                <AdminAirportItem title='결제 방법' content={info?.order_parking_payment} placeholder='선택 안함' /> */}
                <AdminAirportItem title='추가 메모' content={info?.order_parking_message} placeholder='없음' />
            </MoreWrap>
            <ResWrap>
                <div>
                    <BasicText fontSize={28} mFontSize={22} fontWeight={700}>예상 비용</BasicText>
                    <BasicText fontSize={16} mFontSize={15}>실제 상황에 따라 견적 비용이 상이할 수 있습니다.</BasicText>
                </div>
                <BasicText fontSize={28} mFontSize={22} fontWeight={700}>{numberWithComma(info?.order_parking_price)}원</BasicText>
            </ResWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 25px;
    @media ${addTheme.media.m}{
        padding: 15px;
    }
`;

const TitleWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 8px;
`;

const AirportWrap = styled(TitleWrap)`
    gap: 8px;
    margin-top: 25px;
    & div{
        border: none;
    }
    & input{
        color: #aaa;
        font-size: 15px;
    }
`;

const MoreWrap = styled(AirportWrap)`
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e2e2e2;
    gap: 8px;
`;

const ResWrap = styled(RowBetween)`
    padding: 25px 0;
    flex-wrap: wrap;
    gap: 15px;
`;