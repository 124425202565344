import React from 'react'
import styled from 'styled-components'
import AdminTablePart from '../../common/part/AdminTablePart';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
import { momentFormat } from '../../../../utils/format/time';
import { HOME_TABLE_ORDER } from '../../data/table';
import NoListItem from '../../common/item/NoListItem';

const ListItem = ({data,itemClick}:any) =>{
    const clickEvent = () =>{
        // if(itemClick){
        //     itemClick(data.type,data.)
        // }
    }
    return (
        <TableRow>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={HOME_TABLE_ORDER[0].align} onClick={clickEvent}>{data?.order_pickup_code ?? data?.order_parking_code}</TableCell>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={HOME_TABLE_ORDER[1].align} onClick={clickEvent}>{data.type === '1' ? '공항 이동' : '주차 대행'}</TableCell>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={HOME_TABLE_ORDER[1].align} onClick={clickEvent}>{data.user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={HOME_TABLE_ORDER[2].align}>{momentFormat('YYYY-MM-DD hh:mm',data.time)}</TableCell>
        </TableRow>
    )
}

export default function AdminHomeOrderPart({list}:any) {
    const navigate = useNavigate();
    const itemClick = (type:any,id:any) =>{
        // navigate(`/admin/reviewUpdate/${id}`);
    }
    return (
        <Wrap>
            <AdminTablePart title={HOME_TABLE_ORDER}>
                {list === undefined || list.length === 0 ?
                    <NoListItem length={HOME_TABLE_ORDER.length}/> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`review-${index}`} data={data} itemClick={itemClick} />
                        )
                    })
                }
            </AdminTablePart>
        </Wrap>
    )
}

const Wrap = styled.div``;