import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import styled from 'styled-components';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtnItem, AdminWrap, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { apiAdminGetBannerList, apiAdmSetDelBanner } from '../../../../api/api_adm_popupAndBenner';
import AdminBannerItem from '../item/AdminBannerItem';
import { BANNER_LIST } from '../../data/table';

export default function AdminBannerPage() {
    const {page} = useParams();
    const resPage = page ?? 1;
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [titleTotalReload,setTitleTotalReload] = useState(false);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[resPage,adminUser.admin_id]);

    const setPage = (p:any) =>{
        navigate(`/admin/banner/${p}`);
    }

    // 수정 클릭 -> 수정 페이지로 이동
    const goDetail = (id?:any) =>{
        navigate(`/admin/bannerUpdate/${id ?? ''}`);
    }

    // 리스트
    const getList = async() =>{
        const res = await apiAdminGetBannerList(adminUser.admin_token,adminUser.admin_id, resPage,10,'1');
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 삭제
    const delClick = async(id:any) =>{
        if(window.confirm('배너를 삭제 하시겠습니까?')){
            const res = await apiAdmSetDelBanner(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('배너를 삭제했습니다.');
                getList();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 배너' part='banner' reload={titleTotalReload}>
                <AdmBtnItem bg={addTheme.infoChangeBtnBg} onClick={()=>goDetail()}>배너 등록</AdmBtnItem>
            </AdminPageTotalPart>
            <AdminWrap>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>배너 리스트</BasicText>
                <TableWrap>
                    <AdminTablePart bg={addTheme.adminColor} color='#fff' title={BANNER_LIST} minWidth={700} page={resPage} total={total} setPage={setPage}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <AdminBannerItem key={`review-item-${index}`} data={data} goDetail={()=>goDetail(data.banner_id)} del={()=>delClick(data.banner_id)}/>
                                )
                            })
                        }
                    </AdminTablePart>
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const TableWrap = styled.div`
    margin-top: 20px;
`;