import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function LoginLayoutPage({title,children,contentMt}:{title:string,children:React.ReactNode,contentMt?:string}) {
    return (
        <Wrap>
            {title === undefined || title === '' ?
                <></> :
                <BasicText fontWeight={700} fontSize={40} mFontSize={32} align='center'>{title}</BasicText>
            }
            <ContentWrap contentMt={contentMt}>
                {children}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding-top: 80px;
    min-height: 600px;
    @media ${addTheme.media.s}{
        min-height: 500px;
    }
`;
const ContentWrap = styled.div<{contentMt?:string}>`
    margin-top: ${({contentMt}) => contentMt ?? '50px'};
`;