import { axiosConfigInstance } from "./config"

// 회원 리스트
export const apiGetAdminMemberList = async(token:string,admin_id:any,page:any,count:any,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/user',token).post('',{
            method: 'getAdminUserList',
            params:{
                admin_id,page,count,search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 회원 디테일
export const apiGetAdminMemberDetail = async(token:string,admin_id:any,user_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/user',token).post('',{
            method: 'getAdminUserDetailInfo',
            params: {
                admin_id,user_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false
        }
    }
}

// 회원 디테일 메모 수정
export const apiSetAdminMemberMemo = async(token:string,admin_id:any,user_id:any,user_memo:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/user',token).post('',{
            method: 'editAdminUserInfo',
            params: {
                admin_id,user_id,user_memo
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false
        }
    }
}