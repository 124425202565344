import React from 'react'
import AdminProductConfigItem from './AdminProductConfigItem'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdminProductAirportConfigBagsItem({ info, titles, setTitles, valueChange}: any) {
    // 수화물 인풋 변경 시 -> 배열도 변경
    const bagsItemChange = (val: any) => {
        setTitles(val);
        const items = val.split(',');
        const bagsList = info?.product_pickup_config_option_bags;
        let newBags: any = info?.product_pickup_config_option_bags;
        if (items.length > bagsList.length) {
            newBags = [...bagsList, {
                "bags_id": bagsList.length + 1,
                "bags_name": items[items.length - 1],
                "bags_price": 0
            }]
        } else if (items.length === bagsList.length) {
            newBags = bagsList.map((item: any, idx: any) => ({ ...item, bags_name: items[idx] }));
        } else {
            newBags = bagsList.filter((item: any) => items.includes(item.bags_name));
        }
        valueChange('product_pickup_config_option_bags', newBags);
    }

    // 수화물 가격 변경
    const bagsPriceChange = (index: any, val: any) => {
        const newBags = info?.product_pickup_config_option_bags.map((item: any, idx: any) => idx === index ? { ...item, bags_price: val } : item);
        valueChange('product_pickup_config_option_bags', newBags);
    }

    // 수화물 제거
    const bagsItemDel = (index: any) => {
        const newBags = info?.product_pickup_config_option_bags.filter((item: any, idx: any) => idx !== index);
        setTitles(newBags.map((item: any) => item.bags_name).join(','))
        valueChange('product_pickup_config_option_bags', newBags);
    }

    return (
        <>
            <AdminProductConfigItem title='수화물' placeholder='옵션값을 입력하세요(쉼표 사용)' value={titles} onChange={(e: any) => bagsItemChange(e.target.value)} />
            <ArrWrap>
                {info?.product_pickup_config_option_bags.length === 0 ?
                    <></> :
                    info?.product_pickup_config_option_bags.map((data: any, index: any) => {
                        return (
                            <ArrItemWrap key={`bags-${index}`}>
                                <BasicText>{data?.bags_name}</BasicText>
                                <InputWrap>
                                    <input value={numberWithComma(data?.bags_price)} onChange={(e: any) => bagsPriceChange(index, onlyNumber(e))} />
                                    <img onClick={() => bagsItemDel(index)} src='/img/asset/del.svg'></img>
                                </InputWrap>
                            </ArrItemWrap>
                        )
                    })
                }
            </ArrWrap>
        </>
    )
}

const ArrWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-top: 6px;
    padding: 0 8px 12px;
`;
const ArrItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 6px;
`;
const InputWrap = styled(Row)`
    width: auto;
    gap: 6px;
    justify-content: end;
    & input{
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        padding: 5px;
        width: 80%;
    }
    & img{
        cursor: pointer;
    }
`;