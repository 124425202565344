import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../../basic/BasicText';
import { BtnItem, Row, RowBetween } from '../../../../style/basic/commonStyle';
import SelectItem from '../../../common/item/SelectItem';
import { addTheme } from '../../../../style/addTheme';
import { apiGetAdminCityList, apiGetAdminLocationList, apiSetAdminLocationUpdate } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { MenuItem } from '@mui/material';
import AdminTablePart from '../../common/part/AdminTablePart';
import { PRODUCT_CITY_LIST } from '../../data/table';
import NoListItem from '../../common/item/NoListItem';
import AdminProductAirportCityListItem from '../item/AdminProductAirportCityListItem';

export default function AdminProductAirportLocationPart() {
    const adminUser = useAdminUser();
    const [cityList,setCityList] = useState([]);
    const [city,setCity] = useState(1);
    const [location1,setLocation1] = useState([]);
    const [location2,setLocation2] = useState([]);
    const [total,setTotal] = useState([]);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getCityList();
    },[adminUser.admin_id]);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getLocation();
    },[adminUser.admin_id,city]);

    const getCityList = async() =>{
        const res = await apiGetAdminCityList(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            setCityList(res.data);
        }
    }

    const getLocation = async() =>{
        const res = await apiGetAdminLocationList(adminUser.admin_token,adminUser.admin_id,city);
        if(res.result){
            setTotal(res.data);
            setLocation1(res.data[0].region_list);
            setLocation2(res.data[1].region_list);
        }
    }

    const valueChange = (type:number,index:number,name:string,val:any) =>{
        if(type === 1){
            setLocation1((arr:any)=>arr.map((item:any,idx:any)=>idx===index ? {...item,[name]:val}:item));
            return
        }
        if(type === 2){
            setLocation2((arr:any)=>arr.map((item:any,idx:any)=>idx===index ? {...item,[name]:val}:item));
            return
        }
    }
    
    const update = async() =>{
        const list:any = {
            0: location1,
            1: location2,
        }
        const newTotal = total.map((data:any,index:any)=>({...data,region_list: list[index]}))
        const res = await apiSetAdminLocationUpdate(adminUser.admin_token,adminUser.admin_id,newTotal);
        if(res.result){
            alert('변경 되었습니다');
        }else{
            alert(res.error);
        }
    }
    return (
        <Wrap>
            <TitleWrap>
                <BasicText color='#232323' fontWeight={'700'} fontSize={18}>위치 금액</BasicText>
                <RightWrap>
                    {cityList.length === 0 ?
                        <></> :
                        <SelectItem width='auto' value={city} setValue={(val:any)=>setCity(val)} none=''>
                            {cityList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`city-${index}`} value={data.region_group_id}>{data.region_city}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                    <BtnItem className='btn' bg={addTheme.detailBtnBg} onClick={update}>위치 구간 설정</BtnItem>
                </RightWrap>
            </TitleWrap>
            <AdminTablePart title={PRODUCT_CITY_LIST} minWidth={600} mt='30px' stickyHeader maxHeight={'650px'}>
                {location1.length === 0 ? 
                    <NoListItem length={PRODUCT_CITY_LIST.length}/> :
                    location1.map((data:any,index:any)=>{
                        return(
                            <AdminProductAirportCityListItem key={`city-${index}`} index={index} data={data} data2={location2[index]} valueChange={valueChange} />
                        )
                    })
                }
            </AdminTablePart>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 6px;
`;
const RightWrap = styled(Row)`
    flex-wrap: wrap;
    width: auto;
    gap: 12px;
`;