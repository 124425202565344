import React, { useState } from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme';
import { Center, Row } from '../../../style/basic/commonStyle';
import { useMediaQuery } from '@mui/material';
import ModalOrderTypePage from '../../modal/page/ModalOrderTypePage';
import { KAKAO_LINK } from '../../../api/config';

export default function HomeRightPart() {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const [open,setOpen] = useState(false);

    const kakaoClick = () =>{
        window.open(KAKAO_LINK);
    }

    return (
        <>
            {
                mediaQuery ?
                    <MoWrap>
                        <KakaoWrap onClick={kakaoClick}>카톡 상담하기</KakaoWrap>
                        <MoNowWrap onClick={()=>setOpen(true)}>지금 바로 예약하기</MoNowWrap>
                    </MoWrap> :
                    <PcWrap>
                        <PcKakaoWrap onClick={kakaoClick}>카톡 상담하기</PcKakaoWrap>
                        <PcNowWrap onClick={()=>setOpen(true)}>지금 바로 예약하기</PcNowWrap>
                    </PcWrap>
            }
            <ModalOrderTypePage open={open} setOpen={setOpen}/>
        </>
    )
}

const PcWrap = styled(Row)`
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    gap: 10px;
    z-index: 48;
`;

const MoWrap = styled(Row)`
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    flex-direction: column;
    align-items: end;
    gap: 10px;
    z-index: 48;
`;

const KakaoWrap = styled(Center)`
    background-color: ${addTheme.kakaoColor};
    color: #3C1E1E;
    padding: 10px 18px;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
`;

const PcKakaoWrap = styled(KakaoWrap)`
    width: 240px;
    height: 56px;
    font-size: 22px;
`;
const NowWrap = styled(Center)`
    background-color: #000;
    color: #fff;
    padding: 13px 22px;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
`;

const PcNowWrap = styled(NowWrap)`
    width: 240px;
    height: 56px;
    font-size: 22px;
`;

const MoNowWrap = styled(NowWrap)`
    width: 100%;
    text-align: center;
    border-radius: 8px;
`;