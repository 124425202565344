import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

export default function ServiceTitleItem({mt,title,title1,title2}:any) {
    return (
        <Wrap mt={mt}>
            <MaxWidthWrap>
                <Row>
                    <TitleItem>{title}</TitleItem>
                </Row>
                <TitleTextWrap>
                    <TitleTextItem dangerouslySetInnerHTML={{__html:title1}}></TitleTextItem>
                    <TitleTextItem dangerouslySetInnerHTML={{__html:title2}}></TitleTextItem>
                </TitleTextWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{mt?:string}>`
    margin-top: ${({mt}) => mt ?? '0'};
`;
const TitleItem = styled(Center)`
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    padding: 10px 14px;
`;
const TitleTextWrap = styled.div`
    margin-top: 30px;
`;
const TitleTextItem = styled.div`
    font-size: 34px;
    & span {
        font-size: 38px;
        color: ${addTheme.detailBtnBg};
        font-weight: 700;
    }
    @media ${addTheme.media.m}{
        font-size: 26px;
        & span {
            font-size: 30px;
        }
    }
`;