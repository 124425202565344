import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { PRODUCT_CITY_LIST } from '../../data/table'
import { numberWithComma } from '../../../../utils/format/number'
import { onlyNumber } from '../../../../utils/format/string'

const CELL_STYLE = {
    padding:'14px 10px',
    borderBottom:'none',
    '& input':{
        border: '1px solid #ccc',
        width: '100%',
        padding: '8px',
        borderRadius: '6px'
    }
}
export default function AdminProductAirportCityListItem({data,data2,index,valueChange}:any) {
    return (
        <TableRow>
            <TableCell sx={CELL_STYLE} align={PRODUCT_CITY_LIST[0].align}>{data.region_pu_name}</TableCell>
            <TableCell sx={CELL_STYLE} align={PRODUCT_CITY_LIST[1].align}>
                <input value={numberWithComma(data.region_pu_one_way_price)} placeholder='편도' onChange={(e:any)=>valueChange(1,index,'region_pu_one_way_price',onlyNumber(e))}></input>
            </TableCell>
            <TableCell sx={CELL_STYLE} align={PRODUCT_CITY_LIST[1].align}>
                <input value={numberWithComma(data.region_pu_round_trip_price)} placeholder='왕복' onChange={(e:any)=>valueChange(1,index,'region_pu_round_trip_price',onlyNumber(e))}></input>
            </TableCell>
            <TableCell sx={CELL_STYLE} align={PRODUCT_CITY_LIST[2].align}>
                <input value={numberWithComma(data2.region_pu_one_way_price)} placeholder='편도' onChange={(e:any)=>valueChange(2,index,'region_pu_one_way_price',onlyNumber(e))}></input>
            </TableCell>
            <TableCell sx={CELL_STYLE} align={PRODUCT_CITY_LIST[2].align}>
                <input value={numberWithComma(data2.region_pu_round_trip_price)} placeholder='왕복' onChange={(e:any)=>valueChange(2,index,'region_pu_round_trip_price',onlyNumber(e))}></input>
            </TableCell>
        </TableRow>
    )
}
