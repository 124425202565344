import { axiosConfigInstance } from "./config"

// 상품 리스트
export const apiGetAdminProductList = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'getAdminProductList',
            params: {
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 픽업 config
export const apiGetAdminPickupConfig = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/pickup',token).post('',{
            method: 'getAdminPickUpProductConfig',
            params: {
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 픽업 config 변경
export const apiSetAdminPickupConfigUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/pickup',token).post('',{
            method: 'adminPickUpProductConfigEdit',
            params: {
                admin_id,
                ...info
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 픽업 큰 지역 리스트
export const apiGetAdminCityList = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'getAdminRegionCityList',
            params: {
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 픽업 작은 지역 리스트
export const apiGetAdminLocationList = async(token:string,admin_id:any,region_group_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/pickup',token).post('',{
            method: 'getAdminPickUpProductRegionList',
            params: {
                admin_id,region_group_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 픽업 지역 가격 변경
export const apiSetAdminLocationUpdate = async(token:string,admin_id:any,order_region_list:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/pickup',token).post('',{
            method: 'adminPickUpProductRegionEdit',
            params: {
                admin_id,order_region_list
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주차 상품 디테일
export const apiGetAdminParkingConfig = async(token:string,admin_id:any,airplane_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/parking',token).post('',{
            method: 'getAdminParkingProductInfo',
            params: {
                admin_id,
                airplane_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주차 상품 옵션 디테일
export const apiGetAdminParkingOptionConfig = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/parking',token).post('',{
            method: 'getAdminParkingProductConfig',
            params: {
                admin_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주차 상품 변경
export const apiSetAdminParkingConfigUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/product/parking',token).post('',{
            method: 'setAdminParkingInfo',
            params: {
                admin_id,...info
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}