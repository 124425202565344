import { Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import BasicText from '../../../basic/BasicText';
import { apiAdmGetAlarmTemplateList, apiAdmSetAlarmSend } from '../../../../api/api_adm_alarm';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import SelectItem from '../../../common/item/SelectItem';
import { addTheme } from '../../../../style/addTheme';
import { momentFormat } from '../../../../utils/format/time';

const TEMP = {
    "ChannelId": "@nvpworld",
    "TemplateName": "",
    "TemplateMessageType": 1,
    "TemplateEmphasizeType": 1,
    "TemplateContent": "",
    "TemplateExtra": "",
    "TemplateTitle": "",
    "TemplateSubtitle": "",
    "Status": 2,
    "SecurityFlag": false,
    "Buttons": null
}
const INPUT_INIT = {
    user_name: '',
    product_name: '',
    order_date: '',
}

export default function AdminModalAddSendPart({ info, open, setOpen, type }: any) {
    const adminUser = useAdminUser();
    const [tempList, setTemList] = useState([]);
    const [selectTemp, setSelectTemp] = useState(TEMP);
    const [sendInput, setSendInput] = useState(INPUT_INIT)
    const [resContent, setResContent] = useState('');

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        if (!open) return;
        getTempList();
    }, [adminUser.admin_id, open]);

    useEffect(() => {
        if (info === undefined) return
        if(!open) return;
        setSendInput({
            user_name: info.user_name,
            product_name: type === 'parking' ? '주차대행' : '공항이동',
            order_date: momentFormat('YYYY-MM-DD', new Date().getTime()),
        })
    }, [info, type, open]);

    useEffect(() => {
        let content = selectTemp.TemplateContent;
        content = content.replaceAll('#{user_name}', sendInput.user_name);
        content = content.replaceAll('#{product_name}', sendInput.product_name);
        content = content.replaceAll('#{order_date}', sendInput.order_date);
        setResContent(content);
    }, [selectTemp.TemplateContent, sendInput.user_name, sendInput.product_name, sendInput.order_date]);

    const getTempList = async () => {
        const res = await apiAdmGetAlarmTemplateList(adminUser.admin_token, adminUser.admin_id);
        if (res.result) {
            setTemList(res.data);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setSelectTemp(TEMP);
        setSendInput(INPUT_INIT);
    }

    const selectTempChange = (val: any) => {
        const chk: any = tempList.filter((data: any) => data.TemplateName === val);
        if (chk.length === 0) return;
        setResContent(chk[0]?.TemplateContent);
        setSelectTemp(chk[0]);
    }

    const valueChange = (name: string, val: any) => {
        setSendInput((obj: any) => ({
            ...obj,
            [name]: val,
        }))
    }

    const sendAction = async () => {
        const res = await apiAdmSetAlarmSend(adminUser.admin_token,adminUser.admin_id,selectTemp.TemplateName,info.user_phone_number,resContent,info.user_id)
        if(res.result){
            alert('알림톡을 전송했습니다.');
        }else{
            alert(res.error);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': { margin: '8px', maxWidth: '720px', width: '100%', borderRadius: '8px', backgroundColor: '#fff' }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ padding: '20px 24px 16px' }}>
                <BasicText fontWeight={700} fontSize={20} align='center' color='#1c1c1c'>추가 전송</BasicText>
            </DialogTitle>
            <DialogContent>
                <Wrap>
                    <InnerWrap>
                        <ContentWrap>
                            <ItemWrap pWidth={115} gap='5px'>
                                <BasicText>템플릿</BasicText>
                                <InputWrap padding='0' >
                                    <SelectItem value={selectTemp?.TemplateName ?? ''} setValue={selectTempChange} none='no' width='auto'>
                                        <MenuItem value='' disabled>템플릿을 선택해주세요.</MenuItem>
                                        {tempList.map((data: any, index: any) => {
                                            return (
                                                <MenuItem key={`temp-${index}`} value={data.TemplateName}>{data.TemplateName}</MenuItem>
                                            )
                                        })}
                                    </SelectItem>
                                </InputWrap>
                            </ItemWrap>
                            <BasicText>변수</BasicText>
                            <ItemWrap pWidth={115} gap='5px'>
                                <BasicText>user_name</BasicText>
                                <InputWrap>
                                    <input value={sendInput.user_name} onChange={(e: any) => valueChange('user_name', e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={115} gap='5px'>
                                <BasicText>product_name</BasicText>
                                <InputWrap>
                                    <input value={sendInput.product_name} onChange={(e: any) => valueChange('product_name', e.target.value)}></input>
                                </InputWrap>
                            </ItemWrap>
                        </ContentWrap>
                    </InnerWrap>
                    <InnerWrap>
                        <ContentWrap>
                            <ItemWrap pWidth={80} gap='5px' align='start'>
                                <BasicText>알림톡</BasicText>
                                <InputWrap>
                                    <ContentTextItem dangerouslySetInnerHTML={{ __html: resContent === '' ? '-' : resContent }} />
                                </InputWrap>
                            </ItemWrap>
                        </ContentWrap>
                    </InnerWrap>
                </Wrap>
                <BtnWrap>
                    <BtnItem bg={addTheme.detailBtnBg} onClick={sendAction}>전송</BtnItem>
                    <BtnItem bg={addTheme.detailBtnBg} onClick={handleClose}>취소</BtnItem>
                </BtnWrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled(Row)`
    align-items: start;
    gap: 15px;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const InnerWrap = styled.div`
    width: 50%;
`;

const ContentTextItem = styled.div`
    white-space: pre-wrap;
`;

const BtnWrap = styled(Center)`
    margin-top: 15px;
    gap: 20px;
    &>div{
        width: 80px;
    }
`;