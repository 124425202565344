import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { addTheme } from '../../../../style/addTheme';
import { AdminWrap, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import SearchItem from '../../common/item/SearchItem';
import AdminTablePart from '../../common/part/AdminTablePart';
import { TABLE_MEMBER } from '../../data/table';
import { useNavigate, useParams } from 'react-router';
import NoListItem from '../../common/item/NoListItem';
import AdminMemberListItem from '../item/AdminMemberListItem';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiGetAdminMemberList } from '../../../../api/api_adm_member';

export default function AdminMemberPage() {
    const {page} = useParams();
    const resPage = page ?? 1;
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [search,setSearch] = useState('');
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[resPage,search,adminUser.admin_id])

    const setPage = (p:any) =>{
        navigate(`/admin/member/${p}`);
    }

    // 검색
    const searchChange = (val:string) =>{
        setSearch(val);
        setPage(1);
    }

    const getList = async() =>{
        const res = await apiGetAdminMemberList(adminUser.admin_token,adminUser.admin_id,resPage,10,search);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 디테일
    const goDetail = (id:any) =>{
        navigate(`/admin/memberDetail/${id}`);
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 회원' part='user' unit='명' />
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={19} mFontSize={16} fontWeight={700}>회원 리스트</BasicText>
                    <SearchItem searchAction={(sc:any)=>searchChange(sc)} placeholder='이름, 연락처로 검색' />
                </TitleWrap>
                <AdminTablePart title={TABLE_MEMBER} minWidth={1100} page={resPage} setPage={setPage} total={total} mt='30px'>
                    {list.length === 0 ? 
                        <NoListItem length={TABLE_MEMBER.length}/> :
                        list.map((data:any,index:any)=>{
                            return(
                                <AdminMemberListItem key={`member-${index}`} data={data} goDetail={goDetail} />
                            )
                        })
                    }
                </AdminTablePart>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;
