import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { SIGN_TYPE } from '../../../data/airport';
import { apiGetMyInfo } from '../../../api/api_mypage';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

const Item = ({title,content}:{title:string,content:string}) =>{
    return (
        <ItemWrap>
            <BasicText>{title}</BasicText>
            <BasicText>{content}</BasicText>
        </ItemWrap>
    )
}

export default function MyInfoPage() {
    const userInfo = useUserInfo();
    const [info,setInfo] = useState({
        "user_id": 0,
        "user_sign_type": "1",
        "user_name": "",
        "user_phone_number": " ",
        "user_email": " ",
        "user_timestamp": 0,
        "user_last_timestamp": 0
    });

    useEffect(()=>{
        if(userInfo.user_id === 0) return;
        getInfo();
    },[userInfo.user_id])

    const getInfo = async() =>{
        const res = await apiGetMyInfo(userInfo.token,userInfo.user_id);
        if(res.result){
            setInfo(res.data);
        }
    }

    return (
        <Wrap>
            <ContentWrap>
                <Item title='성함' content={info.user_name} />
                <Item title='소셜' content={SIGN_TYPE[info.user_sign_type]} />
                <Item title='전화번호' content={info.user_phone_number === ' ' ? '-' : info.user_phone_number} />
                <Item title='이메일' content={info.user_email === ' ' ? '-' : info.user_email} />
            </ContentWrap>
            <Center>
                <BtnItem>정보 수정</BtnItem>
            </Center>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    align-items: start;
    gap: 14px;
    flex-direction: column;
`;
const ItemWrap =  styled(RowBetween)``;
const BtnItem = styled(Center)`
    margin-top: 40px;
    width: 160px;
    height: 46px;
    background-color: #F5F5F5;
    border-radius: 8px;
    color: #2A2A2A;
    cursor: pointer;
`;