import React from 'react'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import { BtnItem, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import AdminAirportItem from '../../admin/reservation/item/AdminAirportItem';
import { AIRPORT_TYPE } from '../../../data/airport';
import { momentFormat } from '../../../utils/format/time';

const AddrItem = ({ img, title, placeholder }: any) => {
    return (
        <AddrItemWrap $active={title === ''}>
            <img src={img}></img>
            <BasicText color='#000' fontWeight={700} fontSize={18} mFontSize={17}>{title === '' ? placeholder : title}</BasicText>
        </AddrItemWrap>
    )
}

export default function AirportViewPart({info,type}:any) {
    const startDate = info?.pickup_time_depart_timestamp === '' ? '' : momentFormat('YYYY-MM-DD HH:mm',info?.pickup_time_depart_timestamp);
    const endDate = info?.pickup_time_arrive_timestamp === '' ? '' : momentFormat('YYYY-MM-DD HH:mm',info?.pickup_time_arrive_timestamp);
    const bagsRes = info?.pickup_bags.map((item:any)=>item.bags_count === 0 ? '':`${item.bags_name} ${item.bags_count}개`).filter((item:any)=>item).join(',');
    const serviceRes = info?.pickup_service.map((item:any)=>item.service_count === 0 ? '':`${item.service_name} ${item.service_count}개`).filter((item:any)=>item).join(',');
    return (
        <Wrap>
            <TitleWrap>
                <BtnItem bg='#000' round={30} padding='9px 16px' fontSize='15px'>{AIRPORT_TYPE[info?.pickup_type]}</BtnItem>
                <BasicText fontSize={23} mFontSize={20}>{'공항 견적 신청서'}</BasicText>
            </TitleWrap>
            <AddrWrap>
                <AddrItem img='/img/asset/start.svg' title={info?.pickup_depart_location?.address === undefined ? '' : info?.pickup_depart_location?.address} placeholder='출발지' />
                <AddrItem img='/img/asset/end.svg' title={info?.pickup_arrive_location?.airplane_name === undefined ? '' : `${info?.pickup_arrive_location?.airplane_name} ${info?.pickup_arrive_location?.airplane_sub_name}`} placeholder='도착지' />
            </AddrWrap>
            {info?.pickup_type === '1' &&
                // 왕복
                <>
                    <TicketWrap>
                        <AdminAirportItem title='출발' color={startDate === '' ? '#aaa' :'#000'} content={startDate === '' ? '' : `${startDate} ${info?.pickup_time_depart_ap === '' ? '' : `(출발시간 ${info?.pickup_time_depart_ap})`}`} placeholder='출발날짜와 자택 출발시간을 입력해주세요.' />
                        <AdminAirportItem title='항공편' color={info?.pickup_time_depart_flight === '' ? '#aaa' : '#000'} content={info?.pickup_time_depart_flight} placeholder='항공편을 입력해주세요.' />
                    </TicketWrap>
                    <TicketWrap>
                        <AdminAirportItem title='복귀' color={endDate === '' ? '#aaa' :'#000'} content={endDate === '' ? '' : `${endDate} ${info?.pickup_time_arrive_ap === '' ? '' : `(도착 예정시간 ${info?.pickup_time_arrive_ap})`}`} placeholder='복귀날짜와 비행기 도착 예정시간을 입력해주세요.' />
                        <AdminAirportItem title='항공편' color={info?.pickup_time_arrive_flight === '' ? '#aaa' : '#000'} content={info?.pickup_time_arrive_flight} placeholder='항공편을 입력해주세요.' />
                    </TicketWrap>
                </>
            }
            {info?.pickup_type === '2' &&
                // 자택>공항
                <>
                    <TicketWrap>
                        <AdminAirportItem title='출발' color={startDate === '' ? '#aaa' :'#000'} content={startDate === '' ? '' : `${startDate} ${info?.pickup_time_depart_ap === '' ? '' : `(출발시간 ${info?.pickup_time_depart_ap})`}`} placeholder='출발날짜와 자택 출발시간을 입력해주세요.' />
                        <AdminAirportItem title='항공편' color={info?.pickup_time_depart_flight === '' ? '#aaa' : '#000'} content={info?.pickup_time_depart_flight} placeholder='항공편을 입력해주세요.' />
                    </TicketWrap>
                </>
            }
            {info?.pickup_type === '3' &&
                // 공항>자택
                <>
                    <TicketWrap>
                        <AdminAirportItem title='입국' color={startDate === '' ? '#aaa' :'#000'} content={startDate === '' ? '' : `${startDate} ${info?.pickup_time_depart_ap === '' ? '' : `(도착 예정 시간 ${info?.pickup_time_depart_ap})`}`} placeholder='입국날짜와 도착 예정 시간을 입력해주세요.' />
                        <AdminAirportItem title='항공편' color={info?.pickup_time_depart_flight === '' ? '#aaa' : '#000'} content={info?.pickup_time_depart_flight} placeholder='항공편을 입력해주세요.' />
                    </TicketWrap>
                </>
            }
            <MoreWrap>
                <AdminAirportItem title='인원' content={info?.pickup_people === '' ? '' : `${info?.pickup_people}명`} placeholder='0명'/>
                <AdminAirportItem title='수화물' content={bagsRes} placeholder='0개'/>
                <AdminAirportItem title='편의서비스' content={serviceRes} placeholder='0개'/>
                <AdminAirportItem title='경유지' content={info?.pickup_stopover_location} placeholder='없음'/>
                <AdminAirportItem title='차량 선택' content={info?.pickup_car?.car_name === undefined ? '' : info?.pickup_car?.car_name} placeholder='선택 안함'/>
                <AdminAirportItem title='결제 방법' content={info?.pickup_payment} placeholder='선택 안함'/>
                <AdminAirportItem title='추가 메모' content={info?.pickup_message} placeholder='없음'/>
            </MoreWrap>
            {/* {type === 'result' &&
                <MoreWrap>
                    <BasicText fontSize={24} mFontSize={20} fontWeight={700}>예상 비용</BasicText>
                    <BasicText fontSize={16} mFontSize={15}>실제 상황에 따라 견적 비용이 상이할 수 있습니다.</BasicText>
                    <PriceWrap>
                        <BasicText fontSize={26} mFontSize={24} fontWeight={700}>150,000원</BasicText>
                    </PriceWrap>
                </MoreWrap>
            } */}
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    @media ${addTheme.media.m}{
        padding: 15px;
    }
    @media ${addTheme.media.s}{
        padding: 0;
        box-shadow: none;
    }
`;

const TitleWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 8px;
    & p{
        word-break: break-all;
    }
`;

const AddrWrap = styled(TitleWrap)`
    margin-top: 20px;
    gap: 16px;
`;

const TicketWrap = styled(TitleWrap)`
    margin-top: 24px;
    gap: 6px;
`;

const AddrItemWrap = styled(Row)<{$active:boolean}>`
    gap: 8px;
    opacity: ${({$active}) => $active ? 0.3 : 1};
`;

const MoreWrap = styled(TitleWrap)`
    border-top: 1px solid #E6E6E6;
    padding-top: 30px;
    margin-top: 30px;
`;

const PriceWrap = styled(Row)`
    margin-top: 8px;
    justify-content: end;
`;