import React from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function ServicePart02Item({item}:any) {
    return (
        <Wrap>
            <ImgWrap>
                <img alt='content-img' src={item.img} />
            </ImgWrap>
            <TextWrap>
                <BasicText align='center' color='#fff' fontSize={32} mFontSize={24} fontWeight={700}>{item.title}</BasicText>
                <ContentItem dangerouslySetInnerHTML={{__html:item.content1}}></ContentItem>
                <ContentItem dangerouslySetInnerHTML={{__html:item.content2}}></ContentItem>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    border-radius: 10px;
    overflow: hidden;
    position: relative;
`;
const ImgWrap = styled(Center)`
    & img{
        width: 100%;
    }
`;

const TextWrap = styled(Center)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    flex-direction: column;
`;

const ContentItem = styled.div`
    color: #fff;
    line-height: 1.2;
    font-size: 15px;
`;