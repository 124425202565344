import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap, BtnItem } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { addTheme } from '../../../../style/addTheme';
import { REVIEW_LIST } from '../../data/table';
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiGetAdminReviewList, apiSetAdminReviewDel } from '../../../../api/api_adm_review';
import NoListItem from '../../common/item/NoListItem';
import AdminReviewListItem from '../item/AdminReviewListItem';

export default function AdminReviewPage() {
    const {page} = useParams();
    const resPage = page ?? 1;
    const navigate = useNavigate();
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [reload,setReload] = useState(false);
    const adminUser = useAdminUser();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[adminUser.admin_id,resPage,])

    const setPage = (p:any)=>{
        navigate(`/admin/review/${p}`);
    }

    const getList = async() =>{
        const res = await apiGetAdminReviewList(adminUser.admin_token,adminUser.admin_id,resPage,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const goCreate = () =>{
        navigate('/admin/reviewCreate');
    }
    const goDetail = (id:any)=>{
        navigate(`/admin/reviewDetail/${id}`);
    }

    const delAction = async(id:any) =>{
        if(window.confirm('삭제하시겠습니까?')){
            const res = await apiSetAdminReviewDel(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('리뷰가 삭제되었습니다.');
                getList();
                setReload(!reload);
            }else{
                alert(res.error);
            }
        }
    }
    return (
        <Wrap>
            <AdminPageTotalPart title='전체 후기' part='review' unit='개' reload={reload}>
                <BtnItem bg={addTheme.infoChangeBtnBg} onClick={goCreate}>후기 등록</BtnItem>
            </AdminPageTotalPart>
            <AdminWrap>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>후기 리스트</BasicText>
                <AdminTablePart title={REVIEW_LIST} mt='30px' minWidth={1100} page={resPage} setPage={setPage} total={total} >
                    {list.length === 0 ?
                        <NoListItem length={REVIEW_LIST.length} /> :
                        list.map((data:any,index:any)=>{
                            return(
                                <AdminReviewListItem key={`review-${index}`} data={data} delAction={delAction} goDetail={goDetail} />
                            )
                        })
                    }
                </AdminTablePart>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;