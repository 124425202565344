import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdminWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import BasicText from '../../../basic/BasicText';
import SearchItem from '../../common/item/SearchItem';
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import AdminTablePart from '../../common/part/AdminTablePart';
import { TABLE_AIRPORT } from '../../data/table';
import NoListItem from '../../common/item/NoListItem';
import AdminAirportListitem from '../item/AdminAirportListitem';
import { ORDER_STATUS_FILTER_LIST } from '../../../../data/airport';
import { apiGetAdminAirportCount, apiGetAdminAirportList } from '../../../../api/api_adm_airportAndParking';

export default function AdminAirportPage() {
    const { page } = useParams();
    const resPage = page ?? 1;
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [count, setCount] = useState({
        "yesterday_reserve_count": 0,
        "today_reserve_count": 0,
        "total_reserve_count": 0
    })
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState({
        sc: '',
        select: 0,
    });

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        getCount()
    }, [adminUser.admin_id])

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        getList();
    }, [resPage, search.sc, search.select, adminUser.admin_id])

    const setPage = (p: any) => {
        navigate(`/admin/airport/${p}`);
    }

    // search, select
    const searchChange = (name: string, val: any) => {
        setSearch((obj: any) => ({
            ...obj,
            [name]: val,
        }))
        setPage(1);
    }

    const getList = async () => {
        const res = await apiGetAdminAirportList(adminUser.admin_token, adminUser.admin_id, resPage, 10, search.select, search.sc);
        if (res.result) {
            setList(res.data);
            setTotal(res.total);
        }
    }

    const getCount = async () => {
        const res = await apiGetAdminAirportCount(adminUser.admin_token, adminUser.admin_id,'1');
        if (res.result) {
            setCount(res.data);
        }
    }

    const goDetail = (id: any) => {
        navigate(`/admin/airportDetail/${id}`)
    }
    return (
        <Wrap>
            <CountWrap>
                <AdminPageTotalPart title='전체 예약' part='airport' number={count.total_reserve_count} unit='건' />
                <AdminPageTotalPart title='오늘 예약' part='airport' number={count.today_reserve_count} unit='건' />
            </CountWrap>
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={19} mFontSize={16} fontWeight={700}>예약 리스트</BasicText>
                    <SearchWrap>
                        <SelectItem value={search.select} setValue={(val: any) => searchChange('select', val)} width='124px' none='no'>
                            {ORDER_STATUS_FILTER_LIST.map((data: any, index: any) => {
                                return (
                                    <MenuItem key={`state-${index}`} value={data.value}>{data.name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                        <SearchItem searchAction={(sc: any) => searchChange('sc', sc)} placeholder='이름, 연락처로 검색' />
                    </SearchWrap>
                </TitleWrap>
                <AdminTablePart title={TABLE_AIRPORT} minWidth={1100} page={resPage} setPage={setPage} total={total} mt='30px'>
                    {list.length === 0 ?
                        <NoListItem length={TABLE_AIRPORT.length} /> :
                        list.map((data: any, index: any) => {
                            return (
                                <AdminAirportListitem key={`airport-${index}`} data={data} goDetail={goDetail} />
                            )
                        })
                    }
                </AdminTablePart>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const CountWrap = styled(Row)`
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    flex-wrap: wrap;
    &>div{
        width: auto;
    }
`;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const SearchWrap = styled(Row)`
    width: auto;
    flex-wrap: wrap;
    gap: 15px;
`;