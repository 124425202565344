import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';

export default function HomeCarPart() {
    const list = new Array(6).fill('');
    
    return (
        <Wrap>
            <MaxWidthWrap maxWidth={addTheme.contentMaxWidth}>
                <InnerWrap>
                    <BasicText align='center' fontSize={36} mFontSize={26} fontWeight={700}>상황에 맞는 다양한 차량</BasicText>
                    <BasicText align='center' fontSize={22} mFontSize={18} marginTop={0.5}>원하는 어떤 차량도 예약가능합니다.</BasicText>
                    <ContentWrap>
                        {list.map((data:any,index:any)=>{
                            return(
                                <ImgWrap key={`car-${index}`}>
                                    <img src={`/img/asset/car0${index+1}.png`} alt='car'></img>
                                </ImgWrap>
                            )
                        })}
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 160px;
`;
const InnerWrap = styled.div``;
const ContentWrap = styled(Row)`
    flex-wrap: wrap;
    margin-top: 25px;
`;
const ImgWrap = styled.div`
    width: 33.33%;
    &>img{
        width: 100%;
    }
    @media ${addTheme.media.m}{
        width: 50%;
    }
`;