import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import AirportBtnItem from '../../airport/item/AirportBtnItem';
import { onlyNumber } from '../../../utils/format/string';
import ModalParkingTypePage from '../../modal/page/ModalParkingTypePage';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { apiSetOrderParking } from '../../../api/api_airport';
import { apiGetParkingConfig } from '../../../api/api_main';

export default function ParkingInput2Part({ info, valueChange, setStep }: any) {
    const [parkingTypeOpen, setParkingTypeOpen] = useState(false);
    const [config, setConfig] = useState({
        product_parking_config_option_parking: [],
        product_parking_config_option_payment: [],
    });
    const userInfo = useUserInfo();

    useEffect(() => {
        getConfig();
    }, [])

    const getConfig = async () => {
        const res = await apiGetParkingConfig();
        if (res.result) {
            setConfig(res.data);
        }
    }

    const nextAction = async () => {
        if (info?.parking_type?.parking_id === undefined) {
            alert('주차 유형을 선택해주세요.');
            return;
        }
        if (info?.parking_name === '' || info?.parking_phone_number === '') {
            alert('이름과 연락처를 입력해주세요.');
            return
        }
        if (info?.parking_car === '' || info?.parking_car_number === '') {
            alert('차량 기종과 차량 번호를 입력해주세요.');
            return;
        }

        if (userInfo.user_id !== 0) {
            const res = await apiSetOrderParking(userInfo.token, userInfo.user_id, {
                ...info,
                parking_time_depart_airplane: `${info.parking_time_depart_airplane.airplane_name} ${info.parking_time_depart_airplane.airplane_sub_name}`,
                parking_time_arrive_airplane: `${info.parking_time_arrive_airplane.airplane_name} ${info.parking_time_arrive_airplane.airplane_sub_name}`,
            });
            if (res.result) {
                setStep(3);
            } else {
                alert(res.error);
            }
        } else {
            setStep(3);
        }
    }
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={29} mFontSize={21}>주차 정보를 입력해 주세요.</BasicText>
            <InputWrap>
                <InputItemWrap cr onClick={() => setParkingTypeOpen(true)} >
                    <BasicText color={info?.parking_type?.parking_id === undefined ? '#CCCCCC' : '#000'}>{info?.parking_type?.parking_id === undefined ? '픽업 방법을 선택해주세요.' : info?.parking_type?.parking_name} </BasicText>
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_name ?? ''} onChange={(e: any) => valueChange('parking_name', e.target.value)} placeholder={`맡기실 분의 이름을 입력해주세요.`} maxLength={20} />
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_phone_number ?? ''} onChange={(e: any) => valueChange('parking_phone_number', onlyNumber(e))} placeholder={`연락가능한 연락처를 입력해주세요.`} maxLength={20} />
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_car ?? ''} onChange={(e: any) => valueChange('parking_car', e.target.value)} placeholder={`차량 기종을 입력해주세요.`} maxLength={100} />
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_car_number ?? ''} onChange={(e: any) => valueChange('parking_car_number', e.target.value)} placeholder={`차량 번호를 입력해주세요.`} maxLength={12} />
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_message ?? ''} onChange={(e: any) => valueChange('parking_message', e.target.value)} placeholder={`요청 사항을 입력해주세요.`} maxLength={350} />
                </InputItemWrap>
            </InputWrap>
            <BtnWrap>
                <AirportBtnItem prevAction={() => setStep(1)} nextAction={nextAction} />
            </BtnWrap>
            <ModalParkingTypePage info={info} list={config.product_parking_config_option_parking} open={parkingTypeOpen} setOpen={setParkingTypeOpen} valueChange={valueChange} />
        </Wrap>
    )
}

const Wrap = styled.div``;

const InputWrap = styled(Row)`
    margin-top: 40px;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    position: relative;
`;

// const InputItemWrap = styled(Row) <{ cr?: boolean, h?: string, mt?: string }>`
//     background-color: #F5F5F5;
//     border: 1px solid #DEDEDE;
//     border-radius: 5px;
//     width: 100%;
//     height: ${({ h }) => h ?? '52px'};
//     padding: 10px 18px;
//     cursor: ${({ cr }) => cr ? 'pointer' : 'default'};
//     margin-top: ${({ mt }) => mt ?? '0px'};
//     &>input{
//         width: 100%;
//         height: 100%;
//         ::placeholder {
//             color: #ccc;
//         }
//     }
//     &>textarea{
//         width: 100%;
//         font-size: 16px;
//         ::placeholder {
//             color: #ccc;
//         }
//     }
// `;

const InputItemWrap = styled(Row) <{ cr?: boolean, h?: string, mt?: string, padding?: string }>`
    background-color: #F5F5F5;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    width: 100%;
    height: ${({ h }) => h ?? '52px'};
    padding: ${({ padding }) => padding ?? '10px 18px'};
    cursor: ${({ cr }) => cr ? 'pointer' : 'default'};
    margin-top: ${({ mt }) => mt ?? '0px'};
    &>input{
        width: 100%;
        height: 100%;
        ::placeholder {
            color: #ccc;
        }
    }
    &>textarea{
        width: 100%;
        font-size: 16px;
        ::placeholder {
            color: #ccc;
        }
    }
`;

const BtnWrap = styled.div`
    margin-top: 80px;
`;