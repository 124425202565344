import React from 'react'
import AdminProductConfigItem from './AdminProductConfigItem'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdminProductAirportConfigCarItem({ info, titles, setTitles, valueChange }: any) {

    // 차량 인풋 변경 시 -> 배열도 변경
    const carItemChange = (val: any) => {
        setTitles(val);
        const items = val.split(',');
        const carList = info?.product_pickup_config_option_car;
        let newCar: any = info?.product_pickup_config_option_car;
        if (items.length > carList.length) {
            newCar = [...carList, {
                "car_id": carList.length + 1,
                "car_name": items[items.length - 1],
                "car_price": 0
            }]
        } else if (items.length === carList.length) {
            newCar = carList.map((item: any, idx: any) => ({ ...item, car_name: items[idx] }));
        } else {
            newCar = carList.filter((item: any) => items.includes(item.car_name));
        }
        valueChange('product_pickup_config_option_car', newCar);
    }

    // 차량 가격 변경
    const carPriceChange = (index: any, val: any) => {
        const newCar = info?.product_pickup_config_option_car.map((item: any, idx: any) => idx === index ? { ...item, car_price: val } : item);
        valueChange('product_pickup_config_option_car', newCar);
    }

    // 차량 제거
    const carItemDel = (index: any) => {
        const newCar = info?.product_pickup_config_option_car.filter((item: any, idx: any) => idx !== index);
        setTitles(newCar.map((item: any) => item.car_name).join(','))
        valueChange('product_pickup_config_option_car', newCar);
    }

    return (
        <>
            <AdminProductConfigItem title='차량' placeholder='옵션값을 입력하세요(쉼표 사용)' value={titles} onChange={(e: any) => carItemChange(e.target.value)} />
            <ArrWrap>
                {info?.product_pickup_config_option_car.length === 0 ?
                    <></> :
                    info?.product_pickup_config_option_car.map((data: any, index: any) => {
                        return (
                            <ArrItemWrap key={`bags-${index}`}>
                                <BasicText>{data?.car_name}</BasicText>
                                <InputWrap>
                                    <input value={numberWithComma(data?.car_price)} onChange={(e: any) => carPriceChange(index, onlyNumber(e))} />
                                    <img onClick={() => carItemDel(index)} src='/img/asset/del.svg'></img>
                                </InputWrap>
                            </ArrItemWrap>
                        )
                    })
                }
            </ArrWrap>
        </>
    )
}

const ArrWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-top: 6px;
    padding: 0 8px 12px;
`;
const ArrItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 6px;
`;
const InputWrap = styled(Row)`
    width: auto;
    gap: 6px;
    justify-content: end;
    & input{
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        padding: 5px;
        width: 80%;
    }
    & img{
        cursor: pointer;
    }
`;