import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Outlet, useLocation } from 'react-router';
import FooterPart from '../part/FooterPart';
import PopupPart from '../../common/part/PopupPart';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';
import HeaderPart from '../part/HeaderPart';
import { addTheme } from '../../../style/addTheme';

export default function LayoutPage() {
    const location = useLocation();
    const companyInfo = useGlobalCompanyInfo();
    const headerRef = useRef<any>(null);
    const footerRef = useRef<any>(null);
    const [height, setHeight] = useState(0);
    const [fHeight,setFHeight] = useState(0);
    const [mt,setMt] = useState(0);
    const [rightBtnState,setRightBtnState] = useState(false);
    const [headerState,setHeaderState] = useState(false);

    // useEffect(()=>{
    //     // marginTop이 없는 페이지
    //     if(location.pathname === '' || location.pathname === '/'){
    //         setMt(0);
    //     }else{
    //         setMt(40);
    //     }
    // },[location.pathname])

    useEffect(()=>{
        if(location.pathname === '/airport' || location.pathname === '/parking'){
            setHeaderState(true);
        }else{
            setHeaderState(false);
        }
    },[location.pathname])

    const getHeight = () =>{
        if (headerRef.current){
            setHeight(headerRef.current.clientHeight); 
        }
        if(footerRef.current){
            setFHeight(footerRef.current.clientHeight); 
        }
    }

    useEffect(()=>{
        getHeight();
        window.addEventListener('resize', getHeight);
        return () => {
            window.removeEventListener('resize', getHeight);
        };
    },[]);

    useEffect(()=>{
        if(companyInfo.config_consultation_visible === '0'){
            setRightBtnState(false);
        }else{
            setRightBtnState(true);
        }
    },[companyInfo.config_consultation_visible]);
    
    return (
        <Wrap>
            <HeaderWrap ref={headerRef} headerState={headerState}>
                <HeaderPart />
            </HeaderWrap>
            <HeaderSpaceWrap headerState={headerState} h={height} />
            <ContentWrap h={height} fh={fHeight} mt={mt}>
                <Outlet />
            </ContentWrap>
            <FooterWrap ref={footerRef}>
                <FooterPart />
            </FooterWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const HeaderWrap = styled.div<{headerState:boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    @media ${addTheme.media.s}{
        display: ${({headerState}) => headerState ? 'none' : 'block'};
    }
`;
const HeaderSpaceWrap = styled.div<{h:any,headerState:boolean}>`
    height: ${({h}) => h ?? 0}px;
    opacity: 0;
    @media ${addTheme.media.s}{
        display: ${({headerState}) => headerState ? 'none' : 'block'};
    }
`;
const ContentWrap = styled.div<{h:any,fh:any,mt:number}>`
    min-height: ${({h,fh}) => `calc(100vh - ${h ?? 0}px - ${fh ?? 0}px)`};
    margin-top: ${({mt}) => mt ?? 0}px;
`;

const FooterWrap = styled.div`
    /* margin-top: 60px; */
`;