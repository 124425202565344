import React from 'react'
import styled from 'styled-components'
import { BtnItem, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { FaAngleRight } from "react-icons/fa6";
import { addTheme } from '../../../style/addTheme';
import { momentFormat } from '../../../utils/format/time';
import { AIRPORT_TYPE, ORDER_STATUS } from '../../../data/airport';
import { useNavigate } from 'react-router';

export default function MyReservationItem({data,type}:any) {
    const navigate = useNavigate();
    const goReview = () =>{
        navigate(`/reviewCreate/${type}/${data.order_pickup_id}`);
    }
    return (
        <Wrap>
            <InnerWrap>
                <RowBetween style={{flexWrap:'wrap'}}>
                    <StateWrap>
                        <BtnItem bg='#000' round={20}>{AIRPORT_TYPE[data?.order_pickup_type]}</BtnItem>
                        <BtnItem bg={ORDER_STATUS[data?.order_pickup_status].bg} round={20}>{ORDER_STATUS[data?.order_pickup_status].name}</BtnItem>
                    </StateWrap>
                    {(data?.order_pickup_status === 3 && data?.review_id === 0) &&
                        <BtnItem bg='#fff' border='1px solid #000' color='#000' round={20} onClick={goReview}>후기 작성</BtnItem>
                    }
                </RowBetween>
                <TextWrap>
                    <TextItemWrap>
                        <BasicText>{data?.order_pickup_depart_location}</BasicText>
                        <FaAngleRight />
                        <BasicText>{data?.order_pickup_arrive_location}</BasicText>
                    </TextItemWrap>
                    <TextItemWrap>
                        <BasicText color='#333' fontSize={14}>{momentFormat('YYYY-MM-DD HH:mm',data?.order_pickup_time_depart_timestamp)} (비행기 {data?.order_pickup_time_depart_ap})</BasicText>
                        <FaAngleRight color='#fff'/>
                        <BasicText color='#333' fontSize={14}>{momentFormat('YYYY-MM-DD HH:mm',data?.order_pickup_time_arrive_timestamp)} (비행기 {data?.order_pickup_time_arrive_ap})</BasicText>
                    </TextItemWrap>
                </TextWrap>
            </InnerWrap>
            <InfoWrap>
                <BasicText fontSize={14} color='#333333'>상세 예약 정보</BasicText>
                <BasicText fontSize={14} color='#333333'>확인하기</BasicText>
            </InfoWrap>
        </Wrap>
    ) 
}

const Wrap = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;
const InnerWrap = styled.div`
    padding: 15px;
`;
const StateWrap = styled(Row)`
    width: auto;
    gap: 12px;
    flex-wrap: wrap;
    &>div{
        font-size: 15px;
    }
`;
const TextWrap = styled.div``;
const TextItemWrap = styled(Row)`
    gap: 12px;
    margin-top: 15px;
    &>svg{
        flex-shrink: 0;
    }
    &:nth-last-of-type(1){
        margin-top: 4px;
    }
    @media ${addTheme.media.sx}{
        gap: 8px;
        &>p{
            width: 49%;
        }
    }
`;
const InfoWrap = styled(RowBetween)`
    background-color: #F8F8F8;
    padding: 4px 20px;
`;
