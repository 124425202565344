import React, { useState } from 'react'
import styled from 'styled-components'
import AirportBtnItem from '../item/AirportBtnItem';
import BasicText from '../../basic/BasicText';
import { BtnItem, Row } from '../../../style/basic/commonStyle';
import ModalAirportPage from '../../modal/page/ModalAirportPage';
import { AIRPORT_TYPE_LIST } from '../../../data/airport';
import ModalAddrPage from '../../modal/page/ModalAddrPage';

export default function AirportInput1Part({info,valueChange,setStep}:any) {
    const [airportOpen,setAirportOpen] = useState(false);
    const [stopoverState,setStopoverState] = useState(false);
    const [addrOpen,setAddrOpen] = useState(false);

    // 경유지 비/활성 초기화
    const stopoverAction = () =>{
        setStopoverState(!stopoverState);
        if(stopoverState){
            valueChange('stopover','');
        }
    }

    const nextAction = () =>{
        if(info?.pickup_depart_location?.address === undefined || info?.pickup_arrive_location?.airplane_name === undefined){
            alert('출발지와 공항을 선택해주세요.');
            return;
        }
        setStep(2);
    }

    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={29} mFontSize={21}>경로를 입력해 주세요.</BasicText>
            <TypeWrap>
                {AIRPORT_TYPE_LIST.map((data:any,index:any)=>{
                    return(
                        <BtnItem key={`type-${index}`} onClick={()=>valueChange('pickup_type',data.value)} bg={info.pickup_type === data.value ? '#000' : '#fff'} round={30} padding='8px 12px' border='1px solid #000' color={info.pickup_type === data.value ? '#fff' : '#000'}>{data.name}</BtnItem>
                    )
                })}
            </TypeWrap>
            <InputWrap>
                <InputItemWrap cr onClick={()=>setAddrOpen(true)}>
                    {/* <input value={info?.pickup_depart_location ?? ''} onChange={(e:any)=>valueChange('pickup_depart_location',e.target.value)} placeholder={'출발지를 입력해주세요'}></input> */}
                    <BasicText color={info?.pickup_depart_location?.address === undefined ? '#CCCCCC' : '#000'}>{info?.pickup_depart_location?.address === undefined ? '출발지를 입력해주세요' : info?.pickup_depart_location?.address}</BasicText>
                </InputItemWrap>
                {stopoverState &&
                    <InputItemWrap h='auto'>
                        <textarea value={info?.pickup_stopover_location} onChange={(e:any)=>valueChange('pickup_stopover_location',e.target.value)} rows={5} placeholder='모든 경유지를 입력해주세요.' />
                    </InputItemWrap>
                }
                <InputItemWrap cr onClick={()=>setAirportOpen(true)}>
                    <BasicText color={info?.pickup_arrive_location?.airplane_name === undefined ? '#CCCCCC' : '#000'}>{info?.pickup_arrive_location?.airplane_name === undefined ? '도착 공항 선택을 선택해주세요' : `${info?.pickup_arrive_location?.airplane_name} ${info?.pickup_arrive_location?.airplane_sub_name}`}</BasicText>
                </InputItemWrap>
            </InputWrap>
            <AddBtnWrap>
                <BtnItem bg='#fff' color='#000' padding='0' onClick={stopoverAction}>
                    <img alt='add' src={stopoverState ? '/img/asset/del.svg':'/img/asset/add.svg'}></img>
                    {stopoverState ? '경유지 삭제' : '경유지 추가'}
                </BtnItem>
            </AddBtnWrap>
            <AirportBtnItem nextAction={nextAction}/>
            <ModalAirportPage open={airportOpen} setOpen={setAirportOpen} valueChange={valueChange}/>
            <ModalAddrPage open={addrOpen} setOpen={setAddrOpen} valueChange={valueChange}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TypeWrap = styled(Row)`
    margin-top: 40px;
    gap:8px;
    flex-wrap: wrap;
`;

const InputWrap = styled(Row)`
    flex-direction: column;
    gap: 10px;
    align-items: start;
    margin-top: 15px;
`;
const InputItemWrap = styled(Row)<{cr?:boolean,h?:string}>`
    background-color: #F5F5F5;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    width: 100%;
    height: ${({h}) => h ?? '52px'};
    padding: 10px 18px;
    cursor: ${({cr}) => cr ? 'pointer' : 'default'};
    &>input{
        width: 100%;
        height: 100%;
        ::placeholder {
            color: #ccc;
        }
    }
    &>textarea{
        width: 100%;
        font-size: 16px;
        ::placeholder {
            color: #ccc;
        }
    }
`;

const AddBtnWrap = styled(Row)`
    margin-top: 15px;
    padding-bottom: 100px;
    &>div{
        gap: 8px;
        img{
            width: 22px;
        }
    }
`;