
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtnItem, AdminWrap, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { Grid } from '@mui/material';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { useNavigate } from 'react-router';
import { ADM_FAQ_CATE, ADM_FAQ_LIST } from '../../data/table';
import { AdminFaqCateListItem, AdminFaqListItem } from '../item/AdminFaqItems';
import { apiAdmGetFaq, apiAdmGetFaqCategory, apiAdmGetFaqCount, apiAdmSetFaqCategoryDel, apiAdmSetFaqDel } from '../../../../api/api_adm_faq';

export default function AdminFaqPage() {
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [faqCateList,setFaqCateList] = useState<any>([]);
    const [cateCountList,setCateCountList] = useState<any>([]);
    const [faqList,setFaqList] = useState<any>([]);
    const [titleTotalReload,setTitleTotalReload] = useState(false);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getAction();
    },[adminUser.admin_id]);

    const getAction = async() =>{
        getFaqCateList();
        getFaqList();
        getFaqCateInnerCount();
    }

    // 카테고리 해당 faq 수
    const getFaqCateInnerCount = async() =>{
        const res = await apiAdmGetFaqCount(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            setCateCountList(res.data);
        }
    }

    // 카테고리 리스트
    const getFaqCateList = async() =>{
        const res = await apiAdmGetFaqCategory(adminUser.admin_token,adminUser.admin_id,1,100);
        if(res.result){
            setFaqCateList(res.data);
        }
    }

    // faq 리스트
    const getFaqList = async() =>{
        const res = await apiAdmGetFaq(adminUser.admin_token,adminUser.admin_id,1,100);
        if(res.result){
            setFaqList(res.data);
        }
    }
    // 카테고리 수정 추가
    const goFaqCateUpdate = (id?:any) =>{
        navigate(`/admin/faqCateUpdate/${id === undefined ? '' : id}`);
    }
    // faq 수정 추가
    const goFaqUpdate = (id?:any) =>{
        navigate(`/admin/faqUpdate/${id === undefined ? '' : id}`);
    }

    // 카테고리 삭제
    const cateDel = async(id:any) =>{
        if(id === undefined) return;
        if(window.confirm('카테고리를 삭제하시겠습니까?')){
            const res = await apiAdmSetFaqCategoryDel(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('카테고리를 삭제했습니다.');
                getAction();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }

    // faq 삭제
    const faqDel = async(id:any)=>{
        if(id === undefined) return;
        if(window.confirm('faq를 삭제하시겠습니까?')){
            const res = await apiAdmSetFaqDel(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('faq를 삭제했습니다.');
                getAction();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 FAQ' part='faq' reload={titleTotalReload}>
                <AdmBtnItem bg={addTheme.admBtn2} onClick={()=>goFaqCateUpdate()}>FAQ 카테고리 등록</AdmBtnItem>
                <AdmBtnItem bg={addTheme.admBtn1} onClick={()=>goFaqUpdate()}>FAQ 등록</AdmBtnItem>
            </AdminPageTotalPart>
            <Grid container={true} spacing={2}>
                <Grid item md={6} xs={12} >
                    <AdminWrap>
                        <TitleWrap>
                            <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ 카테고리 관리</BasicText>
                        </TitleWrap>
                        <TableWrap>
                            <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_FAQ_CATE} minWidth={580}>
                                {faqCateList === undefined || faqCateList.length === 0 ?
                                    <></> :
                                    faqCateList.map((data:any,index:any) =>{
                                        const dataArr = cateCountList.filter((item:any)=>item.faq_category_id === data.faq_category_id)
                                        return(
                                            <AdminFaqCateListItem key={`faq-cate-${index}`} data={data} count={dataArr.length === 0 ? 0 : dataArr[0].faq_count} update={goFaqCateUpdate} del={cateDel}/>
                                        )
                                    })
                                }
                            </AdminTablePart>
                        </TableWrap>
                    </AdminWrap>
                </Grid>
                <Grid item md={6} xs={12} >
                    <AdminWrap>
                        <TitleWrap>
                            <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ 관리</BasicText>
                        </TitleWrap>
                        <TableWrap>
                            <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_FAQ_LIST} minWidth={640}>
                                {faqList === undefined || faqList.length === 0 ?
                                    <></> :
                                    faqList.map((data:any,index:any)=>{
                                        return(
                                            <AdminFaqListItem key={`faq-${index}`} data={data} update={goFaqUpdate} del={faqDel}/>
                                        )
                                    })
                                }
                            </AdminTablePart>
                        </TableWrap>
                    </AdminWrap>
                </Grid>
            </Grid>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;