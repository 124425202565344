import React from 'react'
import AdminProductConfigItem from './AdminProductConfigItem'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdminProductAirportConfigServiceItem({ info, titles, setTitles, valueChange }: any) {

    // 서비스 인풋 변경 시 -> 배열도 변경
    const serviceItemChange = (val: any) => {
        setTitles(val);
        const items = val.split(',');
        const serviceList = info?.product_pickup_config_option_service;
        let newService: any = info?.product_pickup_config_option_service;
        if (items.length > serviceList.length) {
            newService = [...serviceList, {
                "service_id": serviceList.length + 1,
                "service_name": items[items.length - 1],
                "service_price": 0
            }]
        } else if (items.length === serviceList.length) {
            newService = serviceList.map((item: any, idx: any) => ({ ...item, service_name: items[idx] }));
        } else {
            newService = serviceList.filter((item: any) => items.includes(item.service_name));
        }
        valueChange('product_pickup_config_option_service', newService);
    }

    // 서비스 가격 변경
    const servicePriceChange = (index: any, val: any) => {
        const newService = info?.product_pickup_config_option_service.map((item: any, idx: any) => idx === index ? { ...item, service_price: val } : item);
        valueChange('product_pickup_config_option_service', newService);
    }

    // 서비스 제거
    const serviceItemDel = (index: any) => {
        const newService = info?.product_pickup_config_option_service.filter((item: any, idx: any) => idx !== index);
        setTitles(newService.map((item: any) => item.service_name).join(','))
        valueChange('product_pickup_config_option_service', newService);
    }

    return (
        <>
            <AdminProductConfigItem title='편의서비스' placeholder='옵션값을 입력하세요(쉼표 사용)' value={titles} onChange={(e: any) => serviceItemChange(e.target.value)} />
            <ArrWrap>
                {info?.product_pickup_config_option_service.length === 0 ?
                    <></> :
                    info?.product_pickup_config_option_service.map((data: any, index: any) => {
                        return (
                            <ArrItemWrap key={`bags-${index}`}>
                                <BasicText>{data?.service_name}</BasicText>
                                <InputWrap>
                                    <input value={numberWithComma(data?.service_price)} onChange={(e: any) => servicePriceChange(index, onlyNumber(e))} />
                                    <img onClick={() => serviceItemDel(index)} src='/img/asset/del.svg'></img>
                                </InputWrap>
                            </ArrItemWrap>
                        )
                    })
                }
            </ArrWrap>
        </>
    )
}

const ArrWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-top: 6px;
    padding: 0 8px 12px;
`;
const ArrItemWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 6px;
`;
const InputWrap = styled(Row)`
    width: auto;
    gap: 6px;
    justify-content: end;
    & input{
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        padding: 5px;
        width: 80%;
    }
    & img{
        cursor: pointer;
    }
`;