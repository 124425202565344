import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import { momentFormat } from '../../../../utils/format/time';
import { useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { addTheme } from '../../../../style/addTheme';
import { apiGetAdminMemberDetail, apiSetAdminMemberMemo } from '../../../../api/api_adm_member';
import { SIGN_TYPE } from '../../../../data/airport';

export default function AdminMemberDetailPage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const [info,setInfo] = useState({
        "user_id": 0,
        "user_sign_type": "",
        "user_name": "",
        "user_phone_number": " ",
        "user_email": " ",
        "user_timestamp": 0,
        "user_last_timestamp": 0,
        "user_memo": ""
    });

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getInfo();
    },[id,adminUser.admin_id])

    const getInfo = async() =>{
        const res = await apiGetAdminMemberDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data);
        }
    }

    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    const changeAction = async() =>{
        const res = await apiSetAdminMemberMemo(adminUser.admin_token,adminUser.admin_id,id,info?.user_memo);
        if(res.result){
            alert('변경되었습니다.');
        }
    }
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>회원 정보</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <ContentWrap gap={15}>
                                <ItemWrap pWidth={140}>
                                    <BasicText>소셜</BasicText>
                                    <InputWrap>
                                        <BasicText>{SIGN_TYPE[info?.user_sign_type]}</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>이름</BasicText>
                                    <InputWrap>
                                        <BasicText>{info?.user_name}</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>전화번호</BasicText>
                                    <InputWrap>
                                        <BasicText>{info?.user_phone_number ?? ''}</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>이메일</BasicText>
                                    <InputWrap>
                                        <BasicText>{info?.user_email ?? ''}</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>가입일</BasicText>
                                    <InputWrap>
                                        <BasicText>{momentFormat('YYYY.MM.DD HH:mm:ss',info?.user_timestamp)}</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>최근접속일</BasicText>
                                    <InputWrap>
                                        <BasicText>{momentFormat('YYYY.MM.DD HH:mm:ss',info?.user_last_timestamp)}</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140} align='start'>
                                    <BasicText>메모</BasicText>
                                    <InputWrap>
                                        <textarea rows={8} value={info?.user_memo ?? ''} onChange={(e:any)=>valueChange('user_memo',e.target.value)} placeholder='고객과 관련된 메모를 입력해주세요.'/>
                                    </InputWrap>
                                </ItemWrap>
                            </ContentWrap>
                        </Grid>
                    </Grid>
                    <BtnWrap>
                        <BtnItem bg={addTheme.infoChangeBtnBg} onClick={changeAction}>정보 변경</BtnItem>
                    </BtnWrap>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 35px;
`;

const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 30px;
`;