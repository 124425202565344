import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid, MenuItem } from '@mui/material';
import SelectItem from '../../../common/item/SelectItem';
import { REVIEW_TYPE_LIST } from '../../../../data/airport';
import { addTheme } from '../../../../style/addTheme';
import { apiSetAdminReviewCreate } from '../../../../api/api_adm_review';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';

export default function AdminReviewCreatePage() {
    const adminUser = useAdminUser();
    const fileRef = useRef<any>(null);
    const [info, setInfo] = useState({
        order_type: '1',
        review_title: '',
        review_rate: '5',
        review_content: '',
        review_user_name: '',
        review_image: '',
    })

    const valueChange = (name: string, val: any) => {
        setInfo((obj: any) => ({
            ...obj,
            [name]: val,
        }))
    }

    const fileTrigger = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    }

    const fileSelect = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result;
                valueChange('review_image', result);
            };

            reader.readAsDataURL(file);
        }
    }

    const create = async() =>{
        const res = await apiSetAdminReviewCreate(adminUser.admin_token,adminUser.admin_id,info.order_type,info.review_title,info.review_rate,info.review_content,info.review_user_name,info.review_image);
        if(res.result){
            alert('등록 되었습니다.');
            window.location.reload();
        }else{
            alert(res.error);
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>후기 등록</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <ContentWrap gap={15}>
                                <ItemWrap pWidth={140}>
                                    <BasicText>이름</BasicText>
                                    <InputWrap>
                                        <input value={info?.review_user_name} onChange={(e: any) => valueChange('review_user_name', e.target.value)} placeholder='이름을 입력해주세요.'></input>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140}>
                                    <BasicText>상품</BasicText>
                                    <InputWrap padding='0'>
                                        <SelectItem value={info?.order_type} setValue={(val: any) => valueChange('order_type', val)} none='no'>
                                            {REVIEW_TYPE_LIST.map((data: any, index: any) => {
                                                return (
                                                    <MenuItem key={`type-${index}`} value={data.value}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </SelectItem>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={140} align='start'>
                                    <BasicText marginTop={1}>후기</BasicText>
                                    <div style={{ width: '100%' }}>
                                        <InputWrap>
                                            <textarea rows={6} value={info.review_content} onChange={(e:any)=>valueChange('review_content',e.target.value)}></textarea>
                                        </InputWrap>
                                        <InputWrap margin='15px 0 0'>
                                            <BasicText onClick={fileTrigger} cr={'pointer'}>이미지를 등록해주세요.</BasicText>
                                            <input ref={fileRef} type='file' accept='image/*' onChange={fileSelect} />
                                        </InputWrap>
                                        <ImgWrap>
                                            {info.review_image !== '' &&
                                                <img src={info.review_image}></img>
                                            }
                                        </ImgWrap>
                                    </div>
                                </ItemWrap>
                            </ContentWrap>
                        </Grid>
                    </Grid>
                    <BtnWrap>
                        <BtnItem bg={addTheme.infoChangeBtnBg} onClick={create}>후기 등록</BtnItem>
                    </BtnWrap>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 35px;
`;

const ImgWrap = styled.div`
    margin-top: 20px;
    & img{
        max-width: 100%;
    }
`;

const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 30px;
`;