import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MyReviewNoListPart from '../part/MyReviewNoListPart';
import { Center, Row } from '../../../style/basic/commonStyle';
import HomeReviewItem from '../../home/item/HomeReviewItem';
import { apiGetMyReviewList } from '../../../api/api_mypage';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { Pagination } from '@mui/material';

export default function MyReviewPage() {
    const userInfo = useUserInfo();
    const [list, setList] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        const res = await apiGetMyReviewList(userInfo.token, userInfo.user_id, page, 10);
        if (res.result) {
            setList(res.data);
            setTotal(res.total);
        }
    }

    const pageChange = (p:any) =>{
        setPage(p)
    }
    return (
        <Wrap>
            {list.length === 0 ?
                <MyReviewNoListPart /> :
                <>
                    <ContentWrap>
                        {list.map((data: any, index: any) => {
                            return (
                                <HomeReviewItem key={`my-review-${index}`} data={data} />
                            )
                        })}
                    </ContentWrap>
                    <PaginWrap>
                        <Pagination shape="rounded" page={typeof page === 'string' ? Number(page) : page} count={total} onChange={(e: any, page: any) => pageChange(page)} sx={{ '.MuiPagination-ul li button': { color: '#7d7d7d' }, '.MuiPagination-ul li .Mui-selected': { color: '#111' } }} color='secondary' />
                    </PaginWrap>
                </>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
`;
const PaginWrap = styled(Center)`
    margin-top: 30px;
`;