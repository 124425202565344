import React from 'react'
import styled from 'styled-components'
import ServicePart01 from '../part/ServicePart01';
import ServicePart02 from '../part/ServicePart02';
import ServicePart03 from '../part/ServicePart03';
import ServiceTitleItem from '../item/ServiceTitleItem';
import ServiceParkingFacilityPart from '../part/ServiceParkingFacilityPart';
import ServiceSlidePart from '../part/ServiceSlidePart';
import { SwiperSlide } from 'swiper/react';
import ServiceSlideInItem from '../item/ServiceSlideInItem';
import ServiceSlideOutItem from '../item/ServiceSlideOutItem';
import FaqPart from '../part/FaqPart';
import { Center } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import ServiceBtnPart from '../part/ServiceBtnPart';

const PART02 = [
    {title: '저렴한 비용', img: '/img/asset/service/service1.png', content1: '인천공항 주차장 대비 <strong>60% 저렴!</strong>', content2: '실내주차를 일 5천원(6일차 기준)으로 이용해보세요!'},
    {title: '안전한 주차', img: '/img/asset/service/service2.png', content1: '100% 실내 주차, 24시간 80여개의 CCTV로', content2: '안전한 주차 서비스를 제공합니다!'},
    {title: '편리한 픽업', img: '/img/asset/service/service3.png', content1: '언제든 공항에서 에어드라이브 주차장까지', content2: '무료로 픽업차량을 빠르고 편하게 이용해보세요!'},
]

const STEP_IN = [
    {step: '01', title: '예약완료 후 알림톡 받기', desc1: '알림톡을 받지 못했다면', desc2: '카톡 또는 전화로 문의주세요.'},
    {step: '02', title: '에어드라이브 주차센터 도착', desc1: '셀프도착 후 차키는 가져가세요.', },
    {step: '03', title: '셔틀버스 탑승 및 공항도착', desc1: '수시운행되는 대형밴으로 빠르게 이동',},
]
const STEP_OUT = [
    {title1: '공항 대리 픽업', title2: '서비스가 필요할 때!', desc1: '#인천공항 #김포공항 전국에서 다 가능', },
    {title1: '주차요금, 유류비,', title2: '운전피로가 걱정될 때!', desc1: '30% 더 저렴한 실내 주차', },
    {title1: '수화물이 많아서', title2: '걱정이 될 때!', desc1: '#인천공항 #김포공항 전국에서 다 가능',},
]

export default function ServiceParkingPage() {
    const navigate = useNavigate();

    return (
        <Wrap>
            <ServicePart01 text1='집 앞부터 공항까지 합리적인 여행의 파트너' text2='100% 실내 전문' text3='안전한 차량 주차,' span1='에어드라이브' img='/img/asset/service_parking.png' />
            <ServicePart02 mt='140px' title='Why AirDrive?' subTitle={['여행의 시작을 에어드라이브와 함께 편안하게!','여행 중에는 귀하의 차량을 안전하게!']} content={PART02} />
            <ServiceTitleItem mt='140px' title='서비스 안내' title1='<span>요금은 낮게,혜택은 가득한</span> 합리적인' title2='주차 대행 서비스, 에어드라이브를 이용해보세요.'/>
            <ServicePart03 />
            <ServiceTitleItem mt='140px' title='시설 안내' title1='<strong>고객님의 차량을 안전하게 보관하기 위한</strong>' title2='<strong>에어드라이브의 섬세한 준비들을 확인하세요!</strong>'/>
            <ServiceParkingFacilityPart />
            <ServiceSlidePart title1='편한 여행을 위한' title2='#입차 방법 안내' mt='140px'>
                {STEP_IN.map((data:any,index:any)=>{
                    return(
                        <SwiperSlide key={`in-${index}`}>
                            <ServiceSlideInItem data={data} />
                        </SwiperSlide>
                    )
                })}
            </ServiceSlidePart>
            <ServiceSlidePart title1='편한 귀가를 위한' title2='#출차 방법 안내' bg='#262626'>
                {STEP_OUT.map((data:any,index:any)=>{
                    return(
                        <SwiperSlide key={`out-${index}`}>
                            <ServiceSlideOutItem data={data} />
                        </SwiperSlide>
                    )
                })}
            </ServiceSlidePart>
            <FaqPart type='주차'/>
            <BtnWrap>
                <BtnItem onClick={()=>navigate('/parking')}>주차대행 예약하기</BtnItem>
            </BtnWrap>
            <ServiceBtnPart reservationAction={()=>navigate('/parking')}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnWrap = styled(Center)`
    margin: 80px 0 120px;
`;

const BtnItem = styled(Center)`
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    height: 42px;
    width: 260px;
    cursor: pointer;
`;