import React, { useState } from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useNavigate } from 'react-router';
import ModalOrderTypePage from '../../modal/page/ModalOrderTypePage';

export default function MyReservationNoListPart({type}:any) {
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);
    const goOrder = () =>{
        navigate(type === 0 ? '/airport' : '/parking');
    }
    return (
        <Wrap>
            <BasicText align='center' fontSize={20} mFontSize={18}>예약 정보가 없습니다.</BasicText>
            <BasicText align='center' fontSize={20} mFontSize={18}>지금 바로 견적받아보세요.</BasicText>
            <BtnItem onClick={goOrder}>견적 확인하기</BtnItem>
            <ModalOrderTypePage open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnItem = styled(Center)`
    max-width: 320px;
    width: 80%;
    margin: 25px auto 0;
    height: 48px;
    color: #fff;
    background-color: #181818;
    border-radius: 8px;
    cursor: pointer;
`;