import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { ALARM_LIST } from '../../data/table'
import { momentFormat } from '../../../../utils/format/time'
import { REVIEW_TYPE } from '../../../../data/airport'
import { BtnItem } from '../../../../style/basic/commonStyle'

export default function AdminAlarmTalkListItem({data}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[0].align}>{data.alarm_talk_id}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[1].align}>{data.user_name}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[2].align}>{REVIEW_TYPE[data.order_type]}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[3].align}>{data.user_phone_number}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[4].align}>{data.alarm_talk_depart_location}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[5].align}>{data.alarm_talk_arrive_location}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[6].align}>{momentFormat('YYYY.MM.DD HH:mm',data.order_timestamp)}</TableCell>
            <TableCell sx={{padding:'16px 10px'}} align={ALARM_LIST[7].align}>{data.alarm_talk_log_text}</TableCell>
        </TableRow>
    )
}
