import { axiosConfigInstance } from "./config";

// 자주하는 질문 리스트 가져오기
export const apiGetFaqList = async(faq_category_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/faq').post('',{
            method: 'getFaqList',
            params:{
                faq_category_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}


// 자주하는질문 카테고리 리스트 가져오기
export const apiGetFaqCate = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/faq').post('',{
            method: 'getFaqCategoryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
