import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { PRODUCT_LIST } from '../../data/table'
import { momentFormat } from '../../../../utils/format/time'
import { BtnItem, Center } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme'
import { PRODUCT_ACTIVE } from '../../../../data/airport'
import { numberWithComma } from '../../../../utils/format/number'

export default function AdminProductListItem({data,goDetail}:any) {
    return (
        <TableRow>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[0].align}>{data.product_config_id}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[1].align}>{data.product_config_name}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[2].align}>{numberWithComma(data.product_pickup_config_basic_price)}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[3].align}>{momentFormat('YYYY.MM.DD HH:mm', data.product_config_reg_timestamp)}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[4].align}>{momentFormat('YYYY.MM.DD HH:mm', data.product_config_edit_timestamp)}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[5].align}>{PRODUCT_ACTIVE[data?.product_config_active]}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={PRODUCT_LIST[6].align}>
                <Center style={{ gap: '8px' }}>
                    <BtnItem onClick={goDetail} bg={addTheme.detailBtnBg} round={35} padding='7px 16px'>상세</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
