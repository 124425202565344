import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { BtnItem, Center } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme'
import { REVIEW_LIST } from '../../data/table'
import BasicText from '../../../basic/BasicText'
import { momentFormat } from '../../../../utils/format/time'
import { REVIEW_TYPE } from '../../../../data/airport'

export default function AdminReviewListItem({ data, delAction,goDetail }: any) {
    return (
        <TableRow>
            <TableCell sx={{ padding: '10px' }} align={REVIEW_LIST[0].align}>{data?.review_id}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={REVIEW_LIST[1].align}>{data?.user_name}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={REVIEW_LIST[2].align}>{REVIEW_TYPE[data?.order_type] }</TableCell>
            <TableCell sx={{ padding: '10px' }} align={REVIEW_LIST[3].align}>
                <BasicText line={1} align='center'>{data?.review_content}</BasicText>
            </TableCell>
            <TableCell sx={{ padding: '10px' }} align={REVIEW_LIST[4].align}>{momentFormat('YYYY-MM-DD HH:mm',data?.review_timestamp)}</TableCell>
            <TableCell sx={{ padding: '10px' }} align={REVIEW_LIST[5].align}>
                <Center style={{ gap: '8px' }}>
                    <BtnItem bg={addTheme.detailBtnBg} round={35} padding='7px 16px' onClick={()=>goDetail(data?.review_id)}>상세</BtnItem>
                    <BtnItem bg={addTheme.delBtnBg} round={35} padding='7px 16px' onClick={()=>delAction(data?.review_id)}>삭제</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
