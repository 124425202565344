import React from 'react'
import styled from 'styled-components'
import { AdminWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import AdminProductAirportConfigPart from '../part/AdminProductAirportConfigPart';
import AdminProductAirportLocationPart from '../part/AdminProductAirportLocationPart';

export default function AdminProductAirportPage() {
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>공항 이동 상품</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={0}>
                        <Grid item md={5.5} xs={12}>
                            <AdminProductAirportConfigPart />
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <BorderWrap />
                        </Grid>
                        <Grid item md={5.5} xs={12}>
                            <AdminProductAirportLocationPart />
                        </Grid>
                    </Grid>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 35px;
`;
const BorderWrap = styled.div`
    width: 50%;
    height: 100%;
    border-right: 1px solid #ddd;
`;