import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { BtnItem, Center } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme'
import { TABLE_AIRPORT } from '../../data/table'
import { AIRPORT_TYPE2, ORDER_STATUS, PARKING_TYPE } from '../../../../data/airport'
import { momentFormat } from '../../../../utils/format/time'

export default function AdminParkingListitem({data,goDetail}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[0].align}>{data?.order_parking_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[1].align}>{data?.user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[2].align}>{PARKING_TYPE[data?.order_parking_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[3].align}>{data?.user_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[4].align}>{data?.order_parking_time_depart_airplane}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[5].align}>{data?.order_parking_time_arrive_airplane}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[6].align}>{momentFormat('YYYY-MM-DD HH:mm',data?.order_parking_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[7].align}>{ORDER_STATUS[data?.order_parking_status].name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={TABLE_AIRPORT[8].align}>
                <Center style={{gap:'8px'}}>
                    <BtnItem bg={addTheme.detailBtnBg} round={35} padding='7px 16px' onClick={()=>goDetail(data?.order_parking_id)}>상세</BtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
