import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useMediaQuery } from '@mui/material';

export default function ServiceSlidePart({ title1,title2,children,bg,mt }: any) {
    const mediaQuery = useMediaQuery(addTheme.media.m);
    return (
        <Wrap bg={bg} mt={mt}>
            <MaxWidthWrap padding={mediaQuery ? '0' : '0 15px'}>
                <InnerWrap>
                    <TextWrap>
                        <BasicText color={bg === undefined ? '#000' : '#fff'} align={mediaQuery ? 'center' : 'left'} fontWeight={700} fontSize={50} mFontSize={30}>{title1}</BasicText>
                        <BasicText color={bg === undefined ? '#000' : '#fff'} align={mediaQuery ? 'center' : 'left'} fontWeight={700} fontSize={50} mFontSize={30}>{title2}</BasicText>
                    </TextWrap>
                    <SlideWrap>
                        <Swiper
                            slidesPerView={mediaQuery ? 1.2 : 2.3}
                            spaceBetween={15}
                            >
                                {children}
                        </Swiper>
                    </SlideWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{bg?:string,mt?:string}>`
    background-color: ${({bg}) => bg ?? '#F6F6F6'};
    padding: 120px 0;
    margin-top: ${({mt}) => mt ?? '0'};
`;

const InnerWrap = styled(RowBetween)`
    gap: 70px;
    align-items: start;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 30px;
        &>div{
            width: 100%;
        }
    }
`;

const TextWrap = styled.div`
    width: calc(35% - 35px);
    padding: 0 15px;
`;
const SlideWrap = styled.div`
    width: calc(65% - 35px);
    @media ${addTheme.media.m}{
        padding-left: 12%;
    }
`;