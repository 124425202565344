import React, { useState } from 'react'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { momentFormat } from '../../../utils/format/time';
import { AIRPORT } from '../../../data/airport';
import ModalAirportPage from '../../modal/page/ModalAirportPage';
import AirportBtnItem from '../../airport/item/AirportBtnItem';
import ModalDatePage from '../../modal/page/ModalDatePage';
import ModalTimePage from '../../modal/page/ModalTimePage';

export default function ParkingInput1Part({ info, valueChange, setStep }: any) {
    const [dateOpen, setDateOpen] = useState({
        name: '출발',
        open: false,
    });
    const [airportOpen, setAirportOpen] = useState({
        name: '출발',
        open: false,
    });
    const [timeOpen, setTimeOpen] = useState({
        name: '출발',
        open: false,
    });

    // 복귀날짜
    const endDateClickAction = () =>{
        if(info?.parking_time_depart_timestamp){
            setDateOpen({name:'복귀',open:true})
        }else{
            alert('출발날짜와 시간을 먼저 입력해주세요.');
        }
    }

    const nextAction = () =>{
        if(info?.parking_time_depart_timestamp === ''){
            alert('출발날짜와 주차장 도착 시간을 입력해주세요.')
            return;
        }
        if(info?.parking_time_depart_flight === '' || info?.parking_time_arrive_flight === ''){
            alert('항공편을 입력해주세요.');
            return;
        }
        if(info?.parking_time_depart_airplane?.airplane_name === undefined){
            alert('출국 터미널을 선택해주세요.');
            return;
        }
        if(info?.parking_time_arrive_timestamp === ''){
            alert('복귀날짜와 비행기 도착 시간을 입력해주세요.');
            return;
        }
        if(info?.parking_time_arrive_airplane?.airplane_name === undefined){
            alert('입국 터미널을 선택해주세요.');
            return;
        }
        setStep(2);
    }
    
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={29} mFontSize={21}>일정을 입력해 주세요.</BasicText>
            <InputWrap>
                <InputItemWrap cr onClick={()=>setDateOpen({name:'출발',open:true})}>
                    <BasicText color={info?.parking_time_depart_timestamp === '' ? '#CCCCCC' : '#000'}>{info?.parking_time_depart_timestamp === '' ? '출발날짜와 주차장 도착 시간을 입력해주세요.' : `${momentFormat('YYYY-MM-DD HH:mm', info?.parking_time_depart_timestamp)}`} </BasicText>
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_time_depart_flight ?? ''} onChange={(e: any) => valueChange('parking_time_depart_flight', e.target.value)} placeholder={`항공편을 입력해주세요`} />
                </InputItemWrap>
                <InputItemWrap cr onClick={() => setAirportOpen({ name: '출국', open: true })} >
                    <BasicText color={info?.parking_time_depart_airplane?.airplane_name === undefined ? '#CCCCCC' : '#000'}>{info?.parking_time_depart_airplane?.airplane_name === undefined ? '출국 터미널을 선택해주세요' : `${info?.parking_time_depart_airplane?.airplane_name} ${info?.parking_time_depart_airplane?.airplane_sub_name}`}</BasicText>
                </InputItemWrap>
                <InputItemWrap mt='12px' cr onClick={endDateClickAction}>
                    <BasicText color={info?.parking_time_arrive_timestamp === '' ? '#CCCCCC' : '#000'}>{info?.parking_time_arrive_timestamp === '' ? '복귀날짜와 비행기 도착 시간을 입력해주세요.' : `${momentFormat('YYYY-MM-DD HH:mm', info?.parking_time_arrive_timestamp)}`} </BasicText>
                </InputItemWrap>
                <InputItemWrap>
                    <input value={info?.parking_time_arrive_flight ?? ''} onChange={(e: any) => valueChange('parking_time_arrive_flight', e.target.value)} placeholder={`항공편을 입력해주세요`}></input>
                </InputItemWrap>
                <InputItemWrap cr onClick={() => setAirportOpen({ name: '입국', open: true })}>
                    <BasicText color={info?.parking_time_arrive_airplane?.airplane_name === undefined ? '#CCCCCC' : '#000'}>{info?.parking_time_arrive_airplane?.airplane_name === undefined ? '입국 터미널을 선택해주세요' : `${info?.parking_time_arrive_airplane?.airplane_name} ${info?.parking_time_arrive_airplane?.airplane_sub_name}`}</BasicText>
                </InputItemWrap>
                <DecoWrap>
                    <img src='/img/asset/up.png'></img>
                    <img src='/img/asset/down.png'></img>
                </DecoWrap>
            </InputWrap>
            <BtnWrap>
                <AirportBtnItem nextAction={nextAction}/>
            </BtnWrap>
            <ModalAirportPage name={airportOpen.name} open={airportOpen.open} setOpen={setAirportOpen} valueChange={valueChange} type='parking' />
            <ModalDatePage title={dateOpen.name} open={dateOpen.open} setOpen={setDateOpen} valueChange={valueChange} info={info} setTimeOpen={setTimeOpen} type='parking'/>
            <ModalTimePage title={timeOpen.name} open={timeOpen.open} setOpen={setTimeOpen} valueChange={valueChange} info={info} type='parking' />
        </Wrap>
    )
}

const Wrap = styled.div``;

const InputWrap = styled(Row)`
    margin-top: 40px;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    position: relative;
    padding-left: 35px;
`;

const DecoWrap = styled(RowBetween)`
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 18px;
    &>img{
        width: 18px;
        position: relative;
    }
    &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 1px;
        height: calc(100% - 36px);
        border: 1px dashed #D4D4D4;
    }
`;
const InputItemWrap = styled(Row) <{ cr?: boolean, h?: string, mt?: string }>`
    background-color: #F5F5F5;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    width: 100%;
    height: ${({ h }) => h ?? '52px'};
    padding: 10px 18px;
    cursor: ${({ cr }) => cr ? 'pointer' : 'default'};
    margin-top: ${({ mt }) => mt ?? '0px'};
    &>input{
        width: 100%;
        height: 100%;
        ::placeholder {
            color: #ccc;
        }
    }
    &>textarea{
        width: 100%;
        font-size: 16px;
        ::placeholder {
            color: #ccc;
        }
    }
`;

const BtnWrap = styled.div`
    margin-top: 80px;
`;