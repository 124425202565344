import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetFaqCateDetail, apiAdmSetFaqCategory, apiAdmSetFaqCategoryUpdate } from '../../../../api/api_adm_faq';
import BasicText from '../../../basic/BasicText';
import { AdmBtnItem, AdminWrap, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdminFaqCateUpdatePage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const [type,setType] = useState('등록');
    const [info,setInfo] = useState({
        faq_category_id: 1,
        faq_category_order: '',
        faq_category_title: '',
    })

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(id === undefined){
            setType('등록');
        }else{
            setType('수정');
            getInfo();
        }
    },[adminUser.admin_id,id]);

    // 정보 가져오기
    const getInfo = async() =>{
        const res = await apiAdmGetFaqCateDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data);
        }
    }

    // value 변경
    const valueChange = (name:string,value:any) =>{
        setInfo((obj:any)=>({...obj,[name]:value}));
    }

    // 수정, 추가
    const btnAction = async() =>{
        if(info.faq_category_title === ''){
            alert('카테고리 명을 입력해주세요.')
            return;
        }
        if(type === '등록'){
            const res = await apiAdmSetFaqCategory(adminUser.admin_token,adminUser.admin_id,info);
            if(res.result){
                alert('카테고리가 등록 되었습니다.');
                location.reload();
            }else{
                alert(res.error);
            }
        }else if(type === '수정'){
            if(window.confirm('카테고리를 수정하시겠습니까?')){
                const res = await apiAdmSetFaqCategoryUpdate(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert('수정 되었습니다.');
                    location.reload();
                }else{
                    alert(res.error);
                }
            }
        }
    }
    
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ 카테고리 {type}</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>카테고리 명</BasicText>
                            <InputWrap>
                                <input value={info.faq_category_title} onChange={(e:any)=>valueChange('faq_category_title',e.target.value)} maxLength={150}/>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>순서</BasicText>
                            <InputWrap>
                                <input value={info.faq_category_order} onChange={(e:any)=>valueChange('faq_category_order',onlyNumber(e))} maxLength={1000} />
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                    <Row style={{marginTop:'20px'}}>
                        <AdmBtnItem bg={addTheme.admBtn2} padding='14px 25px' onClick={btnAction}>카테고리 {type}</AdmBtnItem>
                    </Row>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;