import React from 'react'
import styled from 'styled-components'
import { AdminWrap, BtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import { addTheme } from '../../../../style/addTheme';

export default function AdminAlarmTalkUpdatePage() {
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>알림톡 변경</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <ContentWrap gap={15}>
                                <ItemWrap pWidth={140} align='start'>
                                    <BasicText marginTop={1}>메시지 템플릿</BasicText>
                                    <InputWrap>
                                        <textarea rows={8}></textarea>
                                    </InputWrap>
                                </ItemWrap>
                            </ContentWrap>
                        </Grid>
                    </Grid>
                    <BtnWrap>
                        <BtnItem bg={addTheme.infoChangeBtnBg}>정보 변경</BtnItem>
                    </BtnWrap>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 35px;
`;
const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 30px;
`;
