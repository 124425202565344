import React,{useEffect, useState} from 'react'
import { useLocation } from 'react-router'
import { apiVisit } from '../../../api/api_main';
import { useUserInfo } from './useUserInfo';

export default function ScrollToTop() {
  const {pathname} = useLocation();
  const [visit,setVisit] = useState(false);
  const userInfo = useUserInfo();

  useEffect(() => {
    if(pathname.includes('/internet')){
      const linkArr = pathname.split('/').filter((data:any)=>data);
      if(linkArr[0] === 'internet') return;
    };
    window.scrollTo(0,0);
  }, [pathname])

  useEffect(()=>{
    if(visit) return;
    if(pathname.includes('admin')) return;
    visitAction();
  },[pathname,visit,userInfo.user_id]);

  const visitAction = async() =>{
    const res = await apiVisit(userInfo.user_id);
    if(res.result){
      setVisit(true);
    }
  }
  
  return null;
}
