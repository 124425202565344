import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import BasicText from '../../basic/BasicText';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import HomePart03Item from '../item/HomePart03Item';

const AIRPORT_LIST = [
    {title1: '공항 대리 픽업', title2: '서비스가 필요할 때!', desc: '#인천공항 #김포공항 전국에서 다 가능'},
    {title1: '주차요금, 유류비,', title2: '운전피로가 걱정될 때!', desc: '30% 더 저렴한 실내 주차'},
    {title1: '수화물이 많아서', title2: '걱정이 될 때!', desc: '#인천공항 #김포공항 전국에서 다 가능'},
]

const PARKING_LIST = [
    {title1: '안전한 주차대행', title2: '서비스가 필요할 때!', desc: '#인천공항 #김포공항 전국에서 다 가능'},
    {title1: '인천공항 주차요금이', title2: '비싸서 고민될 때', desc: '30% 더 저렴한 실내 주차'},
    {title1: '수화물이 많아서', title2: '걱정이 될 때!', desc: '#인천공항 #김포공항 전국에서 다 가능'},
]

export default function HomePart03() {
    const mediaQuery = useMediaQuery(addTheme.media.m);
    const [btnActive, setBtnActive] = useState(1);
    const [list,setList] = useState(AIRPORT_LIST);

    useEffect(()=>{
        if(btnActive === 1){
            setList(AIRPORT_LIST);
            return;
        }
        if(btnActive === 2){
            setList(PARKING_LIST);
        }
    },[btnActive])

    return (
        <Wrap>
            <MaxWidthWrap padding={mediaQuery ? '0' : '0 15px'}>
                <InnerWrap>
                    <TextWrap>
                        <BasicText color={'#fff'} fontWeight={700} fontSize={42} mFontSize={28} align={mediaQuery ? 'center' : 'left'}>편안 여행을 위한</BasicText>
                        <BasicText color={'#fff'} fontWeight={700} fontSize={42} mFontSize={28} align={mediaQuery ? 'center' : 'left'}>#에어드라이브</BasicText>
                        <BtnWrap>
                            <BtnItem $active={btnActive === 1} onClick={()=>setBtnActive(1)}>#공항이동부터</BtnItem>
                            <BtnItem $active={btnActive === 2} onClick={()=>setBtnActive(2)}>#주차대행까지</BtnItem>
                        </BtnWrap>
                    </TextWrap>
                    <SlideWrap>
                        <Swiper
                            slidesPerView={mediaQuery ? 1.2 : 2.3}
                            spaceBetween={15}
                        >
                            {list.map((data:any,index:any)=>{
                                return(
                                    <SwiperSlide key={`air-${index}`}>
                                        <HomePart03Item data={data} btnActive={btnActive} />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </SlideWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 150px 0;
    background-color: #272727;
    margin-top: 90px;
    @media ${addTheme.media.m}{
        padding: 50px 0;
    }
`;

const InnerWrap = styled(RowBetween)`
    gap: 70px;
    align-items: start;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 20px;
        &>div{
            width: 100%;
        }
    }
`;

const TextWrap = styled.div`
    width: calc(35% - 35px);
    @media ${addTheme.media.m}{
        padding: 0 15px;
    }
`;
const BtnWrap = styled.div`
    margin-top: 35px;
    @media ${addTheme.media.m}{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        gap: 20px;
        &>div{
            width: 138px;
            height: 44px;
        }
    }
`;
const BtnItem = styled(Center) <{ $active?: boolean }>`
    margin-top: 10px;
    border: 1px solid #fff;
    color: ${({ $active }) => $active ? '#272727' : '#fff'};
    background-color: ${({ $active }) => $active ? '#fff' : '#272727'};
    width: 165px;
    height: 46px;
    border-radius: 58px;
    cursor: pointer;
`;

const SlideWrap = styled.div`
    width: calc(65% - 35px);
    @media ${addTheme.media.m}{
        padding-left: 12%;
    }
`;