import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BtnItem, ContentWrap, InputWrap, ItemWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminProductConfigItem from '../item/AdminProductConfigItem';
import { apiGetAdminParkingConfig, apiGetAdminParkingOptionConfig, apiSetAdminParkingConfigUpdate } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';
import { addTheme } from '../../../../style/addTheme';
import AdminProductParkingConfigPayItem from '../item/AdminProductParkingConfigPayItem';
import AdminProductParkingConfigTypeItem from '../item/AdminProductParkingConfigTypeItem';

export default function AdminProductParkingConfigPart() {
    const adminUser = useAdminUser();
    const [payItems, setPayItems] = useState('');
    const [typeItems,setTypeItems] = useState('');
    const [info, setInfo] = useState({
        "order_conf_pk_id": 0,
        "airplane_id": 0,
        "order_conf_pk_default_day": 0,
        "order_conf_pk_default_price": 0,
        "order_conf_pk_add_price": 0,
        "order_conf_pk_option_day": 0,
        "order_conf_pk_option_price": 0,
        "option_payment": [],
        "option_parking": [],
    });

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        getInfo();
        
    }, [adminUser.admin_id])

    const getInfo = async () => {
        const res = await apiGetAdminParkingConfig(adminUser.admin_token, adminUser.admin_id, '1');
        if (res.result) {
            setInfo({
                ...res.data,
                option_payment: [],
                option_parking: [],
            });
            getOption();
        }
    }

    const getOption = async() =>{
        const res = await apiGetAdminParkingOptionConfig(adminUser.admin_token, adminUser.admin_id);
        if(res.result){
            setInfo((obj:any)=>({
                ...obj,
                option_payment: res.data.product_parking_config_option_payment,
                option_parking: res.data.product_parking_config_option_parking,
            }))
            setPayItems(res.data.product_parking_config_option_payment.map((item: any) => item.payment_name).join(','));
            setTypeItems(res.data.product_parking_config_option_parking.map((item:any)=> item.parking_name).join(','));
        }   
    }
    
    const valueChange = (name: string, val: any) => {
        setInfo((obj: any) => ({
            ...obj,
            [name]: val,
        }))
    }

    // 변경
    const update = async () => {
        const newInfo = {
            ...info,
            default_day: info.order_conf_pk_default_day,
            default_price: info.order_conf_pk_default_price,
            add_price: info.order_conf_pk_add_price,
            option_day: info.order_conf_pk_option_day,
            option_price: info.order_conf_pk_option_price,
        }
        const res = await apiSetAdminParkingConfigUpdate(adminUser.admin_token,adminUser.admin_id,newInfo);
        if(res.result){
            alert('변경 되었습니다.');
        }else{
            alert(res.error);
        }
    }
    
    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={140} align='start' style={{ flexDirection: 'column' }}>
                    <BasicText color='#232323' fontWeight={700} fontSize={18}>기본가</BasicText>
                    <PriceWrap>
                        <DayItem>
                            <input value={info.order_conf_pk_default_day} onChange={(e: any) => valueChange('order_conf_pk_default_day', onlyNumber(e))}></input>
                            <BasicText color='#8D8D8D' fontSize={15}>일 까지</BasicText>
                        </DayItem>
                        <PriceItem>
                            <input value={numberWithComma(info.order_conf_pk_default_price)} onChange={(e: any) => valueChange('order_conf_pk_default_price', onlyNumber(e))}></input>
                        </PriceItem>
                    </PriceWrap>
                </ItemWrap>
                <AdminProductConfigItem title='1일 추가 기본가' titleBold right value={numberWithComma(info.order_conf_pk_add_price)} onChange={(e:any)=>valueChange('order_conf_pk_add_price', onlyNumber(e))}/>
                <ItemWrap pWidth={140} align='start' style={{ flexDirection: 'column' }}>
                    <BasicText color='#232323' fontWeight={700} fontSize={18}>조건가</BasicText>
                    <PriceWrap>
                        <DayItem>
                            <input value={info.order_conf_pk_option_day} onChange={(e: any) => valueChange('order_conf_pk_option_day', onlyNumber(e))}></input>
                            <BasicText color='#8D8D8D' fontSize={15}>일 이상일 경우</BasicText>
                        </DayItem>
                        <PriceItem>
                            <input value={numberWithComma(info.order_conf_pk_option_price)} onChange={(e: any) => valueChange('order_conf_pk_option_price', onlyNumber(e))}></input>
                        </PriceItem>
                    </PriceWrap>
                </ItemWrap>
            </ContentWrap>
            <SettingWrap>
                <BasicText color='#232323' fontSize={18} fontWeight={700}>옵션 관리</BasicText>
                <ContentWrap gap={15} mt={15}>
                    <AdminProductParkingConfigTypeItem info={info} titles={typeItems} setTitles={setTypeItems} valueChange={valueChange}/>
                    {/* <AdminProductParkingConfigPayItem info={info} titles={payItems} setTitles={setPayItems} valueChange={valueChange} /> */}
                </ContentWrap>
            </SettingWrap>
            <BtnWrap>
                <BtnItem onClick={update} bg={addTheme.infoChangeBtnBg}>정보 변경</BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const SettingWrap = styled.div`
    margin-top: 80px;
`;

const PriceWrap = styled(Row)`
    margin-top: 10px;
    gap: 10px;
    &>div{
        border: 1px solid #d4d4d4;
        padding: 8px 12px;
        border-radius: 8px;
        height: 42px;
    }
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const DayItem = styled(RowBetween)`
    width: 35%;
    & input{
        width: auto;
        max-width: 36%;
    }
    & p{
        width: 60%;
        flex-shrink: 0;
    }
`;
const PriceItem = styled(Row)`
    width: 65%;
    & input{
        width: 100%;
        text-align: right;
    }
`;

const BtnWrap = styled(Row)`
    justify-content: end;
    margin-top: 60px;
`;