import React from 'react'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import AdminAirportItem from '../../admin/reservation/item/AdminAirportItem';
import { momentFormat } from '../../../utils/format/time';
import { PARKING_TYPE } from '../../../data/airport';

export default function ParkingViewPart({ info,type }: any) {
    const startDate = info?.parking_time_depart_timestamp === '' ? '' : momentFormat('YYYY-MM-DD', info?.parking_time_depart_timestamp);
    const startTime = info?.parking_time_depart_timestamp === '' ? '' : momentFormat('HH:mm', info?.parking_time_depart_timestamp);
    const endDate = info?.parking_time_arrive_timestamp === '' ? '' : momentFormat('YYYY-MM-DD HH:mm', info?.parking_time_arrive_timestamp);
    
    return (
        <Wrap>
            <TitleWrap>
                <BasicText fontSize={23} mFontSize={20}>{'주차 대행 견적 신청서'}</BasicText>
            </TitleWrap>
            <TicketWrap>
                <AdminAirportItem title='출발' color={startDate === '' ? '#aaa' : '#000'} content={startDate === '' ? '' : `${startDate} ${startTime === '' ? '' : `(차량 맡기는시간 ${startTime})`}`} placeholder='출발날짜와 시간을 입력해주세요.' />
                <AdminAirportItem title='항공편' color={info?.parking_time_depart_flight === '' ? '#aaa' : '#000'} content={info?.parking_time_depart_flight} placeholder='항공편을 입력해주세요.' />
                <AdminAirportItem title='터미널' color={info?.parking_time_depart_airplane === '' ? '#aaa' : '#000'} content={info?.parking_time_depart_airplane?.airplane_name === undefined ? '' : `${info?.parking_time_depart_airplane?.airplane_name} ${info?.parking_time_depart_airplane?.airplane_sub_name}`} placeholder='출국 터미널을 선택해주세요.' />
            </TicketWrap>
            <TicketWrap>
                <AdminAirportItem title='복귀' color={endDate === '' ? '#aaa' : '#000'} content={endDate === '' ? '' : `${endDate}`} placeholder='복귀날짜와 시간을 입력해주세요.' />
                <AdminAirportItem title='항공편' color={info?.parking_time_arrive_flight === '' ? '#aaa' : '#000'} content={info?.parking_time_arrive_flight} placeholder='항공편을 입력해주세요.' />
                <AdminAirportItem title='터미널' color={info?.parking_time_arrive_airplane === '' ? '#aaa' : '#000'} content={info?.parking_time_arrive_airplane?.airplane_name === undefined ? '' : `${info?.parking_time_arrive_airplane?.airplane_name} ${info?.parking_time_arrive_airplane?.airplane_sub_name}`} placeholder='입국 터미널을 선택해주세요.' />
            </TicketWrap>
            <MoreWrap>
                <AdminAirportItem title='픽업 방법' color={info?.parking_type?.parking_id === undefined ? '#aaa' : '#000'} content={info?.parking_type?.parking_id === undefined ? '' : info?.parking_type?.parking_name} placeholder='픽업 방법을 선택해주세요' />
                <AdminAirportItem title='연락처' color={info?.parking_name === '' && info?.parking_phone_number === '' ? '#aaa' : '#000'} content={info?.parking_name === '' && info?.parking_phone_number === '' ? '' : `${info?.parking_name} ${info?.parking_phone_number}`} placeholder='맡기실 분의 이름과 연락가능한 연락처를 입력해주세요.' />
                <AdminAirportItem title='차량 정보' color={info?.parking_car === '' && info?.parking_car_number === '' ? '#aaa' : '#000'} content={info?.parking_car === '' && info?.parking_car_number === '' ? '' : `${info?.parking_car} ${info?.parking_car_number}`} placeholder='차량 기종과 차량 번호를 입력해주세요.' />
                {/* <AdminAirportItem title='여행 정보' color={info?.parking_airline === '' && info?.parking_destination === '' ? '#aaa' : '#000'} content={info?.parking_airline === '' && info?.parking_destination === '' ? '' : `${info?.parking_destination} ${info?.parking_airline}`} placeholder='여행지와 항공사를 입력해주세요.' />
                <AdminAirportItem title='결제 방법' color={info?.parking_payment === '' ? '#aaa' : '#000'} content={`${info?.parking_payment}`} placeholder='선택 안함' /> */}
                <AdminAirportItem title='추가 메모' color={info?.parking_message === '' ? '#aaa' : '#000'} content={`${info?.parking_message}`} placeholder='없음' />
            </MoreWrap>
            {/* {type === 'result' &&
                <MoreWrap>
                    <BasicText fontSize={24} mFontSize={20} fontWeight={700}>예상 비용</BasicText>
                    <BasicText fontSize={16} mFontSize={15}>실제 상황에 따라 견적 비용이 상이할 수 있습니다.</BasicText>
                    <PriceWrap>
                        <BasicText fontSize={26} mFontSize={24} fontWeight={700}>150,000원</BasicText>
                    </PriceWrap>
                </MoreWrap>
            } */}
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    @media ${addTheme.media.m}{
        padding: 15px;
    }
    @media ${addTheme.media.s}{
        padding: 0;
        box-shadow: none;
    }
`;

const TitleWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 8px;
    & p{
        word-break: break-all;
    }
`;

const TicketWrap = styled(TitleWrap)`
    margin-top: 24px;
    gap: 6px;
`;

const MoreWrap = styled(TitleWrap)`
    border-top: 1px solid #E6E6E6;
    padding-top: 30px;
    margin-top: 30px;
`;

const PriceWrap = styled(Row)`
    margin-top: 8px;
    justify-content: end;
`;