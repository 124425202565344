import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { apiGetBannerList } from '../../../api/api_main';

export default function HomeBannerPart() {
    const swiperRef = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [total,setTotal] = useState(0);
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        getList();
    },[])
    
    const getList = async() =>{
        setList([{banner_img_web:'/img/asset/newBanner01.png',},{banner_img_web:'/img/asset/newBanner02.png',}]);
        setTotal(2);
        const res = await apiGetBannerList(1,15,'1');
        if(res.result){
            setList(res.data);
            setTotal(res.data.length);
        }
    }

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setActiveIndex(swiperRef.current.swiper.realIndex);
        }
    };
    
    const bannerClick = (link:string) =>{
        if(link === '' || link === undefined) return;
        window.open(link)
    }

    return (
        <Wrap>
            <ContentWrap>
                <Swiper 
                    ref={swiperRef}
                    modules={[Autoplay]}
                    onSlideChange={handleSlideChange}
                    slidesPerView={1}
                    loop
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                >
                    {list.length === 0 ?
                        <SwiperSlide>
                            <ContentItemWrap>
                                <img src='/img/asset/banner01.png'></img>
                            </ContentItemWrap>
                        </SwiperSlide> :
                        list.map((data:any,index:any)=>{
                            const time = new Date().getTime();
                            return(
                                <SwiperSlide key={`banner-${index}`}>
                                    <ContentItemWrap onClick={()=>bannerClick(data?.banner_link_url)}>
                                        <img src={data?.banner_img_web+`?time=${time}`}></img>
                                    </ContentItemWrap>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                {total !== 0 &&
                    <CountWrap>{activeIndex+1}/{total}</CountWrap>
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    position: relative;
`;

const ContentItemWrap = styled.div`
    width: 100%;
    &>img{
        width: 100%;
    }
`;

const CountWrap = styled(Center)`
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: rgba(0,0,0,0.6);
    color: #fff;
    z-index: 10;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 20px;
    letter-spacing: 1px;
`;