import React, { useState } from 'react'
import styled from 'styled-components'
import { AdmBtn } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';

export default function SearchItem({searchAction,placeholder}:any) {
    const [sc,setSc] = useState('');
    const submitAction = (e:any) =>{
        e.preventDefault();
        if(searchAction){
            searchAction(sc);
        }
    }
    return (
        <Wrap onSubmit={submitAction}>
            <input value={sc} onChange={(e:any)=>setSc(e.target.value)} placeholder={placeholder ?? ''}></input>
            <AdmBtn type='submit' bg={addTheme.searchBtnBg} fontSize='15px'>검색</AdmBtn>
        </Wrap>
    )
}

const Wrap = styled.form`
    gap: 10px;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    &>input{
        border: 1px solid #B4B4B4;
        padding: 8px;
        border-radius: 4px;
        height: 42px;
    }
`;