import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function TermsPersonalPage() {
    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>개인정보처리방침</BasicText>
                </TitleWrap>
                <ContentWrap>
                    <BasicText fontSize={24} color={'#1e4479'}>에어드라이브 개인정보처리방침</BasicText>
{`
제1조 (목적)
본 개인정보처리방침은 주식회사 에어드라이브(이하 "회사")가 제공하는 공항 이동 및 주차 대행 예약 서비스(이하 "서비스")를 이용하는 이용자의 개인정보 보호 및 관리에 관한 사항을 규정함을 목적으로 합니다.

제2조 (수집하는 개인정보 항목)
회사는 서비스 제공을 위해 아래와 같은 개인정보를 수집할 수 있습니다.
1. 회원 가입 및 예약 시 수집하는 정보
   - 필수 정보: 이름, 전화번호, 이메일
   - 선택 정보: 차량 번호, 예약 관련 기타 정보
2. 서비스 이용 과정에서 자동으로 수집되는 정보
   - 접속 IP 주소, 쿠키, 서비스 이용 기록, 기기 정보

제3조 (개인정보의 수집 및 이용 목적)
회사는 다음과 같은 목적으로 개인정보를 수집하고 이용합니다.
1. 회원 가입 및 관리
2. 서비스 제공 및 예약 관리
3. 고객 문의 및 민원 처리
4. 마케팅 및 광고 활용 (이용자의 동의가 있는 경우)
5. 법적 의무 준수를 위한 활용

제4조 (개인정보의 보유 및 이용 기간)
1. 이용자의 개인정보는 서비스 제공을 위한 기간 동안 보유됩니다.
2. 관련 법령에 따른 보존 의무가 있는 경우, 해당 기간 동안 보관합니다.
   - 계약 및 청약철회 기록: 5년 (전자상거래법)
   - 소비자 불만 및 분쟁 처리 기록: 3년 (전자상거래법)
   - 전자금융 거래 기록: 5년 (전자금융거래법)

제5조 (개인정보의 제3자 제공)
회사는 이용자의 개인정보를 원칙적으로 제3자에게 제공하지 않습니다. 다만, 아래의 경우에 한하여 제공될 수 있습니다.
1. 이용자의 사전 동의가 있는 경우
2. 법령에 의해 제공이 요구되는 경우
3. 서비스 제공을 위해 제휴사(운송업체, 주차 대행업체 등)에 필요한 정보가 제공되는 경우

제6조 (개인정보의 처리 위탁)
회사는 원활한 서비스 제공을 위해 필요한 경우 개인정보 처리를 위탁할 수 있으며, 위탁 업체 및 위탁 업무의 내용은 본 개인정보처리방침을 통해 공개합니다.

제7조 (이용자의 권리 및 행사 방법)
1. 이용자는 언제든지 개인정보 조회, 수정, 삭제 요청을 할 수 있습니다.
2. 개인정보 보호 관련 요청은 고객센터를 통해 접수할 수 있습니다.
3. 회원 탈퇴 시, 회사는 이용자의 개인정보를 즉시 삭제합니다. 다만, 법령에 따라 보존해야 하는 경우는 예외로 합니다.

제8조 (개인정보 보호를 위한 기술적/관리적 대책)
1. 회사는 이용자의 개인정보 보호를 위해 보안 시스템을 구축하고 관리합니다.
2. 개인정보 접근을 최소화하고, 담당자에 대한 교육을 시행합니다.
3. 해킹 및 악성 코드 대응을 위한 보안 시스템을 운영합니다.

제9조 (쿠키 사용 관련 사항)
1. 회사는 보다 나은 서비스를 제공하기 위해 쿠키를 사용할 수 있습니다.
2. 이용자는 웹 브라우저 설정을 통해 쿠키 사용을 거부할 수 있습니다.

제10조 (개인정보 보호책임자 및 연락처)
회사는 이용자의 개인정보 보호를 위해 개인정보 보호책임자를 지정하고 있습니다.
- 개인정보 보호책임자: 오택준
- 이메일: airdrive2021@gmail.com

기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.

- 개인정보분쟁조정위원회 : www.kopico.go.kr / (1833-6972)
- 개인정보침해신고센터 : privacy.kisa.or.kr / (118)
- 대검찰청 : www.spo.go.kr / (1301)
- 경찰청 : ecrm.police.go.kr / (182)

제11조 (개인정보처리방침 변경)
1. 본 개인정보처리방침은 관련 법령 및 정책 변경에 따라 개정될 수 있습니다.
2. 개정된 사항은 홈페이지를 통해 사전 공지됩니다.

부칙: 본 개인정보처리방침은 2025년 3월 12일부터 시행됩니다.

`}
                </ContentWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}

const Wrap = styled.div`
    margin-bottom: 50px;
`;
const TitleWrap = styled.div`
    margin-top: 150px;
`;
const ContentWrap = styled.div`
    white-space: pre-wrap;
    margin-top: 30px;
    line-height: 1.3;
`;