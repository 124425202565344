import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoginLayoutPage from './LoginLayoutPage';
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useNavigate } from 'react-router';
import ModalOrderTypePage from '../../modal/page/ModalOrderTypePage';

export default function JoinDonePage() {
    const userInfo = useUserInfo();
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);

    useEffect(()=>{
        if(userInfo.user_id === 0){
            navigate('/');
        }
    },[userInfo.user_id]);

    return (
        <LoginLayoutPage title='' contentMt={'0'}>
            <Wrap>
                <img alt='chk' src='/img/asset/chk.svg'></img>
                <BasicText align='center' marginTop={1} fontWeight={700} fontSize={40} mFontSize={32}>회원가입 완료</BasicText>
                <BasicText align='center' marginTop={1.5} fontSize={24} mFontSize={20}>{userInfo.user_name}님 반갑습니다.</BasicText>
                <BasicText align='center' fontSize={24} mFontSize={20}>회원이 되신 것을 환영합니다.</BasicText>
                <BtnItem onClick={()=>setOpen(true)}>견적 확인하기</BtnItem>
            </Wrap>
            <ModalOrderTypePage open={open} setOpen={setOpen} />
        </LoginLayoutPage>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
    &>img{
        width: 70px;
        height: auto;
    }
`;

const BtnItem = styled(Center)`
    margin-top: 22px;
    cursor: pointer;
    background-color: #181818;
    border-radius: 8px;
    width: 286px;
    height: 50px;
    color: #fff;
    font-size: 15px;
`;