import { axiosConfigInstance } from "./config"

// 마이페이지 - 내 정보 가져오기
export const apiGetMyInfo = async(token:string,user_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/info',token).post('',{
            method: 'getMyInfo',
            params: {
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 마이페이지 - 내 예약 리스트 가져오기(픽업)
export const apiGetMyOrderList = async(token:string,user_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/order/pickup',token).post('',{
            method: 'getMyOrderPickUpList',
            params: {
                user_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 마이페이지 - 내 예약 리스트 가져오기(주차대행)
export const apiGetMyOrderParkingList = async(token:string,user_id:any,page:any,count:any)=>{
    try {
        const res = await axiosConfigInstance('/api/client/order/parking',token).post('',{
            method: 'getMyOrderParkingList',
            params:{
                user_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 마이페이지 - 내 리뷰
export const apiGetMyReviewList = async(token:string,user_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/client/review',token).post('',{
            method: 'getMyReviewList',
            params: {
                user_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}