import React from 'react'
import styled from 'styled-components'
import { Center, RowBetween } from '../../../style/basic/commonStyle';

export default function AirportBtnItem({prevAction,nextAction,nextName}:any) {
    
    return (
        <Wrap>
            <PrevBtnItem $active={prevAction} onClick={prevAction}>이전</PrevBtnItem>
            <NextBtnItem onClick={nextAction}>{nextName ?? '다음'}</NextBtnItem>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    gap: 15px;
`;

const Btn = styled(Center)`
    cursor: pointer;
    font-size: 15px;
    height: 55px;
    border-radius: 8px;
`;

const PrevBtnItem = styled(Btn)<{$active?:any}>`
    background-color: #F5F5F5;
    color: #2A2A2A;
    width: 32%;
    opacity: ${({$active}) => $active === undefined ? 0.8 : 1};
    cursor: ${({$active}) => $active === undefined ? 'not-allowed' : 'pointer'};
`;
const NextBtnItem = styled(Btn)`
    background-color: #181818;
    color: #fff;
    width: 65%;
`;