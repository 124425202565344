import { createAction, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

const SET_AIRPLANE = '/SET_AIRPLANE';

export const globalAirplaneCreator = createAction(SET_AIRPLANE);

export function setGlobalAirplane(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalAirplaneCreator>> {
    return async function (dispatch) {
        try {
            dispatch(globalAirplaneCreator({
                data: data
            }));
        } catch (error) {

        }
    };
}

export interface Airplane {
    "airplane_id": number,
    "airplane_group_id": number,
    "airplane_name": string,
    "airplane_sub_name": string,
}

export interface Airport {
    list: Airplane[]
}

const initialState: Airport = {
    list: []
};

export const globalAirplane = handleActions<Airport, any>({
    [SET_AIRPLANE]: (state, action: ReturnType<typeof globalAirplaneCreator>) => ({
        list: action.payload.data.list ?? state.list,
    }),
}, initialState);
