import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import RectItem from '../../common/item/RectItem';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

const LIST = [
    { img: '/img/asset/thumb.png', txt: `실내주차장'소방시설'설치` },
    { img: '/img/asset/thumb.png', txt: `안전한 캡스 경비구역` },
    { img: '/img/asset/thumb.png', txt: `고객대기실` },
]

export default function ServiceParkingFacilityPart() {
    return (
        <Wrap>
            <MaxWidthWrap maxWidth='1100px'>
                <ContentWrap>
                    {LIST.map((item: any, index: any) => {
                        return (
                            <ContentItemWrap key={`facility-${index}`}>
                                <ImgWrap>
                                    <RectItem imgUrl={item.img}></RectItem>
                                </ImgWrap>
                                <BasicText marginTop={1}>{item.txt}</BasicText>
                            </ContentItemWrap>
                        )
                    })}
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 50px;
`;
const ContentWrap = styled(Row)`
    align-items: start;
    gap: 30px;
    flex-wrap: wrap;
    &>div{
        width: calc(33.33% - 20px);
    }
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
`;
const ContentItemWrap = styled.div``;
const ImgWrap = styled.div`
    overflow: hidden;
    border-radius: 10px;
`;
