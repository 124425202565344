import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdminWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { PRODUCT_LIST } from '../../data/table';
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiGetAdminProductList } from '../../../../api/api_adm_product';
import NoListItem from '../../common/item/NoListItem';
import AdminProductListItem from '../item/AdminProductListItem';

export default function AdminProductPage() {
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [list, setList] = useState([]);

    useEffect(() => {
        if (adminUser.admin_id === 0) return;
        getList();
    }, [adminUser.admin_id])

    const getList = async () => {
        const res = await apiGetAdminProductList(adminUser.admin_token, adminUser.admin_id);
        if (res.result) {
            setList(res.data);
        }
    }

    const goDetail = (type:string) =>{
        navigate(`/admin/${type}`);
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 상품' part='product' unit='건' />
            <AdminWrap>
                <BasicText fontSize={19} mFontSize={16} fontWeight={700}>상품 리스트</BasicText>
                <AdminTablePart title={PRODUCT_LIST} minWidth={1100} mt='30px'>
                    {list.length === 0 ? 
                        <NoListItem length={PRODUCT_LIST} /> :
                        list.map((data:any,index:any)=>{
                            let type = index === 0 ? 'productParking' : 'productAirport';
                            return(
                                <AdminProductListItem key={`product-${index}`} data={data} goDetail={()=>goDetail(type)}/>
                            )
                        })
                    }
                </AdminTablePart>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;