import React from 'react'
import styled from 'styled-components'
import LoginLayoutPage from './LoginLayoutPage';
import { Center, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { LOGIN_LINK } from '../../../api/api_login';

export default function LoginPage() {
    const login = (type:string) =>{
        window.location.href = LOGIN_LINK + type;
    }
    return (
        <LoginLayoutPage title='로그인'>
            <Wrap>
                <BtnWrap bg={addTheme.kakaoColor} onClick={()=>login('kakao')}>
                    <img src='/img/asset/kakao.svg' alt='kakao'></img>
                    카카오로 계속하기
                    <div></div>
                </BtnWrap>
                <BtnWrap bg={addTheme.naverColor} color='#fff' onClick={()=>login('naver')}>
                    <img src='/img/asset/naver.svg' alt='naver'></img>
                    네이버로 계속하기
                    <div></div>
                </BtnWrap>
            </Wrap>
        </LoginLayoutPage>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
    gap: 14px;
`;
const BtnWrap = styled(RowBetween)<{bg:string,color?:string}>`
    width: 286px;
    height: 48px;
    border-radius: 8px;
    background-color: ${({bg}) => bg ?? '#fff'};
    color: ${({color}) => color ?? '#000'};
    font-size: 15px;
    padding: 0 18px;
    cursor: pointer;
    &>div{
        width: 20px;
    }
`;