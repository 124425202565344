import React from 'react'
import styled from 'styled-components'
import { RowBetween } from '../../../style/basic/commonStyle';
import HomeTypeItem from '../item/HomeTypeItem';
import { useNavigate } from 'react-router';
import { addTheme } from '../../../style/addTheme';

export default function HomeTypePart() {
    const navigate = useNavigate();
    const clickAction = (link:string) =>{
        navigate(link);
    }
    return (
        <Wrap>
            <ContentWrap>
                <HomeTypeItem type='airplane' title='공항 이동' desc='공항 갈 때, 올 때' img='/img/asset/airplane.png' clickAction={()=>clickAction('/serviceAirport')} />
                <HomeTypeItem bg='#696D7E' type='bus' title='주차 대행' desc='주차가 필요할 때' img='/img/asset/bus.png' clickAction={()=>clickAction('/serviceParking')}/>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 13px;
    width: 100%;
    @media ${addTheme.media.m}{
        margin-top: 22px;
    }
`;
const ContentWrap = styled(RowBetween)`
    flex-direction: column;
    align-items: start;
    gap: 15px;
    &>div{
        cursor: pointer;
        width: 100%;
    }
`;
