import React from 'react'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function HomeTypeItem({bg,title,desc,img,type,clickAction}:any) {
    return (
       <ContentItem onClick={clickAction} bg={bg}>
            <ContentItemTextWrap>
                <BasicText color={bg === undefined ? '#000' : '#fff'} fontSize={24} fontWeight={700} mFontSize={19}>{title}</BasicText>
                <BasicText color={bg === undefined ? '#000' : '#fff'} fontSize={19} mFontSize={16}>{desc}</BasicText>
            </ContentItemTextWrap>
            <ContentItemImgWrap>
                <img src={img} alt={type}></img>
            </ContentItemImgWrap>
        </ContentItem>
    )
}

const ContentItem = styled(Row)<{bg?:string}>`
    background-color: ${({bg}) => bg ?? '#F4F4FC'};
    border-radius: 8px;
    align-items: start;
    flex-direction: column;
    @media ${addTheme.media.m}{
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        &>div{
            padding: 0;
            width: auto;
        }
        /* flex-wrap: wrap;
        &>div{
            width: 100%;
        } */
    }
`;

const ContentItemTextWrap = styled.div`
    padding: 30px 25px 0;
`;
const ContentItemImgWrap = styled(Row)`
    justify-content: end;
    padding: 0 15px 15px;
    &>img{
        width: 100%;
        max-width: 125px;
    }
    @media ${addTheme.media.sx}{
        text-align: center;
        padding: 5px 0;
    }
`;