import { createAction, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

const SET_AIRPORT = '/SET_AIRPORT';
const SET_RESET = '/SET_AIRPORT/RESET';

export const globalAirportCreator = createAction(SET_AIRPORT);
export const globalAirportReset = createAction(SET_RESET);

export function setGlobalAirport(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalAirportCreator>> {
    return async function (dispatch) {
        try {
            dispatch(globalAirportCreator({
                data: data
            }));
        } catch (error) {

        }
    };
}

export interface arrive {
    "airplane_id": number,
    "airplane_group_id": number,
    "airplane_name": string,
    "airplane_sub_name": string,
}

export interface Airport {
    "pickup_type": string,
    "pickup_depart_location": any,
    "pickup_arrive_location": arrive | any,
    "pickup_stopover_location": string,
    "pickup_people": string,
    "pickup_bags": Array<any>,
    "pickup_service": Array<any>,
    "pickup_car": any,
    "pickup_payment": string,
    "pickup_message": string,
    "pickup_time_depart_timestamp": string,
    "pickup_time_depart_ap": string,
    "pickup_time_depart_flight": string,
    "pickup_time_arrive_timestamp": string,
    "pickup_time_arrive_ap": string,
    "pickup_time_arrive_flight": string
    "pickup_state": boolean,
}

const initialState: Airport = {
    "pickup_type": "2",
    "pickup_depart_location": {},
    "pickup_arrive_location": {},
    "pickup_stopover_location": "",
    "pickup_people": "",
    "pickup_bags": [],
    "pickup_service": [],
    "pickup_car": {},
    "pickup_payment": "",
    "pickup_message": "",
    "pickup_time_depart_timestamp": "",
    "pickup_time_depart_ap": "",
    "pickup_time_depart_flight": "",
    "pickup_time_arrive_timestamp": "",
    "pickup_time_arrive_ap": "",
    "pickup_time_arrive_flight": "",
    "pickup_state": false,
};

export const globalAirport = handleActions<Airport, any>({
    [SET_AIRPORT]: (state, action: ReturnType<typeof globalAirportCreator>) => ({
        pickup_type: action.payload.data.pickup_type ?? state.pickup_type,
        pickup_depart_location: action.payload.data.pickup_depart_location ?? state.pickup_depart_location,
        pickup_arrive_location: action.payload.data.pickup_arrive_location ?? state.pickup_arrive_location,
        pickup_stopover_location: action.payload.data.pickup_stopover_location ?? state.pickup_stopover_location,
        pickup_people: action.payload.data.pickup_people ?? state.pickup_people,
        pickup_bags: action.payload.data.pickup_bags ?? state.pickup_bags,
        pickup_service: action.payload.data.pickup_service ?? state.pickup_service,
        pickup_car: action.payload.data.pickup_car ?? state.pickup_car,
        pickup_payment: action.payload.data.pickup_payment ?? state.pickup_payment,
        pickup_message: action.payload.data.pickup_message ?? state.pickup_message,
        pickup_time_depart_timestamp: action.payload.data.pickup_time_depart_timestamp ?? state.pickup_time_depart_timestamp,
        pickup_time_depart_ap: action.payload.data.pickup_time_depart_ap ?? state.pickup_time_depart_ap,
        pickup_time_depart_flight: action.payload.data.pickup_time_depart_flight ?? state.pickup_time_depart_flight,
        pickup_time_arrive_timestamp: action.payload.data.pickup_time_arrive_timestamp ?? state.pickup_time_arrive_timestamp,
        pickup_time_arrive_ap: action.payload.data.pickup_time_arrive_ap ?? state.pickup_time_arrive_ap,
        pickup_time_arrive_flight: action.payload.data.pickup_time_arrive_flight ?? state.pickup_time_arrive_flight,
        pickup_state: action.payload.data.pickup_state ?? state.pickup_state,
    }),
    [SET_RESET]: () => (initialState)
}, initialState);
