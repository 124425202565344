import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import HomeReviewPart from '../part/HomeReviewPart';
import HomeCarPart from '../part/HomeCarPart';
import HomeApplicationPart from '../part/HomeApplicationPart';
import HomeRightPart from '../part/HomeRightPart';
import HomePart01 from '../part/HomePart01';
import HomePart02 from '../part/HomePart02';
import HomePart03 from '../part/HomePart03';

export default function HomePage() {
    const [rightState, setRightState] = useState(true);
    const targetRef = useRef<any>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (targetRef.current) {
                const sc = window.scrollY
                const targetPosition = targetRef.current.getBoundingClientRect().top; // 특정 엘리먼트의 위치

                // 특정 엘리먼트의 위치가 뷰포트의 상단에 도달했을 때
                if ((targetPosition - 780) <= 0) {
                    setRightState(false); // 고정된 요소 숨기기
                } else {
                    setRightState(true); // 고정된 요소 보이기
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Wrap>
            <HomePart01 />
            <HomePart02 />
            <HomePart03 />
            <HomeApplicationPart />
            <HomeCarPart />
            {/* <div ref={targetRef}>
            </div> */}
            <HomeReviewPart />
            {/* <HomeUsePart /> */}
            <HomeRightPart />
            {/* {rightState &&
                <HomeRightPart />
            } */}
        </Wrap>
    )
}

const Wrap = styled.div`
    padding-top: 60px;
    background-color: #FCFCFC;
`;