import React from 'react'
import styled from 'styled-components'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { addTheme } from '../../../style/addTheme';

export default function SelectItem({ width, value, setValue, children, margin, disabled, none, required, borderNo, sx }: any) {

    const handleChange = (e: SelectChangeEvent) => {
        setValue(e.target.value);
    }

    return (
        <Wrap margin={margin} width={width} >
            <Select
                disabled={disabled}
                value={value}
                onChange={handleChange}
                displayEmpty
                size='small'
                sx={{
                    '.MuiSelect-select': { color: '#000', border: borderNo ? 'none' : '1px solid #e9e9e9', textAlign: 'left', paddingRight: '25px !important', padding: '8.5px 12px', fontSize:'15px'},
                    '& fieldset': { borderWidth: '0' },
                    ...sx
                }}
                fullWidth
                required
            >
                {none === undefined ?
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem> :
                    none
                }
                {children !== undefined && children.length !== 0 &&
                    children
                }
            </Select>
        </Wrap>
    )
}

const Wrap = styled.div<{ margin?: string, width?: string }>`
    width: ${({ width }) => width ?? '100%'};
    & svg{
        color: #000;
    }
    margin: ${props => props.margin ?? 0};
    & .MuiSelect-select:focus-visible{
        outline: none;
    }
    & .MuiInputBase-root fieldset{
        border: none;
    }
    /* max-width: 280px;
    @media ${addTheme.media.m}{
        max-width: 100%;
    } */
`;
