import React from 'react'
import styled from 'styled-components'
import { Center } from '../../../../style/basic/commonStyle';
import { TableCell, TableRow } from '@mui/material';

export default function NoListItem({text,length}:any) {
    return (
        <TableRow>
            <TableCell colSpan={length}>
                <Center style={{height:'100px'}}>{text ?? '리스트가 없습니다.'}</Center>
            </TableCell>
        </TableRow>
    )
}